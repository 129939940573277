import styled from 'styled-components';

const PaginationWrapper = styled.div`
  transition: margin 400ms;
  user-select: none;
  * {
    cursor: pointer;
  }

  .pagination-content {
    height: 40px;
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    .nav-btn {
      margin: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      user-select: none;
      background: ${({ theme }) => theme.colors.backgroundColor};
    }

    .nav-btn-left {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .nav-btn-right {
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }

    .left-shadow {
      background: linear-gradient(90deg, rgba(242, 246, 250, 0.77) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .right-shadow {
      background: linear-gradient(90deg, rgba(242, 246, 250, 0.77) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .pages {
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow-x: scroll;
      scrollbar-width: none;
      transition: width 400ms;
      padding: 0 1px;

      ::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
      }

      .page {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        min-height: 40px;

        label {
          color: #d3dfe5;
          width: 30px;
          height: 30px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        label:hover {
          background: #ffffff;
        }
      }

      .selected-page {
        label {
          color: #053149;
        }
        label:hover {
          background: #ffffff;
        }
        // box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.05);
      }
    }
  }
`;
export default PaginationWrapper;
