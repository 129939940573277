import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import useDebounce from '../../helpers/useDebounceHook';
import { uppercase } from '../../helpers/utils';
import { setAccountSearchFields } from '../../store/features/accountsSlice';
import { getDeviceList } from '../../store/features/categorySlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import CategoryTagDetails from './category-tag-detail';
import DeviceListItem from './device-list-item';

const accountsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'DEVICE CODE', key: 'DEVICE_CODE' },
  { name: 'VENUE', key: 'VENUE' },
  { name: 'CREATED ON', key: 'CREATED_ON' },
  { name: 'BILLING PERIOD', key: 'BILLING_PERIOD' },
  { name: 'SUBSCRIPTION ID', key: 'SUBSCRIPTION_ID' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const AccountDeviceDetails = ({
  fromMyAccount = false,
  organizationId = null,
  venueListLength,
  brandIds = [],
  venueIds = [],
  brandOrVenueLoading = {},
}) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { account_id } = useParams();
  const { accountSearchFields } = useSelector(state => state.accounts);

  const brandOrVenueLoadingStatus = fromMyAccount
    ? false
    : brandOrVenueLoading.brandLoading || brandOrVenueLoading.venueLoading;

  const [deviceList, setDeviceList] = useState({});
  const [searchText, setSearchText] = useState(accountSearchFields.device || '');
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(brandOrVenueLoadingStatus);
  const debouncedSearch = useDebounce(searchText, 500);

  useEffect(() => {
    dispatch(setAccountSearchFields({ ...accountSearchFields, device: searchText }));
  }, [searchText]);

  const fetchAccountCategoryTagInfo = (account_id, page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    const organizationIdParam = fromMyAccount ? {} : { organization_id: account_id };
    dispatch(
      getDeviceList({
        params: { ...organizationIdParam, page: page, size: 20, search: debouncedSearch },
      }),
    )
      .then(data => {
        const { last, content } = data;
        const updatedDevices = merge ? [...deviceList.devices, ...content] : [...content];
        setDeviceList({ last, devices: [...updatedDevices] });
      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    const accountId = fromMyAccount ? organizationId : [account_id, ...brandIds, ...venueIds].join(',');
    fetchAccountCategoryTagInfo(accountId, pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    const accountId = fromMyAccount ? organizationId : [account_id, ...brandIds, ...venueIds].join(',');
    if (accountId && !brandOrVenueLoadingStatus) {
      fetchAccountCategoryTagInfo(accountId, 0, false, debouncedSearch, true);
    }
  }, [organizationId, debouncedSearch, brandIds, venueIds, brandOrVenueLoadingStatus]);

  const refreshDeviceList = () => {
    const accountId = fromMyAccount ? organizationId : [account_id, ...brandIds, ...venueIds].join(',');
    if (accountId) {
      fetchAccountCategoryTagInfo(accountId, 0, false, debouncedSearch, true);
    }
  };

  const onDeviceRequirePinUpdate = updatedDevice => {
    const { devices } = deviceList;
    const updatedDeviceList = devices.map(device => (device.id === updatedDevice.id ? updatedDevice : device));
    setDeviceList({ ...deviceList, devices: updatedDeviceList });
  };

  const renderTableHeader = headers => {
    return (
      <div className="border-top border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text px-5 font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <AccountDeviceDetailsWrapper className="flex-column card mb-4 mx-2">
      <CategoryTagDetails
        title={'Devices'}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsOpen={setIsOpen}
        isDisplayButton={false}
        isOpen={isOpen}
      />
      {isOpen && (
        <Fragment>
          {loading ? (
            <div className="item flex items-center justify-center flex-1 my-4">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (deviceList.devices || []).length > 0 ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <InfiniteScrollComponent
                dataLength={deviceList.devices.length}
                hasMore={!deviceList.last}
                fetchMoreData={fetchMoreData}>
                {(deviceList.devices || []).map((device, index) => (
                  <DeviceListItem
                    device={device}
                    key={device?.id}
                    venueListLength={venueListLength}
                    isFirst={index === 0}
                    refreshDeviceList={refreshDeviceList}
                    onDeviceRequirePinUpdate={onDeviceRequirePinUpdate}
                  />
                ))}
              </InfiniteScrollComponent>
            </div>
          ) : debouncedSearch ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no devices added yet</label>
              </div>
            </div>
          ) : (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no devices added yet</label>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </AccountDeviceDetailsWrapper>
  );
};

export const AccountDeviceDetailsWrapper = styled.div`
  .details-container {
    max-height: 400px;
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(150px, 1fr) minmax(125px, 125px) minmax(150px, 1fr) minmax(140px, 140px) minmax(150px, 170px) minmax(
        200px,
        1fr
      )
      minmax(100px, 100px)
      60px;
  }

  .header-container {
    min-height: 40px;
  }

  .no-data-wrapper {
    min-height: 56px;
  }
`;

export default AccountDeviceDetails;
