import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateIntegrationState, updatedIntegrationStateInList } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import Switch from '../common/switch';

const IntegrationsListItem = ({ integration }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const { id: integrationId, name, description, connector, is_enabled, is_default } = integration;

  const onIntegration = () => {
    navigate(`integration-details/${integrationId}`);
  };

  const onDeleteIntegration = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integration,
        isDelete: true,
      },
    });
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integration;
    if (is_enabled) {
      setModal({ type: 'integration-action', content: { integration: integration, isDisable: true } });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      dispatch(updatedIntegrationStateInList(integration));
      dispatch(updateIntegrationState({ id: integration?.id, request, updateList: false }))
        .then(() => dispatch(addToast({ text: 'Status updated successfully', id: nanoid() })))
        .catch(error => {
          const errorData = error?.response?.data;
          dispatch(updatedIntegrationStateInList(integration));
          dispatch(
            addToast({
              error: true,
              text:
                errorData?.error_code === 'BAD_REQUEST'
                  ? 'Please add all required fields in integration'
                  : 'Error while updating status',
              id: nanoid(),
            }),
          );
        });
    }
  };

  return (
    <IntegrationsListItemWrapper
      className="data-container py-2 border-bottom cursor"
      onClick={() => onIntegration(integration)}>
      <div className="border-right flex items-center pr-6 overflow-hidden">
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="border-right flex items-center pr-6 overflow-hidden">
        <label className="regular-text main-grey-text two-lines">{description}</label>
      </div>
      <div className="border-right flex items-center justify-between pr-6">
        <div className="flex items-center">
          <img alt="icon" className="icons" src={integration?.connector?.icon?.active} height={24} width={24} />
          <span className="regular-text font-14 ml-2">{integration?.connector?.name}</span>
        </div>
        <div>{is_default && <Logo width={75} height={24} />}</div>
      </div>
      <div className="flex items-center justify-between">
        <span className="regular-text flex items-center font-16">{is_enabled ? t('ENABLED') : t('DISABLED')}</span>
        <Switch variant="large" onClick={() => onChangeStatus()} enabled={is_enabled} />
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={useFilteredMenuList({
            menuList: [
              { name: t('DETAILS'), onClick: () => onIntegration(integration), permission: 'INTEGRATIONS_MANAGE' },
              { name: t('DELETE'), onClick: () => onDeleteIntegration(), permission: 'INTEGRATIONS_MANAGE' },
            ],
          })}
        />
      </div>
    </IntegrationsListItemWrapper>
  );
};

const IntegrationsListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default IntegrationsListItem;
