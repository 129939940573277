import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getAccountCategoryTagInfo } from '../../store/features/accountsSlice';
import { updateDeviceLocation } from '../../store/features/deviceSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const UpdateDeviceLocation = () => {
  const dispatch = useDispatch();

  const { userOrganization } = useSelector(state => state.user);

  const { modal, setModal } = useContext(OrganisationContext);
  const { device, onSuccess, popupTitle } = modal.content;
  const { id: deviceId, device_code } = device;

  const [loading, setLoading] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const [searchedVenue, setSearchedVenue] = useState(null);
  const [venueError, setVenueError] = useState(false);

  const onConfirmAction = async () => {
    if (!selectedVenue) {
      setVenueError(true);
      dispatch(addToast({ error: true, text: 'Please select venue', id: nanoid() }));
      return;
    }
    setVenueError(false);
    setLoading(true);
    dispatch(
      updateDeviceLocation({ device_id: deviceId, request: { ...device, organisation: { id: selectedVenue.id } } }),
    )
      .then(() => {
        dispatch(addToast({ error: false, text: 'Device location updated successfully', id: nanoid() }));
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while updating device location', id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVenuList = async (page, search = '') => {
    try {
      const venuList = await dispatch(
        getAccountCategoryTagInfo({
          organizationId: userOrganization.id,
          params: { page: page, search: search, category_tag: 'VENUE' },
        }),
      );
      return venuList;
    } catch (error) {
      return null;
    }
  };

  const fetchVenueOptions = async (search, _prevOptions, { page, merge }) => {
    const venueOptions = await fetchVenuList(page, search);
    const { content, ...restResponse } = venueOptions || {};
    const venueContent = venueOptions ? content : [];
    const changedOptions = venueContent.map(option => ({ ...option, label: option.name, value: option.id }));

    return {
      options: changedOptions,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  return (
    <CommonPopup
      popupTitle={popupTitle}
      confirmButtonProps={{
        label: 'Set',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <UpdateDeviceLocationWrapper className="flex-column w-full radius-4 mt-6">
        <label className="regular-text font-16 main-grey-text text-center">Device 7-digit code:</label>
        <label className="bold-text font-24 text-center mt-4">{device_code}</label>
        <div className="flex-column mt-6 w-full">
          <SearchableDropdown
            isClearable
            inputValue={searchedVenue}
            onInputChange={setSearchedVenue}
            loadOptions={fetchVenueOptions}
            className="w-full"
            placeholder="Select venue"
            name="Venue"
            value={selectedVenue}
            onChange={setSelectedVenue}
            isSearchable
            defaultAdditional={{ page: 0 }}
            error={venueError}
          />
        </div>
      </UpdateDeviceLocationWrapper>
    </CommonPopup>
  );
};

const UpdateDeviceLocationWrapper = styled.div`
  .otp-input {
    padding: 4px;
    width: 48px !important;
    height: 48px;
  }
`;

export default UpdateDeviceLocation;
