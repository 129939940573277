import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const IconContainer = ({
  Icon,
  backgroundColor = '',
  iconColor = '',
  iconClassName = '',
  iconContainerClassname = '',
  onClick = () => {},
}) => {
  return (
    <IconContainerWrapper
      backgroundColor={backgroundColor}
      iconColor={iconColor}
      className={classNames('flex items-center justify-center pxy-1 radius-2', iconContainerClassname)}
      onClick={onClick}>
      <Icon className={classNames('icon', iconClassName)} />
    </IconContainerWrapper>
  );
};

const IconContainerWrapper = styled.div`
  background: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};

  .icon {
    color: ${({ theme, iconColor }) => theme.colors[iconColor]};
  }
`;

export default IconContainer;
