import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import IntegrationDetails from '../../components/integration-details';
import IntegrationsList from '../../components/integrations-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Integrations = () => {
  const { setModal } = useContext(OrganisationContext);

  const { integrationDetails } = useSelector(state => state.integrations);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-integration',
      content: {},
    });
  };

  return (
    <IntegrationsWrapper className="flex-column w-full">
      <Routes>
        <Route
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Platforms' },
                  { text: 'Integrations', path: '/platforms/integrations' },
                  ...(integrationDetails?.id ? [{ text: integrationDetails?.connector?.name }] : []),
                ]}
              />
              <IntegrationDetails />
            </Fragment>
          }
          path="/integration-details/:integration_id"
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Platforms', onClick: () => {} },
                  { text: 'Integrations', onClick: () => {} },
                ]}
                showAddNewBtn
                onAddClick={onAddClick}
                addBtnPermission="INTEGRATIONS_MANAGE"
                showSearch={true}
                searchText={searchText}
                onSearchChange={setSearchText}
              />
              <IntegrationsList debouncedSearch={debouncedSearch} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </IntegrationsWrapper>
  );
};

const IntegrationsWrapper = styled.div``;

export default Integrations;
