import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { uppercase } from '../../helpers/utils';
import { getAccountCategoryTagInfo, setAccountSearchFields } from '../../store/features/accountsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import CategoryTagDetails from './category-tag-detail';
import VenueListItem from './venue-list-item';

const accountsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'BRAND', key: 'BRAND' },
  { name: 'ADDRESS', key: 'ADDRESS' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'EMAIL', key: 'EMAIL' },
  { name: 'PRIMARY CONTACT', key: 'PRIMARY_CONTACT' },
  { name: '', key: 'MENU' },
];

const AccountVenueDetails = ({
  fromMyAccount = false,
  organizationId = null,
  organizationName = '',
  setVenueListLength,
  setVenueIds = () => {},
  setBrandOrVenueLoading = () => {},
}) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { setModal } = useContext(OrganisationContext);

  const { account_id } = useParams();
  const { accountSearchFields } = useSelector(state => state.accounts);

  const [venueList, setVenueList] = useState([]);
  const [searchText, setSearchText] = useState(accountSearchFields.venue || '');
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);

  useEffect(() => {
    dispatch(setAccountSearchFields({ ...accountSearchFields, venue: searchText }));
  }, [searchText]);

  const fetchAccountCategoryTagInfo = (account_id, category_tag, page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
      setBrandOrVenueLoading(prev => ({ ...prev, venueLoading: true }));
    }
    dispatch(
      getAccountCategoryTagInfo({
        organizationId: account_id,
        params: { page: page, size: 20, category_tag, search: debouncedSearch },
      }),
    )
      .then(data => {
        const { last, content } = data;
        const updatedVenues = merge ? [...venueList.venues, ...content] : [...content];
        const venueIds = updatedVenues.map(venue => venue.id);
        setVenueList({ last, venues: [...updatedVenues] });
        setVenueListLength(updatedVenues?.length);
        setVenueIds(venueIds);
      })
      .catch(error => {
        console.log('error', error);
      })
      .finally(() => {
        setLoading(false);
        setBrandOrVenueLoading(prev => ({ ...prev, venueLoading: false }));
      });
  };

  const fetchMoreData = () => {
    fetchAccountCategoryTagInfo(account_id, 'VENUE', pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    const accountId = fromMyAccount ? organizationId : account_id;
    if (accountId) {
      fetchAccountCategoryTagInfo(accountId, 'VENUE', 0, false, debouncedSearch, true);
    }
  }, [organizationId, account_id, debouncedSearch]);

  const onAddClick = () => {
    const accountId = fromMyAccount ? organizationId : account_id;
    setModal({
      type: 'add-account',
      content: {
        fromMyAccount: fromMyAccount,
        organizationId: accountId,
        organizationName: organizationName,
        category_tag: 'VENUE',
        onSuccess: () => fetchAccountCategoryTagInfo(accountId, 'VENUE', 0, false, debouncedSearch, true),
      },
    });
  };

  const renderTableHeader = headers => {
    return (
      <div className="border-top border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text px-5 font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <AccountVenueDetailsWrapper className="flex-column card mb-4 mx-2">
      <CategoryTagDetails
        title={'Venues'}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onAddClick={onAddClick}
      />
      {isOpen && (
        <Fragment>
          {loading ? (
            <div className="item flex items-center justify-center flex-1 my-4">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (venueList.venues || []).length > 0 ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <InfiniteScrollComponent
                dataLength={venueList.venues.length}
                hasMore={!venueList.last}
                fetchMoreData={fetchMoreData}>
                {(venueList.venues || []).map((venue, index) => (
                  <VenueListItem
                    fromMyAccount={fromMyAccount}
                    venue={venue}
                    key={venue?.id}
                    isLast={index === venueList.venues.length - 1}
                  />
                ))}
              </InfiniteScrollComponent>
            </div>
          ) : debouncedSearch ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no venues added yet</label>
              </div>
            </div>
          ) : (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no venues added yet</label>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </AccountVenueDetailsWrapper>
  );
};

export const AccountVenueDetailsWrapper = styled.div`
  .details-container {
    max-height: 400px;
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(250px, 1fr) minmax(130px, 130px) minmax(180px, 1fr) minmax(150px, 170px) minmax(240px, 1fr) minmax(
        150px,
        170px
      )
      60px;
  }

  .header-container {
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .no-data-wrapper {
    min-height: 56px;
  }
`;

export default AccountVenueDetails;
