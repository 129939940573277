import isEquals from 'lodash.isequal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { createFilterUrl } from '../../helpers/utils';
import { getDevices, setDevicesList } from '../../store/features/deviceSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import TableHeader from '../common/table-header';
import DevicesListItem from './devices-list-items';

const deviceTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'STORAGE RULE ', key: 'STORAGE_RULE' },
  { name: 'DEVICE CODE', key: 'DEVICE_CODE' },
  { name: 'VENUE', key: 'VENUE' },
  { name: 'CREATED ON', key: 'CREATED_ON' },
  { name: 'SUBSCRIPTION ID', key: 'SUBSCRIPTION_ID' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const DevicesList = ({ debouncedSearch = '', filters = [] }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const filterRef = useRef({});

  const { devicesList } = useSelector(state => state.device);
  const { content: devicesListItem = [], last } = devicesList || {};

  const [loading, setLoading] = useState(true);
  const [deviceList, setDeviceList] = useState({});

  const fetchDeviceList = (page = 0, merge, debouncedSearch) => {
    pageRef.current = page;
    setLoading(true);
    dispatch(
      getDevices({
        merge,
        params: {
          size: 15,
          page: page,
          search: debouncedSearch,
          ...filterRef.current,
        },
      }),
    )
      .then(data => {
        const { last, content } = data;
        const updatedDevices = merge ? [...deviceList.devices, ...content] : [...content];
        setDeviceList({ last, devices: [...updatedDevices] });
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchDeviceList(pageRef.current + 1, true, false);
  };

  const onDeviceRequirePinUpdate = updatedDevice => {
    const { devices } = deviceList;
    const updatedDeviceList = devices.map(device => (device.id === updatedDevice.id ? updatedDevice : device));
    setDeviceList({ ...deviceList, devices: updatedDeviceList });
  };

  useEffect(() => {
    fetchDeviceList(0, false, debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    const updatedFilter = createFilterUrl(filters);

    if (!isEquals(updatedFilter, filterRef.current)) {
      filterRef.current = updatedFilter;
      fetchDeviceList(0, false, debouncedSearch);
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(setDevicesList([]));
    };
  }, []);

  return (
    <DevicesListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (devicesListItem || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={deviceTableHeaders} />
          <div className="flex-column overflow-scroll">
            <InfiniteScrollComponent
              dataLength={deviceList.devices.length}
              hasMore={!last}
              height={64}
              fetchMoreData={fetchMoreData}>
              {(deviceList.devices || []).map(device => (
                <DevicesListItem
                  device={device}
                  key={device?.id}
                  fetchDeviceList={fetchDeviceList}
                  onDeviceRequirePinUpdate={onDeviceRequirePinUpdate}
                />
              ))}
            </InfiniteScrollComponent>
          </div>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </DevicesListWrapper>
  );
};

export const DevicesListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(130px, 1fr) minmax(120px, 1fr) minmax(100px, 1fr) 120px 120px 120px 100px 30px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default DevicesList;
