import classNames from 'classnames';
import { useFormik } from 'formik';
import isEquals from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { durationUnitOptions } from '../../constant/optionData';
import { getErrorFieldJoined, lowercase, onlyNumbers } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateStorage } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import Dropdown from '../common/dropdown';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';
import UpdateActionButtons from '../common/update-action-buttons';

const StorageDetailUI = ({ storageDetails }) => {
  const { name, lifecycle, expiry } = storageDetails || {};

  const getUseBy = expiry => {
    const { units, period } = expiry || {};
    if (units !== undefined && period) {
      return `${units} ${lowercase(period)}`;
    }
    return '-';
  };

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Usage</label>
        <label className="regular-text font-16 one-line">{getUseBy(expiry)}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">State</label>
        <ProductStatus status={lifecycle} />
      </div>
    </Fragment>
  );
};

const StorageDetailEditUI = ({ storageDetails, setEditStorage }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { id, name: storageName, expiry, lifecycle: storageLifecycle, storage_type } = storageDetails || {};

  const onSave = data => {
    const prevData = {
      name: storageName,
      number_of_days: expiry.units,
      duration_unit: expiry.period,
      lifecycle: storageLifecycle || 'INACTIVE',
    };
    if (isEquals(prevData, data)) {
      setEditStorage(false);
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const { name, lifecycle, number_of_days, duration_unit } = data;
    dispatch(
      updateStorage({
        request: {
          name,
          lifecycle,
          expiry: { units: number_of_days, period: duration_unit },
          storage_type: storage_type,
        },
        storage_id: id,
        updateDetails: true,
      }),
    )
      .then(() => {
        dispatch(addToast({ success: true, text: 'Storage updated successfully', id: nanoid() }));
        setEditStorage(false);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while saving storage', id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: {
      name: storageName,
      number_of_days: expiry.units,
      duration_unit: expiry.period,
      lifecycle: storageLifecycle || 'INACTIVE',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
      number_of_days: Yup.number().required('days'),
      duration_unit: Yup.string().required('duration'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSave,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorFields = getErrorFieldJoined(errors, (key, value) => {
        return key === 'number_of_days' ? lowercase(values.duration_unit || 'DAYS') : value;
      });
      const errorText = `Please add ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <input
          className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
          onChange={({ target: { value } }) => {
            setFieldValue('name', value);
          }}
          placeholder={t('enter_type', { type: 'name' })}
          value={values.name || ''}
        />
      </div>
      <div className="flex-column row-gap-2">
        <Dropdown
          customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
          name={'Duration unit'}
          options={durationUnitOptions}
          value={durationUnitOptions.find(uo => uo.value === values.duration_unit)}
          onChange={option => setFieldValue('duration_unit', option.value)}
          placeholder={t('select_type', { type: 'unit' })}
          error={errors.duration_unit && !values.duration_unit}
        />
      </div>
      <div className="flex-column row-gap-2">
        <div className="mb-1">
          <label className="regular-text main-grey-text font-12">
            {t('number_of_type', { type: lowercase(values.duration_unit || 'DAYS') })}
          </label>
        </div>
        <input
          className={classNames(
            'input radius-3 h-40px w-full',
            errors.number_of_days && !values.number_of_days && 'error-border',
          )}
          onChange={({ target: { value } }) => {
            ((value && onlyNumbers.test(value)) || !value) && setFieldValue('number_of_days', value);
          }}
          placeholder="-"
          value={values.number_of_days !== undefined ? values.number_of_days : ''}
        />
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">State</label>
        <div className="flex items-center justify-between">
          <label className="regular-text font-16">Active</label>
          <Switch
            enabled={values.lifecycle === 'ACTIVE'}
            onClick={() => setFieldValue('lifecycle', values.lifecycle === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
          />
        </div>
      </div>
      <UpdateActionButtons
        btnSize="small"
        className="mt-8"
        inEditState
        isSaving={loading}
        onCancel={() => setEditStorage(false)}
        onSave={handleSubmit}
      />
    </Fragment>
  );
};

const StorageDetailsLeft = () => {
  const { storageDetails } = useSelector(state => state.storage);

  const [editStorage, setEditStorage] = useState(false);

  return (
    <StorageDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex items-center pl-6 pr-4">
          <label className="bold-text font-24 flex-1">Storage</label>
          <Menu
            menuList={useFilteredMenuList({
              menuList: [
                { name: 'Edit', onClick: () => setEditStorage(true), permission: 'STORAGE_MANAGE' },
                // { name: 'Delete', onClick: () => {}, permission: 'STORAGE_MANAGE' },
              ],
            })}
          />
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          {editStorage ? (
            <StorageDetailEditUI storageDetails={storageDetails} setEditStorage={setEditStorage} />
          ) : (
            <StorageDetailUI storageDetails={storageDetails} />
          )}
        </div>
      </div>
    </StorageDetailsLeftWrapper>
  );
};

export const StorageDetailsLeftWrapper = styled.div`
  width: 350px;

  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default StorageDetailsLeft;
