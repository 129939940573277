import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/Logo.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as LoginErrorIcon } from '../../assets/images/vector.svg';
import Button from '../../components/Elements/button/button';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { setUser } from '../../store/features/userSlice';
import { LoginWrapper } from '../../styles/pages/login.styled';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [active, setActive] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onLogin = async event => {
    if (active) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API_URL}/authentication`,
          {
            identity: email,
            password: password,
          },
          { timeout: 10000 },
        );
        const { data } = response;
        dispatch(setUser(data));
        setItemInLocalStorage('user', data);
        navigate('/authentication');
      } catch (error) {
        if (error?.message === 'timeout of 10000ms exceeded') {
          setErrorMessage('REQUEST_TIMEOUT');
        } else {
          setErrorMessage('INCORRECT_EMAIL_PASSWORD');
        }
        setShowError(true);
      }
    }
  };

  const changePassword = e => {
    setPassword(e.target.value);
    setShowError(false);
  };

  const changeEmail = email => {
    setEmail(email);
    setShowError(false);
  };

  useEffect(() => {
    setActive(email && password && email.length > 2 && password.length > 2);
  }, [email, password]);

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onLogin();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [email, password, active]);

  return (
    <LoginWrapper className="flex">
      <div className="flex items-center justify-center overflow-scroll m-auto">
        <div className="flex-column items-center login-form card px-16 pt-10 pb-14">
          <img src={Logo} alt="logo" />
          <label className="ramabhadra-regular-text font-24 mt-10 mb-4">Login to your dashboard</label>
          <form className="flex-column form w-full">
            <label className="regular-text main-grey-text font-12 mt-6 mb-2">{t('EMAIL')}</label>
            <div className="flex-column relative w-full">
              <input
                placeholder={t('EMAIL_ADDRESS')}
                onChange={e => changeEmail(e.target.value)}
                className={`input ${showError && 'error-border'}`}
              />
            </div>
            <label className="regular-text main-grey-text font-12 mt-6 mb-2">{t('PASSWORD')}</label>
            <div className="flex-column relative w-full">
              <input
                className={`input password-input ${showError && 'error-border'}`}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('ENTER_PASSWORD')}
                onChange={e => changePassword(e)}
              />
              <img
                className="show-password"
                src={showPassword ? HidePassword : ShowPassword}
                alt="show password"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            {showError && (
              <div className="flex items-center radius-2 py-1 px-2 error-background error-message mt-6">
                <LoginErrorIcon className="error-icon mr-2" />
                <label className="medium-text white-text">{t(errorMessage)}</label>
              </div>
            )}
            <Button
              label={t('LOGIN')}
              className={`mt-10 mb-6 ${active && !showError ? 'primary' : 'primary disabled'}`}
              size="medium"
              borderRadius="100px"
              onClick={onLogin}
              width="350px"
            />
            <a href="#/password" className="bold-text font-16 main-orange-text">
              {t('FORGOT_PASSWORD')}
            </a>
          </form>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default Login;
