import classNames from 'classnames';
import { useFormik } from 'formik';
import isEquals from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { ReactComponent as PrepProductIcon } from '../../assets/images/prep-product.svg';
import { ReactComponent as SaleProductIcon } from '../../assets/images/sale-product.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { updateCategory } from '../../store/features/categorySlice';
import { addToast } from '../../store/features/toastSlice';
import IconContainer from '../common/icon-container';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';
import UpdateActionButtons from '../common/update-action-buttons';

const CategoryDetailUI = ({ categoryDetails }) => {
  const { t } = useTranslation();
  const { name, description, lifecycle, usage } = categoryDetails || {};

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      {/* <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('CATEGORY_NOTES')}</label>
        <label className="regular-text font-16 one-line">{description}</label>
      </div> */}
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Use</label>
        <label>
          {usage === 'COMPONENT' ? (
            <Fragment>
              <PrepProductIcon />
              <span className="regular-text ml-2">Prep</span>
            </Fragment>
          ) : (
            <Fragment>
              <SaleProductIcon />
              <span className="regular-text ml-2">Sale</span>
            </Fragment>
          )}
        </label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">State</label>
        <ProductStatus status={lifecycle} />
      </div>
    </Fragment>
  );
};

const CategoryDetailEditUI = ({ categoryDetails, setEditCategory }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { id, usage } = categoryDetails || {};

  const onSave = data => {
    if (isEquals(categoryDetails, data)) {
      setEditCategory(false);
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const { name, description, lifecycle } = data;
    dispatch(updateCategory({ request: { name, lifecycle, usage: usage }, category_id: id }))
      .then(() => {
        dispatch(addToast({ success: true, text: 'Category updated successfully', id: nanoid() }));
        setEditCategory(false);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while saving category', id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: categoryDetails,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSave,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      dispatch(addToast({ error: true, text: 'Please add name', id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <input
          className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
          onChange={({ target: { value } }) => {
            setFieldValue('name', value);
          }}
          placeholder={t('enter_type', { type: 'name' })}
          value={values.name || ''}
        />
      </div>
      {/* <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('CATEGORY_NOTES')}</label>
        <textarea
          className={classNames('textarea radius-3')}
          onChange={({ target: { value } }) => setFieldValue('description', value)}
          placeholder={t('enter_type', { type: 'category notes' })}
          rows={4}
          value={values.description || ''}
        />
      </div> */}
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Use</label>
        <label>
          {usage === 'COMPONENT' ? (
            <Fragment>
              <PrepProductIcon />
              <span className="regular-text ml-2">Prep</span>
            </Fragment>
          ) : (
            <Fragment>
              <SaleProductIcon />
              <span className="regular-text ml-2">Sale</span>
            </Fragment>
          )}
        </label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">State</label>
        <div className="flex items-center justify-between">
          <label className="regular-text font-16">Active</label>
          <Switch
            enabled={values.lifecycle === 'ACTIVE'}
            onClick={() => setFieldValue('lifecycle', values.lifecycle === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
          />
        </div>
      </div>
      <UpdateActionButtons
        btnSize="small"
        className="mt-8"
        inEditState
        isSaving={loading}
        onCancel={() => setEditCategory(false)}
        onSave={handleSubmit}
      />
    </Fragment>
  );
};

const CategoryDetailsLeft = () => {
  const { hasPermission } = useContext(OrganisationContext);
  const { categoryDetails } = useSelector(state => state.category);

  const [editCategory, setEditCategory] = useState(false);

  return (
    <CategoryDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex items-center pl-6 pr-4">
          <label className="bold-text font-24 flex-1">Category info</label>
          {hasPermission('CATEGORIES_MANAGE') && (
            <IconContainer
              Icon={EditIcon}
              iconColor="primary"
              backgroundColor="additional"
              iconContainerClassname="cursor"
              onClick={() => setEditCategory(true)}
            />
          )}
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          {editCategory ? (
            <CategoryDetailEditUI categoryDetails={categoryDetails} setEditCategory={setEditCategory} />
          ) : (
            <CategoryDetailUI categoryDetails={categoryDetails} />
          )}
        </div>
      </div>
    </CategoryDetailsLeftWrapper>
  );
};

export const CategoryDetailsLeftWrapper = styled.div`
  width: 350px;

  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default CategoryDetailsLeft;
