import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import { OrganisationContext } from '../context/organisationContext';
import AddAccount from './account/add-account';
import AutomationAction from './automation-action';
import CategoryAddEdit from './category/category-add-edit';
import AddDevice from './device/add-device';
import RemoveDevice from './device/remove-device';
import UpdateDeviceLocation from './device/update-device-location';
import UpdateDevicePin from './device/update-device-pin';
import AddIntegration from './integration/add-integration';
import IntegrationActions from './integration/integration-actions';
import { ModalWrapper } from './modal.styled';
import AddProduct from './product/add-product';
import AddStorageGroup from './storage-groups/add-storage-group';
import ChangeGroup from './storage-groups/change-group';
import ChangeStorageRules from './storage-groups/change-storage-rules';
import DeleteStorageGroup from './storage-groups/delete-storage-group';
import AddEditStorage from './storage/add-edit-storage';
import DeleteStorage from './storage/delete-storage';
import UserActions from './user-actions';
import AddUser from './user/add-user';
import ResetUserPassword from './user/reset-user-password';
import ResetUserPin from './user/reset-user-pin';

const initModal = { type: null, content: {} };

const PopupComponents = {
  'add-integration': AddIntegration,
  'integration-action': IntegrationActions,
  'invite-user': AddUser,
  'user-action': UserActions,
  'automation-action': AutomationAction,
  'remove-device': RemoveDevice,
  'category-add-edit': CategoryAddEdit,
  'add-device': AddDevice,
  'add-product': AddProduct,
  'add-storage': AddEditStorage,
  'edit-storage': AddEditStorage,
  'delete-storage': DeleteStorage,
  'update-device-pin': UpdateDevicePin,
  'reset-user-pin': ResetUserPin,
  'update-device-location': UpdateDeviceLocation,
  'reset-user-password': ResetUserPassword,
  'add-account': AddAccount,
  'add-storage-group': AddStorageGroup,
  'change-group': ChangeGroup,
  'delete-storage-group': DeleteStorageGroup,
  'change-storage-rules': ChangeStorageRules,
};

const Modal = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal.type) return null;

  return (
    <CSSTransition appear classNames="modal-transition" in timeout={100}>
      <ModalWrapper id="modal-wrapper-id" onclick={onClick} style={{ justifyContent: 'center', overflow: 'scroll' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              marginTop: '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
