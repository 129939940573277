import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as FridgeIcon } from '../../assets/icons/fridge.svg';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { deleteStorageGroup } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const DeleteStorageGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);
  const { storageGroup, onSuccess = () => {} } = modal.content;

  const { id: storage_group_id, name, is_saas_default } = storageGroup;

  const onConfirmAction = async () => {
    setLoading(true);
    dispatch(deleteStorageGroup({ storage_group_id: storage_group_id }))
      .then(() => {
        dispatch(addToast({ error: false, text: t('STORAGE_DELETE_GROUP'), id: nanoid() }));
        onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: error?.response?.data?.error_description || t('ERROR_STORAGE_DELETE_GROUP'),
            id: nanoid(),
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CommonPopup
      popupTitle={t('ARE_YOU_SURE_TO_DELETE_STORAGE_GROUP')}
      popupSubTitle={t('NOT_WILL_BE_UNDU_ACTION')}
      confirmButtonProps={{
        label: 'Delete',
        className: 'negative',
      }}
      cancelButtonProps={{ label: 'Cancel' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <DeleteStorageGroupWrapper className="flex items-center pxy-2 w-full radius-2 mt-6">
        <FridgeIcon />
        <label className="semibold-text flex-1 one-line ml-4">{name}</label>
        <label className="regular-text font-16">
          <label className="regular-text font-16 one-line">{is_saas_default ? 'Local' : 'Preset Group'}</label>
        </label>
      </DeleteStorageGroupWrapper>
    </CommonPopup>
  );
};

const DeleteStorageGroupWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.light_grey};
`;

export default DeleteStorageGroup;
