import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { isSuperUser } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { resetUserPin } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';

const ResetUserPin = () => {
  const dispatch = useDispatch();

  const { userDetails: currentUser, user: loggedInUser } = useSelector(state => state.user);

  const { modal, setModal } = useContext(OrganisationContext);

  const { user, onSuccess } = modal.content;
  const { id: user_id, name, role } = user;

  const isSameUser = currentUser.id === user_id && !isSuperUser(loggedInUser);

  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState({ prevPin: '', newPin: '', confirmPin: '' });
  const [pinError, setPinError] = useState({ prevPin: false, newPin: false, confirmPin: false });

  const checkPinErrors = () => {
    const { prevPin, newPin, confirmPin } = pin;
    if (isSameUser && prevPin.length !== 4) {
      setPinError({ prevPin: true, newPin: false, confirmPin: false });
      dispatch(addToast({ error: true, text: 'Invalid previous PIN code', id: nanoid() }));
      return true;
    }
    if (newPin.length !== 4 || confirmPin.length !== 4) {
      setPinError({ prevPin: false, newPin: newPin.length !== 4, confirmPin: confirmPin.length !== 4 });
      dispatch(addToast({ error: true, text: 'Invalid PIN code', id: nanoid() }));
      return true;
    }
    if (newPin !== confirmPin) {
      setPinError({ prevPin: false, newPin: true, confirmPin: true });
      dispatch(addToast({ error: true, text: 'PIN code does not match', id: nanoid() }));
      return true;
    }
    setPinError({ prevPin: false, newPin: false, confirmPin: false });
    return false;
  };

  const onConfirmAction = async () => {
    const { newPin, prevPin } = pin;
    if (checkPinErrors()) return;
    setLoading(true);
    dispatch(resetUserPin({ user_id: user_id, request: { pin: newPin, old_pin: isSameUser ? prevPin : null } }))
      .then(() => {
        dispatch(addToast({ error: false, text: 'User pin updated', id: nanoid() }));
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || 'Error while updating user pin';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPinError(false);
  }, [pin]);

  return (
    <CommonPopup
      popupTitle={'Reset PIN code'}
      confirmButtonProps={{
        label: 'Reset',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <ResetUserPinWrapper className="flex-column w-full radius-4 mt-6">
        {isSameUser && (
          <div className="flex-column pxy-4 w-full radius-4 user-details mb-6">
            <label className="bold-text font-18 mb-2">{name}</label>
            <label className="regular-text main-grey-text font-12">{role?.name || ''}</label>
          </div>
        )}
        {isSameUser && (
          <div className="flex-column items-center mb-6">
            <label className="bold-text font-16 mb-2">Enter the previous PIN code</label>
            <label className="regular-text main-grey-text font-12 mb-4">Enter 4-digit Previous PIN code</label>
            <OtpInput
              containerStyle={'flex items-center justify-center col-gap-2'}
              value={pin.prevPin}
              onChange={otpPin => setPin({ ...pin, prevPin: otpPin })}
              numInputs={4}
              placeholder="0000"
              inputType="tel"
              renderSeparator={<span />}
              renderInput={props => (
                <input {...props} className={classNames('input otp-input', pinError.prevPin && 'error-border')} />
              )}
            />
          </div>
        )}
        <div className="flex-column items-center">
          {isSameUser && <label className="bold-text font-16 mb-2">Create a new PIN code</label>}
          <div className="flex-column items-center mb-6">
            <label className="regular-text main-grey-text font-12 mb-2">New PIN code</label>
            <OtpInput
              containerStyle={'flex items-center justify-center col-gap-2 mt-4'}
              value={pin.newPin}
              onChange={otpPin => setPin({ ...pin, newPin: otpPin })}
              numInputs={4}
              placeholder="0000"
              inputType="tel"
              renderSeparator={<span />}
              renderInput={props => (
                <input {...props} className={classNames('input otp-input', pinError.newPin && 'error-border')} />
              )}
            />
          </div>
          <div className="flex-column items-center">
            <label className="regular-text main-grey-text font-12 mb-2">Confirm new PIN code</label>
            <OtpInput
              containerStyle={'flex items-center justify-center col-gap-2'}
              value={pin.confirmPin}
              onChange={otpPin => setPin({ ...pin, confirmPin: otpPin })}
              numInputs={4}
              placeholder="0000"
              inputType="tel"
              renderSeparator={<span />}
              renderInput={props => (
                <input {...props} className={classNames('input otp-input', pinError.confirmPin && 'error-border')} />
              )}
            />
          </div>
        </div>
      </ResetUserPinWrapper>
    </CommonPopup>
  );
};

const ResetUserPinWrapper = styled.div`
  .otp-input {
    padding: 4px;
    width: 48px !important;
    height: 48px;
  }

  .user-details {
    background-color: ${({ theme }) => theme.colors.light_grey};
  }
`;

export default ResetUserPin;
