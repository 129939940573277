import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getCategoryDetails, setCategoryDetails } from '../../store/features/categorySlice';
import CategoryDetailsLeft from './details-left';
import CategoryDetailsRight from './details-right';

const CategoryDetails = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  const [loading, setLoading] = useState(true);

  const fetchCategoryDetails = categoryId => {
    setLoading(true);
    dispatch(
      getCategoryDetails({
        categoryId: categoryId,
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategoryDetails(categoryId);
    }

    return () => {
      dispatch(setCategoryDetails({}));
    };
  }, [categoryId]);

  return (
    <CategoryDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <CategoryDetailsLeft />
          <CategoryDetailsRight />
        </div>
      )}
    </CategoryDetailsWrapper>
  );
};

export const CategoryDetailsWrapper = styled.div``;

export default CategoryDetails;
