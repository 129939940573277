import { createGlobalStyle } from 'styled-components';
import LatoBold from '../assets/fonts/Lato-Bold.ttf';
import LatoLight from '../assets/fonts/Lato-Light.ttf';
import LatoMedium from '../assets/fonts/Lato-Medium.ttf';
import LatoRegular from '../assets/fonts/Lato-Regular.ttf';
import LatoSemibold from '../assets/fonts/Lato-Semibold.ttf';
import RamabhadraRegular from '../assets/fonts/Ramabhadra-Regular.ttf';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  @font-face {
    font-family: 'Lato Light';
    src: local('Lato Light'), url(${LatoLight}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Regular';
    src: local('Lato Regular'), url(${LatoRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Medium';
    src: local('Lato Medium'), url(${LatoMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Semibold';
    src: local('Lato Semibold'), url(${LatoSemibold}) format('truetype');
  }

  @font-face {
    font-family: 'Lato Bold';
    src: local('Lato Bold'), url(${LatoBold}) format('truetype');
  }

  @font-face {
    font-family: 'Ramabhadra Regular';
    src: local('Ramabhadra Regular'), url(${RamabhadraRegular}) format('truetype');
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
