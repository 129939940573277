import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { isUserSassOperator } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateStorageGroup } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';

const StorageGroupListItem = ({
  storage = {},
  onStorageGroupListUpdate = () => {},
  fetchStorageGroupList = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setModal } = useContext(OrganisationContext);

  const isSassOperator = isUserSassOperator();

  const { id: storageGroupId, name, is_saas_default, lifecycle, connections } = storage;

  const navigateToStorageDetails = () => {
    navigate(`storage-group-details/${storageGroupId}`);
  };

  const onToggleLifecycle = () => {
    const newLifecycle = lifecycle !== 'ACTIVE' ? 'ACTIVE' : 'INACTIVE';
    const request = {
      name,
      is_saas_default: is_saas_default,
      lifecycle: lifecycle,
    };
    const deviceUpdateRequest = { ...request, lifecycle: newLifecycle };
    onStorageGroupListUpdate({ ...deviceUpdateRequest, id: storageGroupId });
    dispatch(updateStorageGroup({ storage_rule_group_id: storageGroupId, request: deviceUpdateRequest })).catch(() => {
      onStorageGroupListUpdate({ ...request, id: storageGroupId });
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating storage group',
          id: nanoid(),
        }),
      );
    });
  };

  const getExtraMenuOption = () => {
    const deviceConnections = connections?.devices || 0;
    return (
      <div>
        <div className="flex items-center justify-between px-1 pb-4 pt-4 border-top">
          <span className={`regular-text flex items-center last-width enable-color font-16 px-3`}>Active</span>
          <Switch
            onClick={onToggleLifecycle}
            className="pr-3"
            enabled={lifecycle === 'ACTIVE'}
            disabled={deviceConnections > 0}
          />
        </div>
      </div>
    );
  };

  const onEditClick = () => {
    setModal({
      type: 'add-storage-group',
      content: {
        isEditStorageGroup: true,
        storageGroup: storage,
        onSuccess: data => onStorageGroupListUpdate(data),
      },
    });
  };

  const onDeleteStorageGroup = () => {
    setModal({
      type: 'delete-storage-group',
      content: {
        storageGroup: storage,
        onSuccess: fetchStorageGroupList,
      },
    });
  };

  return (
    <StorageListGroupItemWrapper
      key={storageGroupId}
      className="data-container py-2 border-bottom cursor"
      onClick={() => (!is_saas_default || isSassOperator) && navigateToStorageDetails()}>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{is_saas_default ? 'Local' : 'Preset Group'}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16">{`${connections?.devices || 0} devices`}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <ProductStatus status={lifecycle || 'INACTIVE'} statusWrapperClassName="status-box" />
      </div>
      <div className="flex items-center overflow-hidden">
        {(!is_saas_default || isSassOperator) && (
          <Menu
            menuList={useFilteredMenuList({
              menuList: [
                { name: 'Edit', onClick: onEditClick, id: 'EDIT' },
                { name: 'Delete', onClick: onDeleteStorageGroup, id: 'DELETE' },
              ],
            })}
            ExtraOptions={getExtraMenuOption()}
          />
        )}
      </div>
    </StorageListGroupItemWrapper>
  );
};

const StorageListGroupItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }

  .items-select-checkbox {
    .checkbox {
      border-radius: 8px;
    }
  }
`;

export default StorageGroupListItem;
