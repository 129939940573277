import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { getIntegrationConfig, updateIntegration } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import Loader from '../common/loader';
import UpdateAction from '../common/update-action';
import DropDown from '../dropdown';

const IntegrationDetailsRight = () => {
  const dispatch = useDispatch();

  const { integrationDetails, integrationConfigsDetail } = useSelector(state => state.integrations);
  const { id: integrationId, integration_params, connector } = integrationDetails || {};

  const [configDetails, setConfigDetails] = useState([]);
  const [configDetailValues, setConfigDetailValues] = useState([]);
  const [updateIntegrationDetail, setUpdateIntegrationDetail] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [errorFields, setErrorFields] = useState({});
  const [loading, setLoading] = useState(true);

  const getConditional = config => {
    const findCondition = integrationConfigsDetail.filter(c => c.conditional?.param_name === config.id);
    const finalData = findCondition.map(f => ({
      ...f,
      value: getIntegrationParamValue(f.id),
      depends: getConditional(f),
    }));
    return finalData;
  };

  useEffect(() => {
    if (integrationId) {
      dispatch(getIntegrationConfig({ connector_id: connector?.id }))
        .catch(() => dispatch(addToast({ error: true, text: 'Error while fetching integration config', id: nanoid() })))
        .finally(() => setLoading(false));
    }
  }, [integrationId]);

  useEffect(() => {
    setConfigDetailValues(integration_params);
  }, [integration_params]);

  useEffect(() => {
    const configs = integrationConfigsDetail?.reduce((acc, curr) => {
      if (!curr.conditional) {
        const data = getConditional(curr);
        acc.push({ ...curr, value: getIntegrationParamValue(curr.id), depends: data });
      }
      return acc;
    }, []);
    setConfigDetails(configs);
  }, [integrationConfigsDetail, configDetailValues]);

  const getIntegrationParamValue = name => {
    return configDetailValues?.find(param => param.param_name === name)?.param_value || '';
  };

  const updateIntegrationParams = (name, value) => {
    const isAvailable = configDetailValues?.find(param => param.param_name === name);
    if (isAvailable) {
      setConfigDetailValues([
        ...configDetailValues.map(param => (param.param_name === name ? { ...param, param_value: value } : param)),
      ]);
    } else {
      setConfigDetailValues([...configDetailValues, { param_name: name, param_value: value }]);
    }
    setErrorFields({ ...errorFields, [name]: false });
  };

  const getConfigValueToDisplay = config => {
    const { param_type, value, choices } = config;
    if (param_type === 'LIST') {
      return choices?.find(c => c.id === value)?.name || '';
    }
    return value || '';
  };

  const onEditConfig = config => {
    const { config: previousConfig, previousParams } = updateIntegrationDetail;
    let detailValue = configDetailValues;
    if (previousConfig && previousConfig?.id !== config?.id) {
      detailValue = previousParams;
    }
    setConfigDetailValues(detailValue);
    setUpdateIntegrationDetail({ config, previousParams: detailValue });
  };

  const onCancelUpdate = () => {
    if (loading) {
      return;
    }
    setConfigDetailValues(updateIntegrationDetail.previousParams);
    setUpdateIntegrationDetail({});
  };

  const getSelectedConfigs = () => {
    const selectedConfigs = integrationConfigsDetail.reduce((acc, curr) => {
      if (curr.conditional) {
        const isAvailable = getIntegrationParamValue(curr.conditional.param_name) === curr.conditional.param_value;
        if (isAvailable) {
          acc.push(curr);
        }
      } else {
        acc.push(curr);
      }
      return acc;
    }, []);
    return selectedConfigs;
  };

  const onUpdate = () => {
    if (loading) {
      return;
    }
    const selectedConfigs = getSelectedConfigs();

    const updatedIntegrationParams =
      configDetailValues
        .filter(p => selectedConfigs.find(config => config.id === p.param_name))
        .filter(p =>
          p.param_value ? p.param_value : integration_params.find(param => param.param_name === p.param_name),
        )
        .map(param => ({ ...param, param_value: param.param_value || null })) || [];

    if (isEqual(updatedIntegrationParams, integration_params)) {
      setUpdateIntegrationDetail({});
      return;
    }
    const request = {
      ...integrationDetails,
      integration_params: updatedIntegrationParams,
    };
    setLoading(true);
    dispatch(updateIntegration({ id: integrationDetails.id, request }))
      .then(() => {
        setUpdateIntegrationDetail({});
        dispatch(
          addToast({
            error: false,
            text: 'Integration updated successfully',
            id: uuid(),
          }),
        );
      })
      .catch(() => {
        dispatch(
          addToast({
            error: true,
            text: 'Error while updating integration',
            id: uuid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const renderInput = (config, dependentConfig = null) => {
    return config.conditional ? (
      <>
        {config.conditional.param_value === dependentConfig?.value && (
          <div className="flex integration-input relative w-full">
            <input
              autoComplete="turnoff"
              className={classNames(
                'input w-full',
                errorFields[config.id] && 'error-info',
                config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask',
              )}
              onChange={e => updateIntegrationParams(config.id, e.target.value)}
              placeholder={config.name}
              type="text"
              value={config.value || ''}
            />
            {config.param_type === 'PASSWORD' && (
              <div className="flex items-center cursor mr-16 input-show-img">
                <img
                  alt="icon"
                  onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
                  src={showPassword[config.id] ? ShowPassword : HidePassword}
                />
              </div>
            )}
          </div>
        )}
      </>
    ) : config.param_type === 'LIST' ? (
      <div className="flex-column">
        <DropDown
          className={classNames('integration-input-dropdown', !errorFields[config.id] && 'error-info')}
          setSelected={option => updateIntegrationParams(config.id, option.id)}
          options={config.choices}
          placeholder={config.name}
          selected={config.choices?.find(type => type.id === config.value)}
          withIcon={false}
        />
      </div>
    ) : (
      <div className="flex integration-input relative w-full">
        <input
          autoComplete="turnoff"
          className={classNames(
            'input w-full',
            errorFields[config.id] && 'error-info',
            config.param_type === 'PASSWORD' && !showPassword[config.id] && 'password-mask',
          )}
          onChange={e => updateIntegrationParams(config.id, e.target.value)}
          placeholder={config.name}
          type="text"
          value={config.value || ''}
        />
        {config.param_type === 'PASSWORD' && (
          <div className="flex items-center cursor mr-16 input-show-img">
            <img
              alt="icon"
              onClick={() => setShowPassword({ [config.id]: !showPassword[config.id] })}
              src={showPassword[config.id] ? HidePassword : ShowPassword}
            />
          </div>
        )}
      </div>
    );
  };

  const renderConditionalConfigs = (config, dependentConfig = null, parentConfig = null) => {
    return (
      <>
        {(!config.conditional || (config.conditional && config.conditional.param_value === dependentConfig?.value)) && (
          <div className="flex-column action-container relative">
            <div className="flex-column mb-6">
              <label className="regular-text font-12 main-grey-text mb-1">{config.name}</label>
              {updateIntegrationDetail?.config?.id === config.id ||
              (dependentConfig && updateIntegrationDetail?.config?.id === dependentConfig?.id) ? (
                renderInput(config, dependentConfig)
              ) : (
                <div
                  className={classNames(
                    'flex items-center py-1 px-2 cursor hover-edit-hide hover-edit',
                    !getConfigValueToDisplay(config) && 'hover-edit-show',
                  )}
                  onClick={() => !loading && onEditConfig(config.conditional ? parentConfig : config)}>
                  <label
                    className={classNames(
                      'regular-text font-16 flex-1 one-line',
                      config.param_type === 'PASSWORD' && 'password-mask',
                    )}>
                    {getConfigValueToDisplay(config)}
                  </label>
                  <EditIcon width={20} height={20} className="primary-text edit-icon" />
                </div>
              )}
            </div>
            {config.depends?.map(c => renderConditionalConfigs(c, config, dependentConfig ? parentConfig : config))}
            {updateIntegrationDetail?.config?.id === config.id && (
              <UpdateAction onCancel={onCancelUpdate} onUpdate={onUpdate} className="update-action" />
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <IntegrationDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom details-header flex items-center pxy-6">
        <label className="bold-text font-24">Settings</label>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex-column pxy-6 flex-1 overflow-scroll">
          {configDetails.map(config => renderConditionalConfigs(config))}
        </div>
      )}
    </IntegrationDetailsRightWrapper>
  );
};

const IntegrationDetailsRightWrapper = styled.div`
  .details-header {
    height: 72px;
  }

  .action-container {
    .update-action {
      bottom: -27px;
      z-index: 2;
    }
  }

  .integration-input {
    .input {
      padding-right: 40px;
    }
    .input-show-img {
      position: absolute;
      right: 0;
      height: 100%;
    }
  }
`;

export default IntegrationDetailsRight;
