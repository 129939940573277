import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { capitalize, formatText, getErrorDescription } from '../../helpers/utils';
import {
  deleteIntegration,
  getIntegrationAutomations,
  setIntegrationAutomations,
  updateIntegrationState,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const ownerBackground = ['#FFF0F0', '#E6FAF3', '#FAF4E7'];

const IntegrationActions = () => {
  const dispatch = useDispatch();

  const { integrationAutomations } = useSelector(state => state.integrations);
  const { modal, setModal } = useContext(OrganisationContext);
  const { integration, isDelete, isDisable, onSuccess } = modal.content;
  const { id: integrationId, name, connector } = integration;

  const [loading, setLoading] = useState(false);

  const fetchIntegrationAutomations = integrationId => {
    dispatch(getIntegrationAutomations({ integration_id: integrationId }));
  };

  useEffect(() => {
    if (integrationId) {
      fetchIntegrationAutomations(integrationId);
    }

    return () => {
      dispatch(setIntegrationAutomations([]));
    };
  }, [integrationId]);

  const onConfirmAction = async () => {
    setLoading(true);

    if (isDelete) {
      dispatch(deleteIntegration({ id: integration?.id }))
        .then(() => {
          setModal(initModal);
          dispatch(addToast({ text: 'Integration deleted', id: nanoid() }));
          onSuccess && onSuccess();
        })
        .catch(error => {
          dispatch(
            addToast({
              error: true,
              text: getErrorDescription(error, 'Error while deleting integration'),
              id: nanoid(),
            }),
          );
        })
        .finally(() => setLoading(false));
    } else {
      dispatch(
        updateIntegrationState({
          id: integration?.id,
          request: {
            enabled: false,
          },
          updateList: true,
        }),
      )
        .then(() => {
          setModal(initModal);
          dispatch(addToast({ text: 'Status updated successfully', id: nanoid() }));
          onSuccess && onSuccess();
        })
        .catch(error => {
          dispatch(
            addToast({
              error: true,
              text: getErrorDescription(error, 'Error while disabling integration'),
              id: nanoid(),
            }),
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const getOwnerLogo = (automation, index) => {
    const { owner } = automation;
    if (!owner.name) return '';
    const nameArray = owner.name?.split(' ');
    return (
      <div
        className="flex items-center justify-center owner-logo ml-3 radius-4"
        style={{ backgroundColor: ownerBackground[index % 3] }}>
        <label className="bold-text">
          {nameArray?.[0]?.charAt(0)?.toUpperCase()}
          {nameArray?.[1]?.charAt(0)?.toUpperCase()}
        </label>
      </div>
    );
  };

  return (
    <CommonPopup
      popupTitle={`Are you sure you want to ${isDelete ? 'delete' : isDisable ? 'disable' : ''} this Integration?`}
      confirmButtonProps={{ label: isDelete ? 'Delete' : 'Disable', className: isDelete ? 'negative' : 'primary' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <DeleteIntegrationWrapper className="flex-column items-center w-full mt-6">
        <div className="pxy-4 flex-column w-full radius-4 details-container">
          <label className="medium-text font-16">{name}</label>
          <div className="flex items-center mt-2">
            <div className="flex items-center justify-center pxy-1 radius-2 integration-icon">
              <img alt="image" src={`${connector.icon?.active}`} width={24} height={24} />
            </div>
            <label className="regular-text ml-2">{capitalize(formatText(connector?.name))}</label>
          </div>
        </div>
        {integrationAutomations?.length > 0 && (
          <div className="mt-6">
            <div className="flex items-center justify-center text-center">
              <label className="regular-text main-grey-text">
                This will affect these automations and they will be disabled as a result:
              </label>
            </div>
            <div className="automation-detail mt-4 overflow-scroll">
              {integrationAutomations.map((automation, index) => (
                <div className="pxy-4 mb-4 flex items-center justify-between radius-4 details-container">
                  <div className="flex-column mr-1 flex-1">
                    <label className="medium-text font-16 one-line">{automation.name}</label>
                    <span className="regular-text main-grey-text mt-1">{automation.description}</span>
                  </div>
                  <div className="flex items-center">{getOwnerLogo(automation, index)}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </DeleteIntegrationWrapper>
    </CommonPopup>
  );
};

const DeleteIntegrationWrapper = styled.div`
  .details-container {
    background: ${({ theme }) => theme.colors.backgroundColor2};
  }

  .integration-icon {
    background: white;
  }

  .automation-detail {
    max-height: min(calc(65vh - 350px), 350px);
    min-height: 70px;
    overflow: scroll;

    .owner-logo {
      outline: 2px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      width: 32px;
      height: 32px;
    }
  }
`;

export default IntegrationActions;
