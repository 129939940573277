import styled from 'styled-components';

export const BoxWrapper = styled('div')`
  height: 88px;
  border: 1px solid ${({ theme }) => theme.colors.white};

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};

    .icon-container {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
