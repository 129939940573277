import classNames from 'classnames';
import { useFormik } from 'formik';
import isEquals from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ReactComponent as EmailIcon } from '../../assets/icons/contact/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/contact/phone.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { checkRoleTier } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { addToast } from '../../store/features/toastSlice';
import IconContainer from '../common/icon-container';
import Menu from '../common/menu';
import { UserStatus } from '../common/status';
import Switch from '../common/switch';
import UpdateActionButtons from '../common/update-action-buttons';

const UserDetailUI = ({ userDetails }) => {
  const { name, role, email, phone, employee_number, status } = userDetails || {};

  const getFormatEmail = email => {
    return email?.length >= 43 && email?.includes('device') && email?.includes('@labl.it') ? 'Not Supplied' : email;
  };
  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="semibold-text font-16 one-line">{name}</label>
        <label className="semibold-text main-grey-text font-12">{role?.name}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Contact</label>
        <div className="flex items-center">
          <IconContainer
            className="flex items-center justify-center"
            Icon={EmailIcon}
            backgroundColor="light_grey_background"
            iconColor="primary"
          />
          <label
            className={classNames(
              'regular-text font-16 ml-2',
              getFormatEmail(email) === 'Not Supplied' || !email ? 'main-grey-text' : '',
            )}>
            {getFormatEmail(email)}
          </label>
        </div>
        <div className="flex items-center">
          <IconContainer
            className="flex items-center justify-center"
            Icon={PhoneIcon}
            backgroundColor="light_grey_background"
            iconColor="primary"
          />
          <label className="regular-text font-16 ml-2">{phone || '-'}</label>
        </div>
      </div>
      <div className="flex col-gap-2">
        <div className="flex-1 flex-column row-gap-2">
          <label className="regular-text main-grey-text font-12">Status</label>
          <UserStatus status={status} />
        </div>
        {/* <div className="flex-1 flex-column row-gap-2">
          <label className="regular-text main-grey-text font-12">Employee number</label>
          <label className="regular-text font-16">{employee_number || '-'}</label>
        </div> */}
      </div>
    </Fragment>
  );
};

const UserDetailEditUI = ({ userDetails, setEditUser }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const { id } = userDetails || {};

  const onSave = data => {
    if (isEquals(userDetails, data)) {
      setEditUser(false);
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const { name, lifecycle } = data;
    // dispatch(updateUser({ request: { name, lifecycle }, user_id: id }))
    //   .then(() => {
    //     dispatch(addToast({ success: true, text: 'User updated successfully', id: nanoid() }));
    //     setEditUser(false);
    //   })
    //   .catch(error => {
    //     dispatch(addToast({ error: true, text: 'Error while saving user', id: nanoid() }));
    //   })
    //   .finally(() => setLoading(false));
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: userDetails,
    validationSchema: Yup.object().shape({
      name: Yup.string().required(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSave,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      dispatch(addToast({ error: true, text: 'Please add name', id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <input
          className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
          onChange={({ target: { value } }) => {
            setFieldValue('name', value);
          }}
          placeholder={t('enter_type', { type: 'name' })}
          value={values.name || ''}
        />
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Status</label>
        <div className="flex items-center justify-between">
          <label className="regular-text font-16">Active</label>
          <Switch
            enabled={values.lifecycle === 'ACTIVE'}
            onClick={() => setFieldValue('lifecycle', values.lifecycle === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
          />
        </div>
      </div>
      <UpdateActionButtons
        btnSize="small"
        className="mt-8"
        inEditState
        isSaving={loading}
        onCancel={() => setEditUser(false)}
        onSave={handleSubmit}
      />
    </Fragment>
  );
};

const UserDetailsLeft = ({ fetchUserDetails }) => {
  const { hasPermission, setModal } = useContext(OrganisationContext);
  const { userDetails } = useSelector(state => state.usersManagement);
  const userLocal = getItemFromLocalStorage('user');
  const { user_id } = useParams();
  const { organizations, user } = userLocal;
  const [isRole, setIsRole] = useState(false);
  const [editUser, setEditUser] = useState(false);

  const onResetPin = () => {
    setModal({
      type: 'reset-user-pin',
      content: {
        user: userDetails,
        onSuccess: () => {
          fetchUserDetails(userDetails.id);
        },
      },
    });
  };

  const onResetPassword = () => {
    setModal({
      type: 'reset-user-password',
      content: {
        user: userDetails,
        onSuccess: () => {
          fetchUserDetails(userDetails.id);
        },
      },
    });
  };

  useEffect(() => {
    if (organizations) {
      const isTier = checkRoleTier(organizations?.[0]?.role, userDetails?.role);
      if (isTier && userDetails?.role) {
        setIsRole(isTier);
      } else {
        setIsRole(user_id === user?.id);
      }
    }
  }, [organizations]);

  return (
    <UserDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex items-center pl-6 pr-4">
          <label className="bold-text font-24 flex-1">User profile</label>
          <Menu
            isDisabled={!isRole}
            menuList={useFilteredMenuList({
              menuList: [
                { name: 'Reset pin', onClick: onResetPin, permission: 'USER_DEVICES_MANAGE' },
                { name: 'Reset password', onClick: onResetPassword, permission: 'USERS_CREDENTIALS_MANAGE' },
              ],
            })}
          />
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          {editUser ? (
            <UserDetailEditUI userDetails={userDetails} setEditUser={setEditUser} />
          ) : (
            <UserDetailUI userDetails={userDetails} />
          )}
        </div>
      </div>
    </UserDetailsLeftWrapper>
  );
};

export const UserDetailsLeftWrapper = styled.div`
  width: 350px;

  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default UserDetailsLeft;
