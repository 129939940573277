import { nanoid } from 'nanoid';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { lowercase } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateStorageRules } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';

const StorageItem = ({ storage = {}, fetchStorageRules = () => {}, onStorageRulesListUpdate = () => {} }) => {
  const { storage_group_id } = useParams();
  const dispatch = useDispatch();

  const { setModal, hasPermission } = useContext(OrganisationContext);
  const { id: storage_Id, name, lifecycle, expiry, group, storage_type } = storage;

  const getUseBy = expiry => {
    const { units, period } = expiry || {};
    if (units !== undefined && period) {
      return `${units} ${lowercase(period)}`;
    }
    return '-';
  };

  const onDeleteStorageRules = () => {
    setModal({
      type: 'delete-storage',
      content: {
        storage: storage,
        isStorageRules: true,
        onSuccess: fetchStorageRules,
      },
    });
  };

  const onEditStorageRules = () => {
    setModal({
      type: 'add-storage',
      content: {
        isUpdateStorageRuels: true,
        id: storage_group_id,
        storage: storage,
        isEdit: true,
        onSuccess: fetchStorageRules,
      },
    });
  };

  const onToggleLifecycle = () => {
    const newLifecycle = lifecycle !== 'ACTIVE' ? 'ACTIVE' : 'INACTIVE';
    const request = {
      name,
      storage_type: storage_type,
      expiry: {
        units: expiry?.units,
        period: expiry?.period,
      },
      lifecycle: lifecycle,
      group: group
        ? {
            id: group?.id,
          }
        : null,
    };
    const storageRuleUpdateRequest = { ...request, lifecycle: newLifecycle };
    onStorageRulesListUpdate({ ...storageRuleUpdateRequest, id: storage_Id });
    dispatch(updateStorageRules({ storage_id: storage_Id, request: storageRuleUpdateRequest })).catch(() => {
      onStorageRulesListUpdate({ ...request, id: storage_Id });
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating storage',
          id: nanoid(),
        }),
      );
    });
  };

  const getExtraMenuOption = () => {
    return (
      <div>
        <div className="flex items-center justify-between px-1 pb-4 pt-4 border-top">
          <span className={`regular-text flex items-center last-width enable-color font-16 px-3`}>Active</span>
          <Switch className="pr-3" onClick={onToggleLifecycle} enabled={lifecycle === 'ACTIVE'} disabled={false} />
        </div>
      </div>
    );
  };

  return (
    <DeviceItemWrapper key={storage_Id} className="data-container py-2 border-bottom">
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-14 one-line">{name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-14 one-line">{getUseBy(expiry)}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <ProductStatus status={lifecycle || 'INACTIVE'} statusWrapperClassName="status-box" />
      </div>
      <div className="flex items-center overflow-hidden">
        <Menu
          menuList={useFilteredMenuList({
            menuList: [
              { name: 'Edit', onClick: onEditStorageRules, id: 'EDIT', hasPermission: 'STORAGE_RULES_MANAGE' },
              { name: 'Delete', onClick: onDeleteStorageRules, id: 'DELETE', hasPermission: 'STORAGE_RULES_MANAGE' },
            ],
          })}
          ExtraOptions={getExtraMenuOption()}
        />
      </div>
    </DeviceItemWrapper>
  );
};

export const DeviceItemWrapper = styled.div`
  min-height: 56px;
  max-height: 56px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }
  .menu-btn {
    width: 16px;
    height: 16px;
  }

  .items-select-checkbox {
    .checkbox {
      border-radius: 8px;
    }
  }
`;

export default StorageItem;
