import isEquals from 'lodash.isequal';
import moment from 'moment';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { createFilterUrl } from '../../helpers/utils';
import { getExpiryReportList, setExpiryReportList } from '../../store/features/reportsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import TableHeader from '../common/table-header';
import ExpiryReportListItem from './expiry-report-list-item';

const printLogTableHeaders = [
  { name: 'ITEM', key: 'ITEM' },
  { name: 'STORAGE', key: 'STORAGE' },
  { name: 'INITIALS', key: 'INITIALS' },
  { name: 'EXPIRED', key: 'EXPIRED' },
];

const ExpiryReportList = ({ filters = [] }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const filterRef = useRef({});

  const { organizationParentChildIds } = useContext(OrganisationContext);

  const { expiryReportList } = useSelector(state => state.reports);
  const { content: expiryReports, last } = expiryReportList || {};

  const [loading, setLoading] = useState(true);

  const fetchExpiryReportList = (page, merge, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getExpiryReportList({
        merge,
        params: {
          sort_by: 'use_by_date',
          order_by: 'DESC',
          page: page,
          size: 20,
          organization_id: organizationParentChildIds.join(','),
          'use_by.max': moment().endOf('day').unix(),
          ...filterRef.current,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchExpiryReportList(pageRef.current + 1, true, false);
  };

  useEffect(() => {
    const updatedFilter = createFilterUrl(filters);

    if (!isEquals(updatedFilter, filterRef.current)) {
      filterRef.current = updatedFilter;
      fetchExpiryReportList(0, false, true);
    }
  }, [filters]);

  useEffect(() => {
    fetchExpiryReportList(0, false, true);

    return () => {
      dispatch(setExpiryReportList([]));
    };
  }, [organizationParentChildIds]);

  return (
    <ExpiryReportListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (expiryReports || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={printLogTableHeaders} />
          <InfiniteScrollComponent
            dataLength={expiryReports.length}
            hasMore={!last}
            height={64}
            fetchMoreData={fetchMoreData}>
            {(expiryReports || []).map(logItem => (
              <ExpiryReportListItem logItem={logItem} key={logItem?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : (
        <NoDataComponent />
      )}
    </ExpiryReportListWrapper>
  );
};

export const ExpiryReportListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(280px, 1fr);
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
  }
`;

export default ExpiryReportList;
