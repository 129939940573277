import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const addressSlice = createSlice({
  name: 'address',
  initialState: {},
  reducers: {},
});

export const getAddressList =
  ({ params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/addresses`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getAddressDetails =
  ({ address_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/addresses/${address_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const {} = addressSlice.actions;
export default addressSlice.reducer;
