import classNames from 'classnames';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as RightIcon } from '../../assets/icons/arrows/right.svg';
import { ReactComponent as ExportIcon } from '../../assets/images/download.svg';
import { ReactComponent as FilterIcon } from '../../assets/images/filter.svg';
import { ReactComponent as PlusRoundIcon } from '../../assets/images/plus-round.svg';
import Button from '../../components/Elements/button/button';
import Filter from '../../components/filter';
import { OrganisationContext } from '../../context/organisationContext';
import { PageHeaderWrapper } from '../../styles/components/page-header/page-header.styled';
import InputSearch from '../common/input-search';
import MultipleFilter from '../common/multiple-filter';
import Tabs from '../common/tab/tab';
import Pagination from '../pagination/pagination';

const PageHeader = ({
  names = [],
  filter = {},
  setFilter = () => {},
  searchText,
  onSearchChange,
  showFilter,
  showSearch,
  showAddNewBtn,
  filterObject = {},
  showPagination = false,
  pagination = {},
  onAddClick = () => {},
  addBtnPermission = '',
  title = '',
  titleClassName = '',
  className = '',
  showExportButton = false,
  exportButtonPermission = '',
  onExportClick = () => {},
  showBackButton = false,
  onBackClick = () => {},
  showMultipleFilter = false,
  multiFilter = [],
  setMultiFilter = () => {},
  multiFilterFor = '',
  showTabs = false,
  tabDetails = {},
  multiFilterPlacement = 'bottom',
  customAddButton,
  customSearchableDropdownFiled,
  showCustomSearchableDropdownFiled = true,
  addBtnClassName = '',
  Icon = PlusRoundIcon,
  iconColor = '',
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasPermission, checkPermission } = useContext(OrganisationContext);

  const [openFilter, setOpenFilter] = useState(false);
  const [openMultiFilter, setOpenMultiFilter] = useState(false);

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenFilter(false);
    }
  };

  const handleMultipleOnBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenMultiFilter(false);
    }
  };

  return (
    <PageHeaderWrapper className={`flex items-center mx-2 radius-3 card ${className}`}>
      {names && names.length > 0 && (
        <div className="flex flex-1">
          {names.map((name, i) => (
            <div key={i} className="flex items-center">
              <label
                className={`${i < names.length - 1 ? 'regular-text main-grey-text' : 'bold-text'} cursor`}
                onClick={() => (name.onClick ? name.onClick() : navigate(name.path))}>
                {name.text}
              </label>
              {i < names.length - 1 && (
                <span
                  className={`flex regular-text ml-1 mr-1 ${i < names.length - 2 ? 'main-grey-text' : 'regular-text'}`}>
                  <RightIcon />
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {title && (
        <label className={classNames('bold-text font-20 flex flex-1 default-text', titleClassName)}>{title}</label>
      )}

      {showTabs && <Tabs {...tabDetails} />}
      {showFilter && (
        <div className="relative" onBlur={onBlur} tabIndex={0}>
          <div
            className="filter pxy-2 ml-6 radius-3 flex items-center justify-center cursor border"
            onClick={() => setOpenFilter(!openFilter)}>
            <FilterIcon className={openFilter || filter?.value ? 'open-filter-icon' : ''} />
            <label className="semibold-text ml-2">Filter</label>
          </div>
          {openFilter && <Filter objects={filterObject} setFilter={setFilter} values={filter} />}
        </div>
      )}
      {showMultipleFilter && (
        <div className="relative" onBlur={handleMultipleOnBlur} tabIndex={1}>
          <div
            className="filter pxy-2 ml-6 flex items-center justify-center cursor radius-3 border"
            onClick={() => setOpenMultiFilter(!openMultiFilter)}>
            <FilterIcon className={openMultiFilter || multiFilter?.some(f => f.value) ? 'open-filter-icon' : ''} />
            <label
              className={`semibold-text ml-2 ${
                (openMultiFilter || multiFilter?.some(f => f.value)) && 'primary-text'
              }`}>
              Filter
            </label>
          </div>
          {openMultiFilter && (
            <MultipleFilter
              multiFilterPlacement={multiFilterPlacement}
              filters={multiFilter}
              setFilters={setMultiFilter}
              filterFor={multiFilterFor}
            />
          )}
        </div>
      )}
      {customAddButton}
      {showCustomSearchableDropdownFiled && customSearchableDropdownFiled && customSearchableDropdownFiled()}
      {showAddNewBtn && hasPermission(addBtnPermission) && (
        <Button
          fontSize="14px"
          size="small"
          width="136px"
          borderRadius="12px"
          className={classNames('default-secondary specified-width ml-6', addBtnClassName)}
          icon={<Icon height={16} width={16} color={iconColor} />}
          label={t('ADD_NEW')}
          onClick={() => checkPermission(() => onAddClick(), addBtnPermission)}
        />
      )}
      {showSearch && (
        <InputSearch
          placeholder={'Search'}
          value={searchText}
          onChange={onSearchChange}
          className="ml-6 input-search"
        />
      )}
      {showExportButton && hasPermission(exportButtonPermission) && (
        <Button
          className={`primary-grey ml-6 export-btn`}
          label={t('EXPORT')}
          borderRadius={'12px'}
          size={'medium'}
          icon={<ExportIcon height={16} width={16} />}
          onClick={() => checkPermission(() => onExportClick(), exportButtonPermission)}
        />
      )}
      {showPagination && (
        <Pagination
          className="ml-6"
          selectedPage={pagination.selectedPage}
          setSelectedPage={page => {
            pagination.setSelectedPage && pagination.setSelectedPage(page);
          }}
          totalPages={pagination.totalPages}
        />
      )}
      {showBackButton && (
        <Button
          size="tiny"
          width="110px"
          className={`radius-3 secondary specified-width`}
          label={'Back'}
          onClick={() => onBackClick()}
        />
      )}
    </PageHeaderWrapper>
  );
};

export default PageHeader;
