import classNames from 'classnames';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { capitalize, formatText } from '../../../helpers/utils';

const ProductStatus = ({
  withDot = false,
  status = '',
  withDottedBorder = false,
  dotColor = null,
  statusText = null,
  statusTextClassName = '',
  statusWrapperClassName = '',
}) => {
  const theme = useTheme();
  const { colors: themeColors } = theme || {};

  const getStatusStyle = status => {
    switch (status) {
      case 'ACTIVE':
        return { color: themeColors.white, background: themeColors.light_green };
      case 'INACTIVE':
        return { color: themeColors.white, background: themeColors.additional_red };
      case 'CANCELLED':
        return { color: themeColors.white, background: themeColors.additional_red };
      default:
        return { color: themeColors.white, background: themeColors.green };
    }
  };

  return (
    <ProductStatusWrapper
      className={classNames(
        'flex items-center justify-center',
        withDottedBorder && 'dotted-border',
        statusWrapperClassName,
      )}
      withDottedBorder={withDottedBorder}
      {...getStatusStyle(status)}>
      {withDot && <div className="dot mr-1" />}
      <span className={`medium-text font-12 status-text ${statusTextClassName}`}>
        {statusText ? statusText : capitalize(formatText(status))}
      </span>
    </ProductStatusWrapper>
  );
};

const ProductStatusWrapper = styled('div')`
  padding: 4px 8px;
  width: fit-content;
  border-radius: 12px;
  background: ${({ background }) => background};

  &.dotted-border {
    border: 1px dashed ${({ background, borderColor }) => borderColor || background};
  }

  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: ${({ color, dotColor }) => dotColor || color};
  }

  .status-text {
    color: ${({ color }) => color};
  }
`;

export default ProductStatus;
