import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../assets/images/plus.svg';
import PageHeader from '../../../components/page-header';
import StorageGroupDetails from '../../../components/storage-group-details';
import StorageGroupsList from '../../../components/storage-rule-groups';
import { OrganisationContext } from '../../../context/organisationContext';
import useDebounce from '../../../helpers/useDebounceHook';
import { isUserSassOperator } from '../../../helpers/utils';

const StoragesGroups = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);

  const isSassOperator = isUserSassOperator();
  const { storageGroupDetails, storageGroupList = [] } = useSelector(state => state.storageGroup);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-storage-group',
      content: {
        onSuccess: data => navigate(`storage-rule-group/${data.id}`),
      },
    });
  };

  return (
    <StoragesWrapper className="flex-column w-full">
      <Routes>
        <Route
          path="storage-group-details/:storage_group_id"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Storage Rule Groups', path: '/settings/storages-rules' },
                  ...(storageGroupDetails?.name ? [{ text: storageGroupDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton
                onBackClick={() => navigate('/settings/storages-rules')}
              />
              <StorageGroupDetails />
            </Fragment>
          }
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                title={t('STORAGE_RULE_GROUPS')}
                titleClassName="header-title"
                showAddNewBtn={!(isSassOperator && storageGroupList?.length > 0)}
                onAddClick={onAddClick}
                showSearch={true}
                addBtnPermission="STORAGE_RULE_GROUPS_MANAGE"
                addBtnClassName="additional"
                searchText={searchText}
                onSearchChange={setSearchText}
                Icon={PlusIcon}
                iconColor="#08B2E4"
              />
              <StorageGroupsList debouncedSearch={debouncedSearch} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </StoragesWrapper>
  );
};

const StoragesWrapper = styled.div`
  .header-title {
    font-size: 24px;
  }
`;

export default StoragesGroups;
