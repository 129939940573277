import Accounts from '../accounts';
import Categories from '../categories';
import Dashboard from '../dashboard';
import Devices from '../devices';
import Integrations from '../integrations';
import MyAccount from '../my-account';
import Products from '../products';
import Reports from '../reports';
import Settings from '../settings';
import Security from '../settings/security';
import Users from '../settings/security/users';
import StoragesGroups from '../settings/storage-rule';
import Storages from '../storage';

export const ROUTE_PATHS = [
  {
    id: 1,
    path: '/reports',
    Component: Reports,
    // permission: ['REPORTS_VIEW'],
  },
  {
    id: 2,
    path: '/accounts/*',
    Component: Accounts,
    permission: ['NETWORK_LIST_VIEW'],
  },
  {
    id: 3,
    path: '/categories/*',
    Component: Categories,
    permission: ['CATEGORIES_VIEW'],
  },
  {
    id: 4,
    path: '/products/*',
    Component: Products,
    permission: ['PRODUCTS_VIEW'],
  },
  {
    id: 5,
    path: '/storages/*',
    Component: Storages,
    permission: ['STORAGE_VIEW'],
  },
  {
    id: 6,
    path: '/devices/*',
    Component: Devices,
    permission: ['DEVICES_VIEW'],
  },
  {
    id: 7,
    path: '/platforms/integrations/*',
    Component: Integrations,
    permission: ['INTEGRATIONS_VIEW', 'INTEGRATIONS_MANAGE'],
  },
  // {
  //   id: 6,
  //   path: '/platforms/automations',
  //   Component: Automations,
  //   permission: ['AUTOMATIONS_VIEW', 'AUTOMATIONS_MANAGE'],
  // },
  // {
  //   id: 8,
  //   path: '/platforms/automations/:id/*',
  //   Component: AutomationDetails,
  //   permission: ['AUTOMATIONS_MANAGE'],
  // },
  {
    id: 9,
    path: '/settings',
    Component: Settings,
  },
  {
    id: 10,
    path: '/settings/security',
    Component: Security,
  },
  {
    id: 11,
    path: '/settings/security/users/*',
    Component: Users,
  },
  {
    id: 12,
    path: '/settings/storages-rules/*',
    Component: StoragesGroups,
  },
  {
    id: 12,
    path: '/profile',
    Component: Dashboard,
  },
  {
    id: 13,
    path: '/my-account/*',
    Component: MyAccount,
  },
];
