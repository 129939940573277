import moment from 'moment';
import React, { Fragment, useContext, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash-red.svg';
import {
  AccountFilterOptions,
  brandFilterOptions,
  categoryFilterOptions,
  expiryReportFilterOptions,
  lifecycleFilter,
  organizationFilterOptions,
  printLogFilterOptions,
  productsFilterOptions,
  stateFilter,
  usageFilter,
  venueFilterOptions,
} from '../../../constant/filter-option-data';
import { OrganisationContext } from '../../../context/organisationContext';
import { getItemFromLocalStorage } from '../../../helpers/localstorage';
import { getAccountCategoryTagInfo } from '../../../store/features/accountsSlice';
import { getCategories, getProductsList } from '../../../store/features/productsSlice';
import { getStorageItem } from '../../../store/features/storageGroupsSlice';
import DateSelectorElement from '../date-selector/date-selector-element';
import Dropdown from '../dropdown';
import SearchableDropdown from '../searchable-dropdown';

const localUser = getItemFromLocalStorage('user');

const filterFetchFunctions = {
  PRODUCT: { fetchFunction: getProductsList, pageable: true },
  STORAGE: { fetchFunction: getStorageItem, pageable: false },
  CATEGORY: { fetchFunction: getCategories, pageable: true },
  BRAND: {
    fetchFunction: getAccountCategoryTagInfo,
    pageable: true,
    params: { category_tag: 'BRAND' },
    payload: { organizationId: localUser?.organizations?.[0]?.id },
  },
  VENUE: {
    fetchFunction: getAccountCategoryTagInfo,
    pageable: true,
    params: { category_tag: 'VENUE' },
    payload: { organizationId: localUser?.organizations?.[0]?.id },
  },
};

const FilterItem = ({ filter = {}, setFilter, index = 0, filterFor, removeFilter }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { organizationParentChilds, organizationParentChildData } = useContext(OrganisationContext);
  const { organization, networks } = organizationParentChildData;

  const inputTimeout = useRef(null);
  const tomorrowMoment = useRef(moment().add(1, 'days'));

  const [search, setSearch] = useState(null);
  const [inputValue, setInputValue] = useState(filter?.child?.value || null);

  const getOrganizationParentChildIds = () => {
    return organizationParentChilds.map(org => ({
      label: org?.category?.tag?.name ? `${org?.category?.tag?.name} - ${org.name}` : org.name,
      value: org.id,
    }));
  };

  const onInputChange = value => {
    setInputValue(value.trim());
    if (inputTimeout.current) {
      clearTimeout(inputTimeout.current);
    }
    inputTimeout.current = setTimeout(() => {
      setFilter({ ...filter, child: value.trim() ? { value: value.trim() } : null });
    }, 500);
  };

  const filterDropdownOptions = useMemo(() => {
    return {
      USAGE: usageFilter,
      LIFECYCLE: lifecycleFilter,
      STATE: stateFilter,
      ORGANIZATION: getOrganizationParentChildIds(),
    };
  }, [organizationParentChilds]);

  const filterOptions = useMemo(() => {
    const filters = {
      PRODUCTS: productsFilterOptions,
      PRINT_LOG: printLogFilterOptions,
      EXPIRY_REPORT: expiryReportFilterOptions,
      CATEGORY: categoryFilterOptions,
      ACCOUNT: AccountFilterOptions,
      BRAND: brandFilterOptions,
      VENUE: venueFilterOptions,
    };
    if (organization?.allow_networking && networks?.length > 0) {
      filters.PRINT_LOG = [...printLogFilterOptions, ...organizationFilterOptions];
      filters.EXPIRY_REPORT = [...expiryReportFilterOptions, ...organizationFilterOptions];
      filters.CATEGORY = [...categoryFilterOptions, ...organizationFilterOptions];
      filters.PRODUCTS = [...productsFilterOptions, ...organizationFilterOptions];
    }
    return filters;
  }, [organization, networks]);

  const fetchChildOpt = async (
    search,
    _prevOptions,
    { page, merge, fetchFunction, hasMore, pageable, params = {}, payload = {} },
  ) => {
    if (!fetchFunction) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 0,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
    try {
      const optionData = await dispatch(
        fetchFunction({
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            ...params,
          },
          ...payload,
        }),
      );
      let optionContent = [];
      let last = true;
      if (pageable) {
        const { content, ...restResponse } = optionData || {};
        optionContent = optionData ? content : [];
        last = restResponse.last;
      } else {
        optionContent = optionData || [];
      }
      const changedOptions = optionContent.map(option => ({ label: option.name, value: option.id }));

      return {
        options: changedOptions,
        hasMore: !last,
        additional: {
          page: page + 1,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: !last,
          pageable,
        },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: hasMore,
        additional: {
          page: page,
          merge: merge,
          fetchFunction: fetchFunction,
          hasMore: hasMore,
        },
      };
    }
  };

  const getChildrenRenderComponent = () => {
    switch (filter?.childComponent) {
      case 'DROPDOWN':
        return (
          <Dropdown
            options={filterDropdownOptions[filter.value] || []}
            value={filter.child}
            onChange={selected => {
              setFilter({ ...filter, child: selected });
            }}
            placeholder={t('select_type', { type: filter.label.toLowerCase() })}
          />
        );
      case 'MULTI_DROPDOWN':
        return (
          <Dropdown
            options={filterDropdownOptions[filter.value] || []}
            value={filter.child}
            isMulti={true}
            isSearchable={false}
            onChange={selected => {
              setFilter({ ...filter, child: selected });
            }}
            placeholder={t('select_type', { type: filter.label.toLowerCase() })}
          />
        );
      case 'SEARCHABLE_DROPDOWN':
        return (
          <SearchableDropdown
            key={filter.value}
            isSearchable
            defaultAdditional={{ page: 0, ...filterFetchFunctions[filter.value] }}
            customStyle={{ control: { height: '32px', borderRadius: '8px' }, valueContainer: { padding: '0 16px' } }}
            inputValue={search}
            onInputChange={setSearch}
            loadOptions={fetchChildOpt}
            value={filter.child}
            onChange={selected => setFilter({ ...filter, child: selected })}
            placeholder={t('select_type', { type: filter.label.toLowerCase() })}
          />
        );
      case 'INPUT':
        return (
          <input
            className="input h-32px radius-2"
            key={filter.value}
            value={inputValue}
            onChange={({ target: { value } }) => onInputChange(value)}
            placeholder={t('enter_type', { type: filter.label.toLowerCase() })}
          />
        );
      case 'DATE_RANGE':
        return (
          <div className="flex col-gap-4">
            <DateSelectorElement
              name="From"
              format="DD/MM/YY"
              placeholder="dd/mm/yy"
              selectedDate={filter.child?.min}
              maxEndDate={filter.child?.max || tomorrowMoment.current.unix()}
              showCurrentMonth={false}
              setSelectedDate={date => setFilter({ ...filter, child: { ...filter.child, min: date } })}
            />
            <DateSelectorElement
              name="To"
              format="DD/MM/YY"
              placeholder="dd/mm/yy"
              selectedDate={filter.child?.max}
              minStartDate={filter.child?.min}
              maxEndDate={tomorrowMoment.current.unix()}
              showCurrentMonth={false}
              setSelectedDate={date => setFilter({ ...filter, child: { ...filter.child, max: date } })}
            />
          </div>
        );
      default:
        return <Fragment />;
    }
  };

  return (
    <div className="flex-column pxy-4 border-top" key={filter.id}>
      <div className="flex w-full items-center justify-between mb-4">
        <label className="medium-text font-12 flex-1">{`FILTER ${index + 1}`}</label>
        <TrashIcon className="flex items-center justify-center cursor" onClick={() => removeFilter(filter)} />
      </div>
      <Dropdown
        options={filterOptions[filterFor] || []}
        value={filter?.value ? filter : null}
        onChange={selected => {
          setFilter({ ...selected, child: null });
          setInputValue(null);
          setSearch(null);
        }}
        placeholder={t('select_type', { type: 'filter' })}
      />
      {filter.value && (
        <div className="flex-column  mt-4">
          <div className="flex-column">{getChildrenRenderComponent()}</div>
        </div>
      )}
    </div>
  );
};

export default FilterItem;
