import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import StorageList from '../../components/storage-list';
import StorageDetails from '../../components/storage-details';

const Storages = () => {
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);

  const { storageDetails } = useSelector(state => state.storage);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-storage',
      content: {
        onSuccess: data => navigate(`storage-details/${data.id}`),
      },
    });
  };

  return (
    <StoragesWrapper className="flex-column w-full">
      <Routes>
        <Route
          path="storage-details/:storage_id"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Storages', path: '/storages' },
                  ...(storageDetails?.name ? [{ text: storageDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton
                onBackClick={() => navigate('/storages')}
              />
              <StorageDetails />
            </Fragment>
          }
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                title="Storage locations"
                showAddNewBtn={true}
                onAddClick={onAddClick}
                showSearch={true}
                addBtnPermission="STORAGE_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
              />
              <StorageList debouncedSearch={debouncedSearch} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </StoragesWrapper>
  );
};

const StoragesWrapper = styled.div``;

export default Storages;
