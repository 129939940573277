import React, { Fragment, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedDate } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateDevice } from '../../store/features/deviceSlice';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';

const DevicesListItem = ({ device = {}, fetchDeviceList = () => {}, onDeviceRequirePinUpdate = () => {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hasPermission, setModal } = useContext(OrganisationContext);

  const {
    id: devicesId,
    name,
    device_code,
    requires_profile_pin,
    subscription,
    created_on,
    mac_address,
    serial_number,
    storage_rule_group,
    organisation,
  } = device;

  const navigateToDeviceDetails = () => {
    navigate(`device-details-info/${devicesId}`);
  };

  const onUpdatePin = () => {
    setModal({
      type: 'update-device-pin',
      content: { device, onSuccess: fetchDeviceList },
    });
  };

  const onSetLocation = () => {
    setModal({
      type: 'update-device-location',
      content: {
        device: device,
        popupTitle: 'Set location',
        onSuccess: fetchDeviceList,
      },
    });
  };

  const onToggleRequiredPin = updatedRequiredPin => {
    const deviceUpdateRequest = {
      name,
      organisation: { id: organisation.id },
      serial_number,
      mac_address,
      requires_profile_pin: updatedRequiredPin,
    };
    onDeviceRequirePinUpdate({ ...device, requires_profile_pin: updatedRequiredPin });
    dispatch(updateDevice({ device_id: devicesId, request: deviceUpdateRequest })).catch(() => {
      onDeviceRequirePinUpdate(device);
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating device',
          id: nanoid(),
        }),
      );
    });
  };

  return (
    <StorageListItemWrapper
      key={devicesId}
      className="data-container py-2 border-bottom cursor"
      onClick={() => navigateToDeviceDetails()}>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{name}</label>
      </div>

      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">
          {storage_rule_group ? storage_rule_group?.name : 'Local'}
        </label>
      </div>

      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{device_code}</label>
      </div>

      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{organisation?.name}</label>
      </div>

      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{getFormattedDate(created_on, 'DD/MM/YYYY')}</label>
      </div>

      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{subscription?.integration?.external_reference}</label>
      </div>

      <div className="flex items-center overflow-hidden">
        <ProductStatus status={subscription?.status || 'INACTIVE'} />
      </div>

      <div className="flex items-center overflow-hidden">
        <Menu
          menuList={useFilteredMenuList({
            menuList: [
              { name: 'Details', onClick: navigateToDeviceDetails, id: 'DETAILS' },
              { name: 'Update pin', onClick: onUpdatePin, permission: 'DEVICES_MANAGE', key: 'UPDATE_PIN' },
              { name: 'Set location', onClick: onSetLocation, permission: 'DEVICES_MANAGE', key: 'SET_LOCATION' },
            ],
          })}
          ExtraOptions={
            <Fragment>
              {hasPermission('DEVICES_MANAGE') && (
                <div className="flex pl-4 pb-4 pr-3 items-center justify-between">
                  <label className="regular-text one-line font-16 mr-2">Require profile pins</label>
                  <Switch
                    variant={'medium'}
                    enabled={requires_profile_pin}
                    onClick={() => onToggleRequiredPin(!requires_profile_pin)}
                  />
                </div>
              )}
            </Fragment>
          }
          iconColor="#023544"
        />
      </div>
    </StorageListItemWrapper>
  );
};

const StorageListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }

  .items-select-checkbox {
    .checkbox {
      border-radius: 8px;
    }
  }
`;

export default DevicesListItem;
