import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Dropdown from '../../components/common/dropdown';
import { initModal } from '../../constant/InitialData';
import { usageOptions } from '../../constant/optionData';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorFieldJoined } from '../../helpers/utils';
import { createCategory } from '../../store/features/categorySlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const CategoryAddEdit = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setModal, modal, organizationParentChilds } = useContext(OrganisationContext);

  const { isNew, onSuccess } = modal.content;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [category, setCategory] = useState({});

  const { name = '', description = '', usage = '' } = category;

  const checkCategoryErrors = () => {
    if (!name?.trim() || !usage) {
      const error = {
        name: !name?.trim(),
        usage: !usage,
      };
      const errorFields = getErrorFieldJoined(error, (key, _) => key);
      const errorText = `Please add ${errorFields}`;

      setError(error);
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      return true;
    }
    setError({});
    return false;
  };

  const onConfirm = () => {
    if (loading || checkCategoryErrors()) {
      return;
    }
    const request = {
      name: name.trim(),
      // description: description.trim(),
      usage: usage,
      organizations: organizationParentChilds.map(org => ({ id: org.id })),
    };
    setLoading(true);
    dispatch(createCategory({ request }))
      .then(data => {
        onSuccess && onSuccess(data);
        setModal(initModal);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while creating category' }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <CommonPopup
      popupTitle={isNew ? t('category.new') : t('category.edit')}
      confirmButtonProps={{ label: isNew ? t('ADD') : t('SAVE') }}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirm}
      disabled={loading}>
      <CategoryAddEditWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-1 font-12">{t('NAME')}</label>
          <input
            autoComplete="off"
            className={classNames('input radius-3 h-40px', error?.name && 'error-border')}
            onChange={({ target: { value } }) => {
              setCategory({ ...category, name: value });
              setError({ ...error, name: false });
            }}
            placeholder={t('enter_type', { type: 'name' })}
            value={name || ''}
          />
        </div>
        {/* <div className="w-full flex-column">
          <label className="egular-text main-grey-text mb-1 font-12">{t('CATEGORY_NOTES')}</label>
          <textarea
            className={classNames('textarea radius-3', error?.description && 'error-border')}
            onChange={({ target: { value } }) => {
              setCategory({ ...category, description: value });
              setError({ ...error, description: false });
            }}
            placeholder={t('enter_type', { type: 'category notes' })}
            rows={4}
            value={description || ''}
          />
        </div> */}
        <Dropdown
          customStyle={{ control: { height: '40px', marginTop: '4px', borderRadius: '12px' } }}
          name={t('USAGE')}
          options={usageOptions}
          value={usageOptions.find(uo => uo.value === usage)}
          onChange={option => setCategory({ ...category, usage: option.value })}
          placeholder={t('select_type', { type: 'usage' })}
          error={error.usage && !usage}
        />
      </CategoryAddEditWrapper>
    </CommonPopup>
  );
};

const CategoryAddEditWrapper = styled.div``;

export default CategoryAddEdit;
