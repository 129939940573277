import React from 'react';
import styled from 'styled-components';
import { getFormattedDate } from '../../helpers/utils';

const ExpiryReportListItem = ({ logItem = {} }) => {
  const { product, storage, initials, printed_info, storage_rule } = logItem;

  const { use_by_date } = printed_info || {};

  return (
    <ExpiryReportListItemWrapper className="data-container py-2 border-bottom cursor">
      <div className="flex items-center overflow-hidden">
        <label className="medium-text font-16 one-line">{product?.name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="medium-text font-16 one-line">{storage?.name || storage_rule?.name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text overflow-hidden one-line">{initials}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text overflow-hidden one-line">
          {use_by_date ? getFormattedDate(use_by_date, 'MMMM DD, YYYY') : ''}
        </label>
      </div>
    </ExpiryReportListItemWrapper>
  );
};

const ExpiryReportListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default ExpiryReportListItem;
