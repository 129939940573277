import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';

export const storageGroupsSlice = createSlice({
  name: 'storageGroup',
  initialState: {
    storageGroupList: [],
    storageGroupDetails: {},
    storageItemList: [],
  },
  reducers: {
    setStorageGroupList: (state, { payload }) => {
      state.storageGroupList = payload;
    },
    setStorageGroupDetails: (state, { payload }) => {
      state.storageGroupDetails = payload;
    },
    setStorageItem: (state, { payload }) => {
      state.storageItemList = payload;
    },
  },
});

export const getStorageGroupList =
  ({ fetchOnly = false, params, merge }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage_rule_groups`, { params });
      !fetchOnly && dispatch(setStorageGroupList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStorageGroupDetails =
  ({ params, storage_rule_group_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage_rule_groups/${storage_rule_group_id}`, { params });
      dispatch(setStorageGroupDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStorageItem =
  ({ fetchOnly, params, merge }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage_rules`, { params });
      !fetchOnly && dispatch(setStorageItem(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createStorageGroup =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage_rule_groups`, request);
      dispatch(getStorageGroupList({ params: { page: 0, size: 15, search: '' } }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateStorageGroup =
  ({ storage_rule_group_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/storage_rule_groups/${storage_rule_group_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteStorageGroup =
  ({ storage_group_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/storage_rule_groups/${storage_group_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createStorageRules =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage_rules`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateStorageRules =
  ({ storage_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/storage_rules/${storage_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteStorageRules =
  ({ storage_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/storage_rules/${storage_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const changeGroupStorageRulesDevice =
  ({ rules_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage_rules/${rules_id}/devices`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const changeGroupStorageRulesGroup =
  ({ group_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage_rule_groups/${group_id}/devices`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setStorageGroupList, setStorageGroupDetails, setStorageItem } = storageGroupsSlice.actions;
export default storageGroupsSlice.reducer;
