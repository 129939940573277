import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Dropdown from '../../components/common/dropdown';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import Switch from '../../components/common/switch';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { lowercase, onlyNumbers } from '../../helpers/utils';
import { getCategoryList } from '../../store/features/categorySlice';
import { addProduct, addProductLabels, addProductStorage } from '../../store/features/productsSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const durationUnitOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Days', value: 'DAYS' },
];

const AddProduct = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userOrganization } = useSelector(state => state.user);

  const { modal, setModal, organizationParentChilds } = useContext(OrganisationContext);
  const { onSuccess } = modal.content;

  const [product, setProduct] = useState({});
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchedCategory, setSearchedCategory] = useState(null);

  const { name, description, category, is_expiry_override, number_of_days, duration_unit } = product;

  const getCategoryTypeFromUsage = usage => {
    return usage === 'SINGLE' ? '(For sale)' : usage === 'COMPONENT' ? '(For Prep)' : '';
  };

  const checkErrors = () => {
    const number_of_days_error = is_expiry_override && !number_of_days;
    const duration_unit_error = is_expiry_override && !duration_unit;
    if (!name?.trim() || !category || number_of_days_error || duration_unit_error) {
      const error = {
        name: !name?.trim(),
        category: !category,
        days: number_of_days_error,
        duration: duration_unit_error,
      };
      const errorFields = Object.entries(error)
        .filter(([_, value]) => value)
        .map(([key]) => (key === 'days' ? lowercase(duration_unit || 'DAYS') : key))
        .join(', ');
      const errorText = `Please add ${errorFields}`;
      setError(error);
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      return true;
    }
    setError({});
    return false;
  };

  const onConfirm = async () => {
    if (loading || checkErrors()) {
      return;
    }
    setLoading(true);
    try {
      const addProductRequest = {
        name: name,
        // description: description || '',
        product_type: 'PHYSICAL',
        categories: [
          {
            id: category.id,
          },
        ],
        lifecycle: 'ACTIVE',
        organizations: organizationParentChilds.map(org => ({ id: org.id })),
      };

      const addStorageRequest = {
        expiry_type: 'OVERRIDE',
        expiry: { units: number_of_days || null, period: duration_unit || null },
        storage: null,
      };

      const addLabelRequest = {
        text: '',
        label_type: category.usage === 'COMPONENT' ? 'ALLERGENS' : 'INGREDIENTS',
        barcode: null,
        image: null,
        label_text: {
          ingredients: '',
          allergens: '',
          custom_allergens: '',
          may_contain: '',
          price: '',
        },
      };

      const addedProduct = await dispatch(addProduct({ request: addProductRequest }));
      await dispatch(addProductLabels({ request: addLabelRequest, product_id: addedProduct.id }));

      if (is_expiry_override) {
        await dispatch(addProductStorage({ request: addStorageRequest, product_id: addedProduct.id }));
      }

      onSuccess(addedProduct);
      setLoading(false);
      setModal(initModal);
    } catch (error) {
      setLoading(false);
      dispatch(addToast({ error: true, text: 'Error while adding product', id: nanoid() }));
    }
  };

  const fetchCategoryList = async (page, search = '') => {
    try {
      const categoryList = await dispatch(
        getCategoryList({
          organizationId: userOrganization.id,
          merge: false,
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            lifecycle: 'ACTIVE',
          },
        }),
      );
      return categoryList;
    } catch (error) {
      return null;
    }
  };

  const fetchCategoryOptions = async (search, _prevOptions, { page, merge }) => {
    const categoryOptions = await fetchCategoryList(page, search);
    const { content, ...restResponse } = categoryOptions || {};
    const categoryContent = categoryOptions ? content : [];
    const changedOptions = categoryContent.map(option => ({
      ...option,
      label: `${option.name} ${getCategoryTypeFromUsage(option.usage)}`,
      value: option.id,
    }));

    return {
      options: changedOptions,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  return (
    <CommonPopup
      popupTitle="New Product"
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirm}
      disabled={loading}>
      <AddProductWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-1 font-12">{t('NAME')}</label>
          <input
            className={classNames('input radius-3 h-40px', error?.name && 'error-border')}
            onChange={({ target: { value } }) => {
              setProduct({ ...product, name: value });
              setError({ ...error, name: false });
            }}
            placeholder={t('enter_type', { type: 'name' })}
            value={name || ''}
          />
        </div>
        {/* <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-1 font-12">{t('PRODUCT_NOTES')}</label>
          <textarea
            className={classNames('textarea radius-3')}
            onChange={({ target: { value } }) => setProduct({ ...product, description: value })}
            placeholder={t('enter_type', { type: 'product notes' })}
            rows={4}
            value={description || ''}
          />
        </div> */}
        <SearchableDropdown
          customStyle={{ control: { borderRadius: '12px' } }}
          inputValue={searchedCategory}
          onInputChange={setSearchedCategory}
          loadOptions={fetchCategoryOptions}
          className="w-full"
          placeholder={t('select_type', { type: 'category' })}
          name="Category"
          value={category}
          onChange={option => {
            setProduct({ ...product, category: option });
            setError({ ...error, category: false });
          }}
          isSearchable
          defaultAdditional={{ page: 0 }}
          error={error.category && !category}
        />
        <div className="flex flex-1 w-full items-center mt-2 justify-between">
          <label className="bold-text">EXPIRY OVERRIDE</label>
          <Switch
            enabled={is_expiry_override}
            onClick={() => setProduct({ ...product, is_expiry_override: !is_expiry_override })}
          />
        </div>
        {is_expiry_override && (
          <div className="expiry-override col-gap-6">
            <div className="flex-column w-full justify-center">
              <div className="mb-1">
                <label className="regular-text main-grey-text font-12">
                  {t('number_of_type', { type: lowercase(duration_unit || 'DAYS') })}
                </label>
              </div>
              <input
                className={classNames('input radius-3 h-40px w-full', error?.days && 'error-border')}
                onChange={({ target: { value } }) => {
                  if ((value && onlyNumbers.test(value)) || !value) {
                    setProduct({ ...product, number_of_days: value });
                    setError({ ...error, days: false });
                  }
                }}
                placeholder="-"
                value={number_of_days || ''}
              />
            </div>
            <Dropdown
              customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
              name={'Duration unit'}
              options={durationUnitOptions}
              value={durationUnitOptions.find(uo => uo.value === duration_unit)}
              onChange={option => {
                setProduct({ ...product, duration_unit: option.value });
                setError({ ...error, duration: false });
              }}
              placeholder={t('select_type', { type: 'unit' })}
              error={error?.duration}
            />
          </div>
        )}
      </AddProductWrapper>
    </CommonPopup>
  );
};

const AddProductWrapper = styled.div`
  .expiry-override {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export default AddProduct;
