import React from 'react';
import DropDown from '../dropdown';

const Item = ({ filter, setFilter }) => {
  const getFilterProps = object => {
    return {};
  };

  const getValues = () => {
    return [];
  };

  return (
    <div className="flex-column pxy-4">
      <DropDown
        options={filter.objects?.map(o => getFilterProps(o))}
        selected={filter.objects?.map(o => getFilterProps(o)).find(o => o.type === filter.object?.type)}
        setSelected={o => {
          setFilter({ ...filter, object: o, value: null });
        }}
        placeholder="Select object"
      />
      {filter.object && (
        <div className="flex-column  mt-4">
          <div className="flex-column">
            <DropDown
              options={getValues()}
              selected={filter.value}
              setSelected={o => setFilter({ ...filter, value: o })}
              placeholder={`Select ${filter.object.name.toLowerCase()}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
