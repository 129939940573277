import isEqual from 'lodash.isequal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { createFilterUrl } from '../../helpers/utils';
import { getCategoryList, setCategoryList } from '../../store/features/categorySlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import TableHeader from '../common/table-header';
import CategoryListItem from './category-list-item';

const categoryTableHeaders = [
  { name: 'CATEGORY', key: 'CATEGORY' },
  // { name: 'CATEGORY NOTES', key: 'CATEGORY' },
  { name: 'FOR', key: 'FOR' },
  { name: 'USAGE', key: 'USAGE' },
  { name: 'STATE', key: 'STATE' },
];

const CategoryList = ({ debouncedSearch = '', filters = [] }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const filterRef = useRef(createFilterUrl(filters));

  const { userOrganization } = useSelector(state => state.user);
  const { categoryList } = useSelector(state => state.category);
  const { content: category, last } = categoryList || {};

  const [loading, setLoading] = useState(true);

  const fetchCategoryList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getCategoryList({
        organizationId: userOrganization.id,
        merge,
        params: {
          page: page,
          size: 20,
          lifecycle: 'ACTIVE',
          search: debouncedSearch,
          ...filterRef.current,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchCategoryList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchCategoryList(0, false, debouncedSearch, true);
  }, [userOrganization, debouncedSearch]);

  useEffect(() => {
    const updatedFilter = createFilterUrl(filters);

    if (!isEqual(updatedFilter, filterRef.current)) {
      filterRef.current = updatedFilter;
      fetchCategoryList(0, false, debouncedSearch, true);
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(setCategoryList([]));
    };
  }, []);

  return (
    <CategoryListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (category || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={categoryTableHeaders} />
          <InfiniteScrollComponent
            dataLength={category.length}
            hasMore={!last}
            height={64}
            fetchMoreData={fetchMoreData}>
            {(category || []).map(category => (
              <CategoryListItem category={category} key={category?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </CategoryListWrapper>
  );
};

export const CategoryListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(300px, 1fr) minmax(200px, 1fr) 300px 80px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default CategoryList;
