import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/icons/arrows/down.svg';
import { getErrorDescription } from '../../../helpers/utils';
import { getAddressDetails, getAddressList } from '../../../store/features/addressSlice';
import { addToast } from '../../../store/features/toastSlice';

const defaultCustomStyle = {
  control: {},
  valueContainer: {},
  menu: {},
  menuList: {},
};

const variants = {
  primary: {
    control: { height: '40px', borderRadius: '12px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected, selectProps } = props;
  const { inputValue } = selectProps;

  return (
    <div
      className={`flex items-center w-full pxy-2 radius-1 cursor option-wrapper ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>
        <Highlighter
          searchWords={[inputValue]}
          autoEscape={true}
          textToHighlight={data.label}
          highlightTag={({ children, _highlightIndex }) => (
            <strong className="highlighted-text primary-text">{children}</strong>
          )}
        />
      </span>
    </div>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <DownIcon />
    </components.DropdownIndicator>
  );
};

const AddressInput = ({
  setSelectedAddress = () => {},
  address = {},
  error,
  wrapperClassName = '',
  customStyle = defaultCustomStyle,
  menuPlacement = 'auto',
  variant = '',
}) => {
  const dispatch = useDispatch();

  const [addressText, setAddressText] = useState(address.line1);
  const [addressList, setAddressList] = useState([]);

  const theme = useTheme();

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.colors.additional_red : menuIsOpen ? theme.colors.primary : theme.colors.main_border,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '32px',
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.colors.additional_red : menuIsOpen ? theme.colors.primary : theme.colors.main_border,
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Lato Regular',
      color: theme.colors.main_grey,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.colors.black_text,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato Regular',
      padding: '4px 16px',
      ...customStyle.valueContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      padding: '8px',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    clearIndicator: () => ({
      display: 'none',
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato Regular',
      color: theme.colors.main_grey,
    }),
  };

  const getAddressData = async inputValue => {
    try {
      const response = await dispatch(getAddressList({ params: { search: inputValue } })).catch(err => {
        const errorText = getErrorDescription(err, 'Something went wrong while getting address list');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      });
      const formatedAddressList = response.map(data => {
        return { ...data, value: data.id, label: data.address };
      });
      return formatedAddressList;
    } catch (error) {
      const errorText = getErrorDescription(err, 'Something went wrong while getting address list');
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  };

  const handleInputChange = newValue => {
    setAddressText(newValue);
    return newValue;
  };

  const loadOptions = async inputValue => {
    try {
      const options = await getAddressData(inputValue);
      setAddressList(options);
      return options;
    } catch (error) {}
  };

  const handleChangeOption = async option => {
    dispatch(getAddressDetails({ address_id: option.id })).then(response => {
      setSelectedAddress(response);
    });
  };

  return (
    <AsyncDropdownWrapper>
      <AsyncSelect
        className={wrapperClassName}
        classNamePrefix={'async-select'}
        defaultOptions={addressList || []}
        inputValue={addressText || ''}
        onInputChange={handleInputChange}
        loadOptions={loadOptions}
        maxMenuHeight={200}
        placeholder={'Start enter address'}
        onChange={option => handleChangeOption(option)}
        value={address.line1 ? { ...address, label: address.line1, value: address.id } : null}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        components={{
          Option,
          DropdownIndicator,
        }}
      />
    </AsyncDropdownWrapper>
  );
};

export const AsyncDropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.menu_item_hover};
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.menu_item_hover};

    &:hover {
      background-color: ${({ theme }) => theme.colors.menu_item_hover};
    }
  }
`;

export default AddressInput;
