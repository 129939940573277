import React, { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';
import AccountDetails from '../../components/accounts-details';
import { OrganisationContext } from '../../context/organisationContext';

const AccountTypePermissions = {
  ACCOUNT: null,
  VENUE: ['ORGANIZATIONS_VIEW'],
};

const AccountTypeComponent = {
  ACCOUNT: AccountDetails,
  BRAND: AccountDetails,
  VENUE: AccountDetails,
  // VENUE: VenueDetails,
};

const MyAccount = () => {
  const { hasMultiplePermission } = useContext(OrganisationContext);
  const { userOrganization } = useSelector(state => state.user);
  const { id: organizationId, category } = userOrganization;
  const { tag } = category?.tag || {};

  const setAccountComponent = () => {
    const permissions = AccountTypePermissions[tag];
    const Component = AccountTypeComponent[tag];

    if (!hasMultiplePermission(permissions)) {
      return <Fragment />;
    }

    if (Component) {
      return <Component fromMyAccount organizationId={organizationId} tag={tag} />;
    }
    return <Fragment />;
  };

  return <div className="flex-column w-full">{setAccountComponent()}</div>;
};

export default MyAccount;
