import classNames from 'classnames';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import Dropdown from '../../components/common/dropdown';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorFieldJoined, lowercase, onlyNumbers } from '../../helpers/utils';
import {
  changeGroupStorageRulesDevice,
  createStorageRules,
  updateStorageRules,
} from '../../store/features/storageGroupsSlice';
import { createStorage, updateStorage } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const durationUnitOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Days', value: 'DAYS' },
];

const AddEditStorage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal, organizationParentChilds } = useContext(OrganisationContext);
  const {
    onSuccess,
    id = '',
    isEdit,
    storage,
    isCreateStorageRuels = false,
    isUpdateStorageRuels = false,
    isFromDeviceDetails = false,
  } = modal.content;

  const { name: storageName, expiry, storage_type, lifecycle } = storage || {};

  const [loading, setLoading] = useState(false);

  const onCreateStorage = data => {
    const { name, number_of_days, duration_unit } = data;
    if (isCreateStorageRuels) {
      const isGroup = isFromDeviceDetails
        ? {}
        : {
            group: {
              id: id,
            },
          };

      const addStorageRulesRequest = {
        name,
        storage_type: 'OTHER',
        expiry: {
          units: number_of_days,
          period: duration_unit,
        },
        lifecycle: 'ACTIVE',
        organizations: organizationParentChilds.map(org => ({ id: org.id })),
        ...isGroup,
      };

      dispatch(createStorageRules({ request: addStorageRulesRequest }))
        .then(async addedStorage => {
          if (isFromDeviceDetails) {
            await dispatch(changeGroupStorageRulesDevice({ rules_id: addedStorage?.id, request: [{ id: id }] }));
          }
          onSuccess(addedStorage);
          dispatch(addToast({ success: true, text: t('STORAGE_RULES_ADD'), id: nanoid() }));
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_RULES_ADD');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    } else {
      const addStorageRequest = {
        name,
        storage_type: 'OTHER',
        expiry: {
          units: number_of_days,
          period: duration_unit,
        },
        lifecycle: 'ACTIVE',
      };

      dispatch(createStorage({ request: addStorageRequest }))
        .then(addedStorage => {
          onSuccess(addedStorage);
          dispatch(addToast({ success: true, text: t('STORAGE_ADD'), id: nanoid() }));
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_ADD');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    }
  };

  const onUpdateStorage = data => {
    const { name, number_of_days, duration_unit } = data;
    if (isUpdateStorageRuels) {
      const groupData = isFromDeviceDetails
        ? {}
        : {
            group: {
              id: id,
            },
          };
      const editStorageRequest = {
        name,
        storage_type: storage_type,
        expiry: {
          units: number_of_days,
          period: duration_unit,
        },
        lifecycle: lifecycle,
        ...groupData,
      };
      dispatch(updateStorageRules({ storage_id: storage.id, request: editStorageRequest }))
        .then(editedStorage => {
          dispatch(addToast({ success: true, text: t('STORAGE_RULES_UPDATE'), id: nanoid() }));
          onSuccess(editedStorage);
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_RULES_UPDATE');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    } else {
      const editStorageRequest = {
        name,
        storage_type: storage_type,
        expiry: {
          units: number_of_days,
          period: duration_unit,
        },
        lifecycle: lifecycle,
      };
      dispatch(updateStorage({ storage_id: storage.id, request: editStorageRequest }))
        .then(editedStorage => {
          dispatch(addToast({ success: true, text: t('STORAGE_UPDATE'), id: nanoid() }));
          onSuccess(editedStorage);
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_UPDATE');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    }
  };

  const onConfirm = async data => {
    if (loading) {
      return;
    }
    setLoading(true);
    if (isEdit) {
      onUpdateStorage(data);
    } else {
      onCreateStorage(data);
    }
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: isEdit
      ? { name: storageName, number_of_days: expiry.units, duration_unit: expiry.period }
      : { name: '', number_of_days: '', duration_unit: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('name'),
      number_of_days: Yup.number().required('days'),
      duration_unit: Yup.string().required('duration'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onConfirm,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorFields = getErrorFieldJoined(errors, (key, value) => {
        return key === 'number_of_days' ? lowercase(values.duration_unit || 'DAYS') : value;
      });
      const errorText = `Please add ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <CommonPopup
      popupTitle={isEdit ? t('edit_type', { type: 'Storage' }) : t('new_type', { type: 'Storage' })}
      confirmButtonProps={{ label: isEdit ? t('SAVE') : t('ADD') }}
      onCancel={() => setModal(initModal)}
      onConfirm={handleSubmit}
      disabled={loading}>
      <AddEditStorageWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-1 font-12">{t('NAME')}</label>
          <input
            className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={t('enter_type', { type: 'name' })}
            value={values.name || ''}
          />
        </div>
        <Dropdown
          customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
          name={'Duration unit'}
          options={durationUnitOptions}
          value={durationUnitOptions.find(uo => uo.value === values.duration_unit)}
          onChange={option => setFieldValue('duration_unit', option.value)}
          placeholder={t('select_type', { type: 'unit' })}
          error={errors.duration_unit && !values.duration_unit}
        />
        <div className="flex-column w-full justify-center">
          <div className="mb-1">
            <label className="regular-text main-grey-text font-12">
              {t('number_of_type', { type: lowercase(values.duration_unit || 'DAYS') })}
            </label>
          </div>
          <input
            className={classNames(
              'input radius-3 h-40px w-full',
              errors.number_of_days && !values.number_of_days && 'error-border',
            )}
            onChange={({ target: { value } }) => {
              ((value && onlyNumbers.test(value)) || !value) && setFieldValue('number_of_days', value);
            }}
            placeholder="-"
            value={values.number_of_days || ''}
          />
        </div>
      </AddEditStorageWrapper>
    </CommonPopup>
  );
};

const AddEditStorageWrapper = styled.div``;

export default AddEditStorage;
