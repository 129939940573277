import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 256px;
  background: ${({ theme }) => theme.colors.sidebarBackground};
  max-height: 100vh;
  overflow-y: scroll;

  .expand-arrow-icon {
    path {
      stroke-width: 3px;
    }
  }

  &.expanded {
    width: 256px;
    min-width: 256px;

    .expand-icon {
      left: 259px;
      top: 44px;
    }
  }

  &.collapsed {
    width: 80px;
    min-width: 80px;

    .expand-icon {
      left: 80px;
      top: 56px;
    }
  }

  .expand-icon {
    background: ${({ theme }) => theme.colors.primary};
    left: 235px;
    top: 24px;
    z-index: 10;
    padding: 6px 6px;
    border-radius: 50%;
    box-shadow: 3px 0px 2px rgba(0, 0, 0, 0.19);
    border: 2px solid #ffffff;
  }

  .sidebar-top {
    display: flex;
    flex-direction: column;
    position: relative;
    outline: 0;
    flex: 1 1;
  }

  .sidebar-bottom {
    background: ${({ theme }) => theme.colors.sidebar_bottom_background};
    position: relative;

    .user-name {
      height: 56px;

      .profile-icon {
        border-radius: 50%;
      }
    }

    .show-menu {
      height: 108px;
    }

    .hidden-menu {
      height: 0;
    }

    .user-menu {
      position: absolute;
      background: ${({ theme }) => theme.colors.sidebar_bottom_background};
      z-index: 2;
      bottom: 56px;
      overflow: hidden;
      transition: height 200ms linear 0s;
      box-shadow: 2px 2px 15px rgba(5, 49, 73, 0.1);
      border-radius: 8px 8px 0px 0px;

      .user-action {
        &:hover {
          background: ${({ theme }) => theme.colors.sidebar_item_hover};
          border-color: ${({ theme }) => theme.colors.sidebar_item_hover};

          .user-action-icon {
            svg {
              color: ${({ theme }) => theme.colors.primary};
            }
          }

          .user-action-text {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }

      .user-action-selected {
        background: ${({ theme }) => theme.colors.sidebar_item_hover};
        .user-action-icon {
          background-color: ${({ theme }) => theme.colors.sidebar_menu_icon_selected};
          svg {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }

    .user-menu-border {
      border-bottom: 1px solid ${({ theme }) => theme.colors.main_border_light};
    }
  }

  .sidebar-bottom-opened {
    border-radius: 0px 0px 12px 12px;
  }

  ${({ theme: { down, up, breakpoints } }) => `
    ${down(breakpoints.lg)} {
      &.expanded {
        width: 220px;
        min-width: 220px;

        .expand-icon {
          left: 224px;
        }
      }
    }

    ${up(breakpoints.xl)} {
      &.expanded {
        width: 280px;
        min-width: 280px;

        .expand-icon {
          left: 285px;
        }
      }
    }
  `}
`;

export const SidebarMenuItemWrapper = styled.div`
  user-select: none;

  .menu-arrow {
    transition-duration: 300ms;
    transition-property: transform;
  }

  .menu-arrow-down {
    transform: rotate(90deg);
  }

  .menu-item-container {
    height: 48px;

    .mi-item {
      height: 48px;

      .mi-icon-stroke,
      .mi-icon {
        height: 16px;
        width: 16px;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.sidebar_item_hover};
        border-color: ${({ theme }) => theme.colors.sidebar_item_hover};

        .icon-container {
          svg {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }

    .mi-item-selected {
      .menu-item-name {
        color: ${({ theme }) => theme.colors.primary};
      }
      .icon-container {
        background-color: ${({ theme }) => theme.colors.sidebar_menu_icon_selected};
        svg {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }

    .mi-subitem-selected {
      .menu-item-name {
        color: ${({ theme }) => theme.colors.primary};
      }
      .icon-container {
        svg {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .menu-subitems {
    overflow: hidden;
    transition: height 300ms linear 0s;
    margin-top: 2px;

    .subitem-container {
      .subitem {
        height: 48px;
      }

      &:hover {
        background: ${({ theme }) => theme.colors.sidebar_item_hover};
        border-color: ${({ theme }) => theme.colors.sidebar_item_hover};

        .icon-container {
          svg {
            color: ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }

    .subitem-selected {
      .sub-item-name {
        color: ${({ theme }) => theme.colors.primary};
      }
      .icon-container {
        background-color: ${({ theme }) => theme.colors.sidebar_menu_icon_selected};
        svg {
          color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .menu-subitems-hide {
    height: 0;
    overflow: hidden;
    transition: height 300ms linear 0s;
  }
`;
