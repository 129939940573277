import React, { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { ReactComponent as MinusIcon } from '../../../assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg';

const CustomToolbar = ({ showPlus = false, onPlus }) => {
  return (
    <div id="toolbar">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      {showPlus && (
        <button className="ql-insertPlus plus-minus" data-tooltip-id="plus-selector" onClick={onPlus}>
          <MinusIcon />
          <PlusIcon />
        </button>
      )}
    </div>
  );
};

const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {},
  },
};

const insertAttributeDeltaKey = ['insert', 'attributes'];

const Editor = ({ value, setValue, placeholder = '', showPlus = false, onPlus, disableInput = false }) => {
  const quillRef = useRef(null);

  const [selectedLength, setSelectedLength] = useState(0);

  return (
    <EditorWrapper className="mb-8 border border radius-3">
      <ReactQuill
        ref={quillRef}
        className="regular-text font-16"
        theme="snow"
        value={value}
        onChange={(eValue, delta) => {
          const insertOrAttributeOps = (delta.ops || [])?.filter(op =>
            Object.keys(op).find(o => insertAttributeDeltaKey.includes(o)),
          );
          if (disableInput && selectedLength && !(insertOrAttributeOps.length > 0)) {
            quillRef.current.getEditor().clipboard.dangerouslyPasteHTML(value);
            return;
          }
          setValue(eValue);
        }}
        onChangeSelection={range => {
          if (disableInput) {
            setSelectedLength(range?.length || 0);
          }
        }}
        modules={modules}
        placeholder={placeholder}
        onKeyDown={e => disableInput && e.preventDefault()}
      />
      <CustomToolbar showPlus={showPlus} onPlus={() => onPlus(quillRef.current)} />
    </EditorWrapper>
  );
};

export const EditorWrapper = styled.div`
  .quill {
    .ql-snow {
      border: unset;
    }

    .ql-editor {
      font-family: 'Lato Regular';
      font-size: 16px;
    }

    .ql-blank::before {
      color: ${({ theme }) => theme.colors.main_grey};
      font-style: normal;
    }
  }

  .ql-toolbar {
    border: unset;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 0 0 12px 12px;
    padding: 4px 8px;
    display: flex;

    .ql-insertPlus {
      margin-left: auto;
    }

    .ql-bold,
    .ql-italic,
    .ql-underline {
      margin-right: 4px;
      border-radius: 6px;
    }

    .ql-active {
      background-color: ${({ theme }) => theme.colors.main_orange_O_10};
      color: ${({ theme }) => theme.colors.main_orange};

      svg {
        path,
        line,
        rect,
        .ql-stroke {
          stroke: ${({ theme }) => theme.colors.main_orange};
        }
      }
    }
    .plus-minus {
      display: flex;
      width: fit-content;
      column-gap: 8px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.colors.primary_background_light};
      color: ${({ theme }) => theme.colors.primary};

      svg {
        path,
        line,
        rect,
        .ql-stroke {
          stroke: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }
`;

export default Editor;
