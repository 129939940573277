import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getContactValueByType, getOrganizationByCategoryTag } from '../../helpers/utils';
import Menu from '../common/menu';

const VenueListItem = ({ venue = {}, isLast = false, fromMyAccount = false }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { hasPermission } = useContext(OrganisationContext);

  const { name, primary_location, contact_details, parent_organization, owners } = venue;
  const contactEmail = getContactValueByType(contact_details, 'EMAIL');
  const contactPhone = getContactValueByType(contact_details, 'MOBILE');
  const parentOrganizationAsBrand = getOrganizationByCategoryTag(parent_organization, 'BRAND');

  const onVenueClick = () => {
    if (hasPermission('ORGANIZATIONS_VIEW')) {
      navigate(`venue-details/${venue.id}`);
    }
  };

  const venueItemMenu = [{ name: t('DETAILS'), onClick: onVenueClick, permission: 'ORGANIZATIONS_VIEW' }].filter(
    m => !m.permission || hasPermission(m.permission),
  );

  return (
    <VenueListItemWrapper className={`data-container py-2 cursor ${!isLast && 'border-bottom'}`} onClick={onVenueClick}>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="medium-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{parentOrganizationAsBrand?.name}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{primary_location?.formatted_address}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactPhone?.contact_value}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactEmail?.contact_value}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{owners?.[0]?.name || ''}</label>
      </div>
      <div className="flex items-center justify-center">
        {venueItemMenu.length > 0 && <Menu menuList={venueItemMenu} />}
      </div>
    </VenueListItemWrapper>
  );
};

const VenueListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default VenueListItem;
