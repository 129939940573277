import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AddressInput from './address-input';

const Address = ({
  address = {},
  handleChange = () => {},
  error = {},
  customLabels = { line1: 'ADDRESS_LINE_1', line2: 'ADDRESS_LINE_2' },
  wrapperClassName = '',
  labelClassName = '',
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex-column items-center justify-center w-full row-gap-6', wrapperClassName)}>
      <div className="flex-column w-full row-gap-2 line-1">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>
          {t(customLabels.line1)}
        </label>
        <AddressInput
          address={{ ...address, label: address.line1 }}
          setSelectedAddress={add => handleChange('address', add)}
          error={error.line1}
          wrapperClassName="address-input"
          customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
        />
      </div>
      <div className="flex-column w-full row-gap-2 line-2">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>
          {t(customLabels.line2)}
        </label>
        <input
          autoComplete="turnoff"
          className={classNames('input radius-3 h-40px', error?.line2 && 'error-border')}
          onChange={e => handleChange('line2', e.target.value)}
          placeholder={t('enter_type', { type: 'address' })}
          value={address?.line2 || ''}
        />
      </div>
      <div className="flex-column w-full row-gap-2 city">
        <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{'Town/City'}</label>
        <input
          autoComplete="turnoff"
          className={classNames('input radius-3 h-40px', error?.city && 'error-border')}
          onChange={e => handleChange('city', e.target.value)}
          placeholder={t('enter_type', { type: 'city' })}
          value={address?.city || ''}
        />
      </div>
      <div className="flex items-center col-gap-6 w-full">
        <div className="flex-column w-full row-gap-2 state">
          <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{t('COUNTY')}</label>
          <input
            autoComplete="turnoff"
            className={classNames('input radius-3 h-40px w-full', error?.state && 'error-border')}
            onChange={e => handleChange('state', e.target.value)}
            placeholder={t('enter_type', { type: 'county' })}
            value={address?.state || ''}
          />
        </div>
        <div className="flex-column w-full row-gap-2 zip-code">
          <label className={classNames('regular-text main-grey-text font-12', labelClassName)}>{t('POST_CODE')}</label>
          <input
            autoComplete="turnoff"
            className={classNames('input radius-3 h-40px w-full', error?.postcode && 'error-border')}
            onChange={e => handleChange('postcode', e.target.value)}
            placeholder={t('enter_type', { type: 'postcode' })}
            value={address?.postcode || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
