import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PageHeader from '../../components/page-header';
import ProductDetails from '../../components/product-details';
import ProductsList from '../../components/products-list';
import { multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Products = () => {
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);
  const { productDetails } = useSelector(state => state.products);

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([{ id: nanoid() }]);

  const [isEditProduct, setIsEditProduct] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddProduct = () => {
    setModal({
      type: 'add-product',
      content: {
        isNew: true,
        onSuccess: data => {
          navigate(`product-details/${data.id}`);
          setIsEditProduct(true);
        },
      },
    });
  };

  return (
    <ProductsWrapper className="flex-column w-full">
      <Routes>
        <Route
          path="product-details/:productId"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Products', path: '/products' },
                  ...(productDetails?.name ? [{ text: productDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton
                onBackClick={() => {
                  navigate('/products');
                  setIsEditProduct(false);
                }}
              />
              <ProductDetails isEditProduct={isEditProduct} />
            </Fragment>
          }
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                title="Products"
                showAddNewBtn={true}
                onAddClick={onAddProduct}
                showSearch={true}
                addBtnPermission="PRODUCTS_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
                showMultipleFilter
                setMultiFilter={data => setFilters(data)}
                multiFilter={filters}
                multiFilterFor={multipleFilterTypeEnum.products}
              />
              <ProductsList debouncedSearch={debouncedSearch} filters={filters} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </ProductsWrapper>
  );
};

const ProductsWrapper = styled.div``;

export default Products;
