import React, { useState } from 'react';
import HttpsRedirect from 'react-https-redirect';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import { RefreshPopup } from './popup/refresh-popup';
import { Router } from './routes/router';
import setUpInterceptor from './store/services/interceptor';
import { CommonStyleWrapper } from './styles/Common.styled';
import GlobalStyles from './styles/global-styles';
import { dark, light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
  dark,
};

const App = () => {
  const store = useStore();
  setUpInterceptor(store);
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(getLanguage());
  const [theme, setTheme] = useState(initialThemeState.theme);

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider value={{ theme, setTheme }}>
        <ThemeProvider theme={themesMap[theme]}>
          <GlobalStyles />
          <CommonStyleWrapper />
          <div className={`App theme-${theme}`}>
            <HttpsRedirect>
              <Router />
              <RefreshPopup />
            </HttpsRedirect>
          </div>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
