import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { uppercase } from '../../helpers/utils';
import { getAccountsList, setAccountsList } from '../../store/features/accountsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import AccountsListItem from './accounts-list-item';

const accountsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'ADDRESS', key: 'ADDRESS' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'EMAIL', key: 'EMAIL' },
  { name: 'PRIMARY CONTACT', key: 'PRIMARY_CONTACT' },
  { name: '', key: 'MENU' },
];

const AccountsList = ({ debouncedSearch = '', addedData = null }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { userOrganization } = useSelector(state => state.user);
  const { accountsList } = useSelector(state => state.accounts);
  const { content: accounts, last } = accountsList || {};

  const [loading, setLoading] = useState(false);

  const fetchAccountsList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getAccountsList({
        organizationId: userOrganization.id,
        merge,
        params: { page: page, size: 20, category_tag: 'ACCOUNT', search: debouncedSearch },
      }),
    ).finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchAccountsList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchAccountsList(0, false, debouncedSearch, true);
  }, [userOrganization, debouncedSearch, addedData]);

  useEffect(() => {
    return () => {
      dispatch(setAccountsList([]));
    };
  }, []);

  const renderTableHeader = headers => {
    return (
      <div className="border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text px-6 font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <AccountsListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (accounts || []).length > 0 ? (
        <Fragment>
          {renderTableHeader(accountsTableHeaders)}
          <InfiniteScrollComponent
            dataLength={accounts.length}
            hasMore={!last}
            height={48}
            fetchMoreData={fetchMoreData}>
            {(accounts || []).map(account => (
              <AccountsListItem account={account} key={account?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </AccountsListWrapper>
  );
};

export const AccountsListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(150px, 1fr) minmax(225px, 1.5fr) minmax(150px, 170px) minmax(250px, 280px) minmax(140px, 170px)
      60px;
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default AccountsList;
