import { configureStore } from '@reduxjs/toolkit';
import accountsSlice from './features/accountsSlice';
import addressSlice from './features/addressSlice';
import automationsSlice from './features/automationsSlice';
import categorySlice from './features/categorySlice';
import deviceSlice from './features/deviceSlice';
import integrationsSlice from './features/integrationsSlice';
import productsSlice from './features/productsSlice';
import reloadSlice from './features/reloadSlice';
import reportsSlice from './features/reportsSlice';
import storageGroupsSlice from './features/storageGroupsSlice';
import storageSlice from './features/storageSlice';
import toastSlice from './features/toastSlice';
import userManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    user: userSlice,
    usersManagement: userManagementSlice,
    automations: automationsSlice,
    integrations: integrationsSlice,
    accounts: accountsSlice,
    products: productsSlice,
    category: categorySlice,
    storage: storageSlice,
    storageGroup: storageGroupsSlice,
    reports: reportsSlice,
    device: deviceSlice,
    address: addressSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
