import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState: {
    integrationsList: [],
    integrationDetails: {},
    integrationConfigsDetail: [],
  },
  reducers: {
    setIntegrationsList: (state, { payload }) => {
      state.integrationsList = payload;
    },
    setIntegrationDetails: (state, { payload }) => {
      state.integrationDetails = { ...payload };
    },
    setIntegrationConfigsDetail: (state, { payload }) => {
      state.integrationConfigsDetail = payload;
    },
    addNewIntegrationInList: (state, { payload }) => {
      state.integrationsList = [...state.integrationsList, payload];
    },
    updateIntegrationInList: (state, { payload }) => {
      state.integrationsList = [
        ...state.integrationsList.map(integration =>
          integration.id === payload.id ? { ...payload } : { ...integration },
        ),
      ];
    },
    setIntegrationAutomations: (state, { payload }) => {
      state.integrationAutomations = payload;
    },
    removeIntegrationFromList: (state, { payload }) => {
      state.integrationsList = [...state.integrationsList.filter(integration => integration.id !== payload.id)];
    },
    updatedIntegrationStateInList: (state, { payload }) => {
      state.integrationsList = [
        ...state.integrationsList.map(integration =>
          integration.id === payload.id ? { ...integration, is_enabled: !integration.is_enabled } : { ...integration },
        ),
      ];
    },
  },
});

export const getIntegrationsList =
  ({ params, isFetchOnly = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations`, { params: params });
      !isFetchOnly && dispatch(setIntegrationsList(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationDetail =
  ({ integration_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}`);
      dispatch(setIntegrationDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getIntegrationConfig =
  ({ connector_id }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/connector/${connector_id}/config`);
      dispatch(setIntegrationConfigsDetail(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getConnectors = () => async dispatch => {
  try {
    const { data } = await api.get(`/connectors`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createIntegration =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/integrations`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateIntegration = payload => async dispatch => {
  try {
    const { data } = await api.put(`/integrations/${payload.id}`, payload.request);
    dispatch(updateIntegrationInList(data));
    dispatch(setIntegrationDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getIntegrationAutomations =
  ({ integration_id, isFetchOnly = false }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/integrations/${integration_id}/automations`);
      !isFetchOnly && dispatch(setIntegrationAutomations(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteIntegration = payload => async dispatch => {
  try {
    const { data } = await api.delete(`/integrations/${payload.id}`);
    dispatch(removeIntegrationFromList(payload));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateIntegrationState = payload => async dispatch => {
  try {
    const { id, request, updateList } = payload;
    const { data } = await api.put(`/integrations/${id}/state`, request);
    if (updateList) {
      dispatch(updatedIntegrationStateInList(payload));
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {
  setIntegrationsList,
  setIntegrationDetails,
  setIntegrationConfigsDetail,
  addNewIntegrationInList,
  updateIntegrationInList,
  setIntegrationAutomations,
  removeIntegrationFromList,
  updatedIntegrationStateInList,
} = integrationsSlice.actions;
export default integrationsSlice.reducer;
