import styled from 'styled-components';

export const SettingsWrapper = styled.div`
  .icon-fill {
    color: ${({ theme }) => theme.colors.black_text};
  }
`;

export const SecurityWrapper = styled.div`
  .content-box {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  .subtitle-text {
    color: ${({ theme }) => theme.colors.Gray2};
  }
`;

export const UsersWrapper = styled.div`
  max-height: 100vh;
  overflow: hidden;
`;
