import React, { useContext } from 'react';
import styled from 'styled-components';
import { ReactComponent as DeviceIcon } from '../../assets/images/device.svg';
import { OrganisationContext } from '../../context/organisationContext';
import Menu from '../common/menu';

const DeviceItem = ({ device = {}, onRemoveDevice }) => {
  const { hasPermission } = useContext(OrganisationContext);

  const { id: deviceId, name, organisation, serial_number, mac_address } = device;

  const deviceItemMenu = [{ name: 'Remove', onClick: () => onRemoveDevice(device), permission: 'USER_DEVICES_MANAGE' }];
  const filteredMenuList = deviceItemMenu.filter(m => !m.permission || hasPermission(m.permission));

  return (
    <DeviceItemWrapper className="border radius-3 pxy-4 col-gap-4" key={deviceId}>
      <DeviceIcon className="flex" />
      <div className="flex-column row-gap-1">
        <label className="bold-text font-16 one-line">{name}</label>
        <label className="regular-text font-12 one-line">{organisation?.name}</label>
        {/* <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Mac address:</span>
          {mac_address}
        </label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Serial number:</span>
          {serial_number}
        </label> */}
      </div>
      {filteredMenuList.length > 0 && <Menu menuList={filteredMenuList} />}
    </DeviceItemWrapper>
  );
};

export const DeviceItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 44px minmax(100px, 1fr) 32px;
`;

export default DeviceItem;
