import classNames from 'classnames';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorFieldJoined, isUserSassOperator } from '../../helpers/utils';
import { createStorageGroup, updateStorageGroup } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const AddEditStorage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal, organizationParentChilds } = useContext(OrganisationContext);
  const { onSuccess, isEditStorageGroup = false, storageGroup } = modal.content;
  const { id: storage_group_id, name: groupName, lifecycle = 'ACTIVE' } = storageGroup || {};

  const [loading, setLoading] = useState(false);

  const onConfirm = async data => {
    setLoading(true);
    const isSassOperator = isUserSassOperator();

    const { name } = data;

    const isAdd = isEditStorageGroup
      ? {}
      : {
          organizations: organizationParentChilds.map(org => ({ id: org.id })),
        };

    const addStorageGroupRequest = {
      name: name,
      is_saas_default: isSassOperator,
      lifecycle: lifecycle,
      ...isAdd,
    };

    if (isEditStorageGroup) {
      await dispatch(updateStorageGroup({ storage_rule_group_id: storage_group_id, request: addStorageGroupRequest }))
        .then(editedGroup => {
          dispatch(addToast({ success: true, text: t('STORAGE_GROUP_UPDATE'), id: nanoid() }));
          onSuccess(editedGroup);
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_GROUP_UPDATE');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    } else {
      await dispatch(createStorageGroup({ request: addStorageGroupRequest }))
        .then(addedStorageGroup => {
          dispatch(addToast({ success: true, text: t('STORAGE_GROUP_ADD'), id: nanoid() }));
          onSuccess(addedStorageGroup);
          setModal(initModal);
        })
        .catch(error => {
          const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_GROUP_ADD');
          dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
        })
        .finally(() => setLoading(false));
    }
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: isEditStorageGroup ? { name: groupName } : { name: '' },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('name'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onConfirm,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorFields = getErrorFieldJoined(errors, value => {
        return value;
      });
      const errorText = `Please add ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <CommonPopup
      popupTitle={t('STORAGE_RULE_GROUP')}
      confirmButtonProps={{ label: t('SAVE') }}
      cancelButtonProps={{ label: t('NO') }}
      onCancel={() => setModal(initModal)}
      onConfirm={handleSubmit}
      disabled={loading}>
      <AddEditStorageWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-1 font-12">{t('NAME')}</label>
          <input
            className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder={t('enter_type', { type: 'name' })}
            value={values.name || ''}
          />
        </div>
      </AddEditStorageWrapper>
    </CommonPopup>
  );
};

const AddEditStorageWrapper = styled.div``;

export default AddEditStorage;
