import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { checkRoleTier } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { UsersListItemWrapper } from '../../styles/components/users-list/users-list.styled';
import Button from '../Elements/button/button';
import Menu from '../common/menu';
import { UserStatus } from '../common/status';
import Switch from '../common/switch';

const UserListItem = ({ user, updateUsersList }) => {
  const navigate = useNavigate();

  const userLocal = getItemFromLocalStorage('user');
  const { organizations, user: localUser } = userLocal;

  const { hasPermission, setModal } = useContext(OrganisationContext);

  const [isRole, setIsRole] = useState(false);

  const getFormatEmail = email => {
    return email?.length >= 43 && email?.includes('device') && email?.includes('@labl.it') ? 'Not Supplied' : email;
  };

  const onChangeStatus = async () => {
    const { is_active } = user;
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isChangeStatus: true,
        isActive: is_active,
        title: `Are you sure you want to ${is_active ? 'deactivate' : 'activate'} this user?`,
      },
    });
  };

  const onResendClick = async () => {
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isReinvite: true,
        title: 'Are you sure you want to re-invite this user?',
        onSuccess: updateUsersList,
      },
    });
  };

  const getExtraMenuOption = () => {
    if (!hasPermission('USERS_MANAGE')) {
      return null;
    }
    if (user.status === 'EXPIRED') {
      return (
        <div>
          <div className="flex items-center justify-between pxy-4 border-top">
            <Button
              className={`additional w-full`}
              label={'Re-send invite'}
              borderRadius={'12px'}
              size={'tiny'}
              onClick={onResendClick}
            />
          </div>
        </div>
      );
    } else if (user.status !== 'INVITED') {
      return (
        <div>
          <div className="flex items-center justify-between px-1 pb-4 pt-4 border-top">
            <span className={`regular-text flex items-center last-width enable-color font-16 px-3`}>Active</span>
            <Switch onClick={onChangeStatus} className="pr-3" enabled={user?.is_active} disabled={false} />
          </div>
        </div>
      );
    }
  };

  const onDeleteUser = async () => {
    const { status } = user;
    const title =
      status === 'INVITED'
        ? 'Are you sure you want to cancel this invitation?'
        : 'Are you sure you want to delete this user?';
    setModal({
      type: 'user-action',
      content: {
        user: user,
        top: true,
        isDelete: status !== 'INVITED',
        isCancelInvitation: status === 'INVITED',
        title,
      },
    });
  };

  const onUserDetails = () => {
    navigate(`user_details/${user?.id}`);
  };

  const onResetPin = () => {
    setModal({
      type: 'reset-user-pin',
      content: {
        user: user,
        onSuccess: updateUsersList,
      },
    });
  };

  const onResetPassword = () => {
    setModal({
      type: 'reset-user-password',
      content: {
        user: user,
        onSuccess: updateUsersList,
      },
    });
  };

  useEffect(() => {
    if (organizations) {
      const isTier = checkRoleTier(organizations?.[0]?.role, user?.role);
      if (isTier && user?.role) {
        setIsRole(isTier);
      } else {
        setIsRole(localUser?.id === user?.id);
      }
    }
  }, [organizations]);

  return (
    <UsersListItemWrapper className="data-container cursor border-bottom" onClick={onUserDetails}>
      <div className=" flex-column items-start px-6 overflow-hidden justify-center w-full row-gap-1">
        <label className="medium-text font-16 one-line">{user?.name}</label>
        <label className="regular-text one-line main-grey-text font-12">{user?.role?.name}</label>
      </div>
      <div className="flex items-center justify-start w-full px-6">
        <label className={`regular-text one-line font-16 ${!user.phone ? 'main-grey-text' : ''}`}>
          {user?.phone ? (user?.country_code || '+44') + user?.phone : 'Not provided'}
        </label>
      </div>
      <div className="flex items-center justify-start w-full px-6">
        <label
          className={`regular-text one-line font-16 ${
            getFormatEmail(user?.email) === 'Not Supplied' || !user.email ? 'main-grey-text' : ''
          }`}>
          {getFormatEmail(user?.email)}
        </label>
      </div>
      <div className="flex items-center justify-start px-6">
        <UserStatus status={user?.status} />
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={useFilteredMenuList({
            menuList: [
              { name: 'Details', onClick: onUserDetails, permission: 'USERS_MANAGE' },
              isRole && { name: 'Reset pin', onClick: onResetPin, permission: 'USER_DEVICES_MANAGE' },
              isRole && { name: 'Reset password', onClick: onResetPassword, permission: 'USERS_CREDENTIALS_MANAGE' },
              isRole && {
                name: user.status === 'INVITED' ? 'Cancel invitation' : 'Delete',
                onClick: () => onDeleteUser(),
                permission: 'USERS_MANAGE',
              },
            ].filter(Boolean),
          })}
          ExtraOptions={isRole ? getExtraMenuOption() : null}
        />
      </div>
    </UsersListItemWrapper>
  );
};

export default UserListItem;
