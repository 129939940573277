import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SearchIcon } from '../../../assets/images/search.svg';

const InputSearch = ({ value, onChange, placeholder, className, inputType = 'text' }) => {
  return (
    <InputSearchWrapper className={classNames('flex relative', className)}>
      <SearchIcon className="absolute search-icon" height={24} width={24} />
      <input
        className="radius-3 input"
        value={value}
        onChange={({ target }) => onChange(target.value)}
        placeholder={placeholder}
        type={inputType}
      />
    </InputSearchWrapper>
  );
};

const InputSearchWrapper = styled('div')`
  .search-icon {
    height: 100%;
    margin: 0 12px;
  }

  .input {
    height: 40px;
    padding-left: 44px;
    border-width: 1px;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }
`;

export default InputSearch;
