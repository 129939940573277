import styled from 'styled-components';

export const MultipleFilterStyledWrapper = styled.div`
  width: 300px;
  z-index: 3;
  top: 45px;
  left: -75px;
  filter: drop-shadow(2px 8px 30px rgba(9, 19, 53, 0.1));

  &::before {
    content: '';
    background: white;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%) rotate(45deg);
    position: absolute;
    left: 50%;
    top: 0px;
  }

  &.bottom-right {
    left: unset;
    right: 0;

    &::before {
      left: 90%;
    }
  }
`;
