import { css } from 'styled-components';

const text = css`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black_text};
`;

export const FontStyles = css`
  .light-text {
    ${text}
    font-family: Lato Light;
  }

  .regular-text {
    ${text}
    font-family: Lato Regular;
  }

  .ramabhadra-regular-text {
    ${text}
    font-family: Ramabhadra Regular;
  }

  .medium-text {
    ${text}
    font-family: Lato Medium;
  }

  .bold-text {
    ${text}
    font-family: Lato Bold;
  }

  .semibold-text {
    ${text}
    font-family: Lato Semibold;
  }

  .primary-text {
    color: ${({ theme }) => theme.colors.primary};
  }

  .white-text {
    color: ${({ theme }) => theme.colors.white};
  }

  .main-orange-text {
    color: ${({ theme }) => theme.colors.main_orange};
  }

  .main-grey-text {
    color: ${({ theme }) => theme.colors.main_grey};
  }

  .lighter-text {
    color: ${({ theme }) => theme.colors.lighter_text};
  }
`;
