import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ProductStatus } from '../common/status';
import { lowercase } from '../../helpers/utils';
import Menu from '../common/menu';
import Checkbox from '../common/checkbox';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';

const StorageListItem = ({ storage = {}, storageSelected = false, onStorageSelect }) => {
  const navigate = useNavigate();

  const { id: storageId, name, expiry, lifecycle, connections, usage } = storage;

  const navigateToStorageDetails = () => {
    navigate(`storage-details/${storageId}`);
  };

  const getUseBy = expiry => {
    const { units, period } = expiry;
    if (units !== undefined && period) {
      return `${units} ${lowercase(period)}`;
    }
    return '-';
  };

  return (
    <StorageListItemWrapper
      key={storageId}
      className="data-container py-2 border-bottom cursor"
      onClick={() => navigateToStorageDetails()}>
      {/* <div className="flex items-center overflow-hidden">
        <Checkbox inputClassname="radius-2" checked={storageSelected} onChange={() => onStorageSelect(storageId)} />
      </div> */}
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{getUseBy(expiry)}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16">
          {connections?.devices ? `Devices: ${connections?.devices}` : 'Global'}
        </label>
      </div>
      <div className="flex items-center overflow-hidden">
        <ProductStatus status={lifecycle || 'INACTIVE'} />
      </div>
      <div className="flex items-center overflow-hidden">
        <Menu menuList={useFilteredMenuList({ menuList: [{ name: 'Details', onClick: navigateToStorageDetails }] })} />
      </div>
    </StorageListItemWrapper>
  );
};

const StorageListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }

  .items-select-checkbox {
    .checkbox {
      border-radius: 8px;
    }
  }
`;

export default StorageListItem;
