import React from 'react';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import Activate from '../pages/activate';
import ForgotPassword from '../pages/forgot-password/forgot-password';
import Login from '../pages/login';
import Main from '../pages/main/main';
import MultiFactorAuth from '../pages/multi-factor-auth';
import Toast from '../popup/toast/toast';
import { AuthenticationRoute } from './authenticationRoute';
import history from './history';
import { PrivateRoute } from './privateRoute';

export const Router = () => {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route element={<Login />} path="/login" />
        <Route element={<ForgotPassword />} path="/password" />
        <Route element={<Activate />} path="/activate" />
        <Route element={<AuthenticationRoute />}>
          <Route element={<MultiFactorAuth />} path="/authentication" />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<Main />} path="/*" />
        </Route>
        <Route render={() => <Navigate to="/login" />} />
      </Routes>
      <Toast />
    </HashRouter>
  );
};
