import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import VenueDetails from '../../components/venue-details';
import { getAccountDetails, setAccountDetails, setAccountSearchFields } from '../../store/features/accountsSlice';
import PageHeader from '../page-header';
import AccountDeviceDetails from './account-device-details';
import AccountVenueDetails from './account-venue-details';
import BrandDetails from './brand-details';
import DetailsHeader from './details-header';

const AccountDetailUI = ({
  loading,
  fromMyAccount,
  organizationId,
  organizationName,
  allow_networking,
  setVenueListLength,
  venueListLength,
  tag,
}) => {
  const [brandIds, setBrandIds] = useState([]);
  const [venueIds, setVenueIds] = useState([]);
  const [brandOrVenueLoading, setBrandOrVenueLoading] = useState({
    brandLoading: tag !== 'BRAND' && tag !== 'VENUE',
    venueLoading: tag !== 'VENUE',
  });

  return (
    <AccountDetailUIWrapper className="flex-column flex-1 mt-6 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex-colum flex-1 overflow-scroll">
          <DetailsHeader fromMyAccount={fromMyAccount} />
          {allow_networking && (
            <Fragment>
              {tag !== 'BRAND' && tag !== 'VENUE' && (
                <BrandDetails
                  fromMyAccount={fromMyAccount}
                  organizationId={organizationId}
                  organizationName={organizationName}
                  setBrandIds={setBrandIds}
                  setBrandOrVenueLoading={setBrandOrVenueLoading}
                />
              )}
              {tag !== 'VENUE' && (
                <AccountVenueDetails
                  fromMyAccount={fromMyAccount}
                  organizationId={organizationId}
                  organizationName={organizationName}
                  setVenueListLength={setVenueListLength}
                  setVenueIds={setVenueIds}
                  setBrandOrVenueLoading={setBrandOrVenueLoading}
                />
              )}
            </Fragment>
          )}
          <AccountDeviceDetails
            fromMyAccount={fromMyAccount}
            organizationId={organizationId}
            venueListLength={venueListLength}
            brandIds={brandIds}
            venueIds={venueIds}
            brandOrVenueLoading={allow_networking && brandOrVenueLoading}
          />
        </div>
      )}
    </AccountDetailUIWrapper>
  );
};

const AccountDetails = ({ fromMyAccount = false, organizationId = null, tag = '' }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account_id } = useParams();

  const { accountDetails, venueDetails } = useSelector(state => state.accounts);

  const [venueListLength, setVenueListLength] = useState(0);
  const [loading, setLoading] = useState(true);

  const accountTitle = tag === 'BRAND' ? 'My Brand' : tag === 'VENUE' ? 'My Venue' : 'My Account';

  const fetchAccountDetails = account_id => {
    setLoading(true);
    dispatch(
      getAccountDetails({
        organizationId: account_id,
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const accountId = fromMyAccount ? organizationId : account_id;
    if (accountId) {
      fetchAccountDetails(accountId);
    }

    return () => {
      dispatch(setAccountDetails({}));
    };
  }, [account_id, organizationId]);

  useEffect(() => {
    return () => {
      dispatch(setAccountSearchFields({}));
    };
  }, []);

  return (
    <Routes>
      <Route
        path="/venue-details/:venue_id"
        element={
          <Fragment>
            {!fromMyAccount && (
              <PageHeader
                names={[
                  { text: 'Accounts', path: '/accounts' },
                  ...(accountDetails?.name
                    ? [{ text: accountDetails?.name, path: `/accounts/account-details/${account_id}` }]
                    : []),
                  ...(venueDetails?.name ? [{ text: venueDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton={!fromMyAccount}
                onBackClick={() => navigate(`/accounts/account-details/${account_id}`)}
              />
            )}
            {fromMyAccount && (
              <PageHeader
                names={[
                  { text: accountTitle, path: '/my-account' },
                  ...(venueDetails?.name ? [{ text: venueDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton={true}
                onBackClick={() => navigate(`/my-account`)}
              />
            )}
            <VenueDetails />
          </Fragment>
        }
      />
      <Route
        path="/*"
        element={
          <Fragment>
            {!fromMyAccount && (
              <PageHeader
                names={[
                  { text: 'Accounts', path: '/accounts' },
                  ...(accountDetails?.name ? [{ text: accountDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton={!fromMyAccount}
                onBackClick={() => navigate('/accounts')}
              />
            )}
            {fromMyAccount && <PageHeader title={accountTitle} />}
            <AccountDetailUI
              loading={loading}
              fromMyAccount={fromMyAccount}
              organizationId={organizationId}
              organizationName={accountDetails?.name || ''}
              allow_networking={accountDetails?.allow_networking}
              setVenueListLength={setVenueListLength}
              venueListLength={venueListLength}
              tag={tag}
            />
          </Fragment>
        }
      />
    </Routes>
  );
};

export const AccountDetailUIWrapper = styled.div``;

export default AccountDetails;
