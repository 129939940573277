import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getVenueDetails, setVenueDetails } from '../../store/features/accountsSlice';
import DetailsHeader from './details-header';
import VenueContent from './venue-content';

const VenueDetails = ({ fromMyAccount = false, organizationId = null }) => {
  const dispatch = useDispatch();
  const { venue_id } = useParams();

  const [loading, setLoading] = useState(true);

  const fetchVenueDetails = venue_id => {
    setLoading(true);
    dispatch(
      getVenueDetails({
        organizationId: venue_id,
      }),
    ).finally(() => setLoading(false));
  };

  useEffect(() => {
    const accountId = fromMyAccount ? organizationId : venue_id;
    if (accountId) {
      fetchVenueDetails(accountId);
    }

    return () => {
      dispatch(setVenueDetails({}));
    };
  }, [venue_id, organizationId]);

  return (
    <VenueDetailsWrapper className="flex-column flex-1 mt-6 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex-column flex-1 overflow-scroll">
          <DetailsHeader />
          <VenueContent isDisplayButton={false} />
        </div>
      )}
    </VenueDetailsWrapper>
  );
};

export const VenueDetailsWrapper = styled.div``;

export default VenueDetails;
