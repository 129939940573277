import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as NoDeviceIcon } from '../../assets/images/no-device.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getStorageDevices, setStorageDevices } from '../../store/features/storageSlice';
import Button from '../Elements/button/button';
import DeviceItem from './device-item';

const StorageDetailsRight = () => {
  const dispatch = useDispatch();
  const { storage_id } = useParams();

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { storageDevices } = useSelector(state => state.storage);

  const [loading, setLoading] = useState(true);

  const fetchStorageDevices = storage_id => {
    setLoading(true);
    dispatch(
      getStorageDevices({
        storage_id: storage_id,
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (storage_id) {
      fetchStorageDevices(storage_id);
    }

    return () => {
      dispatch(setStorageDevices([]));
    };
  }, [storage_id]);

  const onRemoveDevice = device => {
    setModal({
      type: 'remove-device',
      content: {
        device: device,
        idValue: storage_id,
        key: 'STORAGE',
        onSuccess: () => fetchStorageDevices(storage_id),
      },
    });
  };

  const onAddDevice = device => {
    setModal({
      type: 'add-device',
      content: {
        idValue: storage_id,
        key: 'STORAGE',
        addedDevices: storageDevices,
        onSuccess: () => fetchStorageDevices(storage_id),
      },
    });
  };

  return (
    <StorageDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom details-header flex items-center px-6">
        <label className="bold-text font-24 flex-1">Devices</label>
        {hasPermission('STORAGE_DEVICE_MANAGE') && (
          <Button
            label="Add new"
            icon={<PlusIcon className="primary-text" />}
            className="additional specified-width mr-4"
            width="110px"
            size="tiny"
            onClick={onAddDevice}
            disabled={loading}
          />
        )}
      </div>
      <div className="details-container pxy-6 flex-column flex-1 overflow-scroll">
        {loading ? (
          <div className="item flex items-center justify-center flex-1">
            <img alt="loading" height="40px" src={Loading} />
          </div>
        ) : storageDevices.length > 0 ? (
          <div className="devices-wrapper">
            {storageDevices.map(device => (
              <DeviceItem device={device} onRemoveDevice={onRemoveDevice} key={device.id} />
            ))}
          </div>
        ) : (
          <div className="flex-column items-center justify-center flex-1">
            <NoDeviceIcon />
            <label className="medium-text font-16 main-grey-text mt-6">
              This Storage location belongs to all devices
            </label>
          </div>
        )}
      </div>
    </StorageDetailsRightWrapper>
  );
};

export const StorageDetailsRightWrapper = styled.div`
  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }

  .devices-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
    gap: 24px;
  }
`;

export default StorageDetailsRight;
