import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  padding: 12px 16px 12px 24px;
  min-height: 64px;

  .filter {
    :hover {
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.primary};
      fill: ${({ theme }) => theme.colors.primary};
    }
  }

  .input-search {
    .input {
      width: 300px;
    }
  }

  .export-btn {
    label {
      font-family: Poppins Regular;
    }
  }

  ${({ theme: { down, breakpoints } }) => `
  ${down(breakpoints.lg)} {
    .input-search {
      .input {
        width: 220px;
      }
    }
  }
`}

  .draw-count-down {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    min-height: 32px;
    max-height: 32px;
  }

  .date-range-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    max-height: 32px;
    min-height: 32px;
    border: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
