import React, { Fragment, useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import AccountDetails from '../../components/accounts-details';
import AccountsList from '../../components/accounts-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Accounts = () => {
  const { setModal } = useContext(OrganisationContext);

  const [addedData, setAddedData] = useState(null);
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddClick = () => {
    setModal({
      type: 'add-account',
      content: {
        category_tag: 'ACCOUNT',
        onSuccess: setAddedData,
      },
    });
  };

  return (
    <AccountsWrapper className="flex-column w-full">
      <Routes>
        <Route path="account-details/:account_id/*" element={<AccountDetails />} />
        <Route
          element={
            <Fragment>
              <PageHeader
                title="Accounts"
                showSearch={true}
                searchText={searchText}
                onSearchChange={setSearchText}
                showAddNewBtn
                // addBtnPermission="ACCOUNTS_MANAGE"
                onAddClick={onAddClick}
              />
              <AccountsList debouncedSearch={debouncedSearch} addedData={addedData} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </AccountsWrapper>
  );
};

const AccountsWrapper = styled.div``;

export default Accounts;
