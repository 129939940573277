import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';

export const productsSlice = createSlice({
  name: 'products',
  initialState: {
    productsList: [],
    productDetails: {},
    productExpiry: [],
  },
  reducers: {
    setProductsList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.productsList = { ...data, content: [...state.productsList.content, ...data.content] };
      } else {
        state.productsList = data;
      }
    },
    setProductDetails: (state, { payload }) => {
      state.productDetails = payload;
    },
    setProductExpiry: (state, { payload }) => {
      state.productExpiry = payload;
    },
  },
});

export const getProductsList = payload => async dispatch => {
  try {
    const { params, merge } = payload;
    const { data } = await apiV3.get(`/api/products`, {
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    });
    dispatch(setProductsList({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCategories = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await apiV3.get(`/api/categories`, {
      params: params,
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductDetails = payload => async dispatch => {
  try {
    const { productId } = payload;
    const { data } = await apiV3.get(`/api/products/${productId}`);
    dispatch(setProductDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductLabels = payload => async () => {
  try {
    const { productId } = payload;
    const { data } = await apiV3.get(`/api/products/${productId}/labels`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProductLabel = payload => async () => {
  try {
    const { productLabelId, request } = payload;
    const { data } = await apiV3.put(`/api/labels/${productLabelId}`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductAllergens = payload => async () => {
  try {
    const { productId } = payload;
    const { data } = await apiV3.get(`/api/products/${productId}/allergens`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProductAllergens = payload => async () => {
  try {
    const { productId, request } = payload;
    const { data } = await apiV3.post(`/api/products/${productId}/allergens`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProductAllergens = payload => async () => {
  try {
    const { productId, allergenId } = payload;
    const { data } = await apiV3.delete(`/api/products/${productId}/allergens/${allergenId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAllergens = payload => async () => {
  try {
    const { data } = await apiV3.get(`/api/allergens`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProduct = payload => async () => {
  try {
    const { request } = payload;
    const { data } = await apiV3.post(`/api/products`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProductStorage = payload => async () => {
  try {
    const { product_id, request } = payload;
    const { data } = await apiV3.post(`/api/products/${product_id}/expiry`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addProductLabels = payload => async () => {
  try {
    const { product_id, request } = payload;
    const { data } = await apiV3.post(`/api/products/${product_id}/labels`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProductExpiry = payload => async dispatch => {
  try {
    const { product_id } = payload;
    const { data } = await apiV3.get(`/api/products/${product_id}/expiry`);
    dispatch(setProductExpiry(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProduct = payload => async dispatch => {
  try {
    const { request, product_id } = payload;
    const { data } = await apiV3.put(`/api/products/${product_id}`, request);
    dispatch(setProductDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProductExpiry = payload => async () => {
  try {
    const { product_id, request } = payload;
    const { data } = await apiV3.put(`/api/products/${product_id}/expiry`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteProductExpiry =
  ({ product_expiry_id, product_id }) =>
  async () => {
    try {
      const { data } = await apiV3.delete(`/api/products/${product_id}/expiry/${product_expiry_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setProductsList, setProductDetails, setProductExpiry } = productsSlice.actions;
export default productsSlice.reducer;
