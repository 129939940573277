import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { addToast } from '../../store/features/toastSlice';
import { resetUserPassword } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';

const ResetUserPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userDetails: currentUser } = useSelector(state => state.user);

  const { modal, setModal } = useContext(OrganisationContext);

  const { user, onSuccess } = modal.content;
  const { id: user_id, name, role } = user;

  const isSameUser = currentUser.id === user_id;

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({ prevPassword: '', newPassword: '', confirmPassword: '' });
  const [passwordError, setPasswordError] = useState({
    prevPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [showPassword, setShowPassword] = useState({
    prevPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const checkPinErrors = () => {
    const { prevPassword, newPassword, confirmPassword } = password;
    if (isSameUser && !prevPassword.length) {
      setPasswordError({ prevPassword: true, newPassword: false, confirmPassword: false });
      dispatch(addToast({ error: true, text: 'Invalid previous password', id: nanoid() }));
      return true;
    }
    if (!newPassword.length || !confirmPassword.length) {
      setPasswordError({
        prevPassword: false,
        newPassword: !newPassword.length,
        confirmPassword: !confirmPassword.length,
      });
      dispatch(addToast({ error: true, text: 'Invalid passwords', id: nanoid() }));
      return true;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError({ prevPassword: false, newPassword: true, confirmPassword: true });
      dispatch(addToast({ error: true, text: 'Password does not match', id: nanoid() }));
      return true;
    }
    setPasswordError({ prevPassword: false, newPassword: false, confirmPassword: false });
    return false;
  };

  const onConfirmAction = async () => {
    const { newPassword, prevPassword } = password;
    if (checkPinErrors()) return;
    setLoading(true);
    dispatch(
      resetUserPassword({
        user_id: user_id,
        request: { password: newPassword, old_password: isSameUser ? prevPassword : null },
      }),
    )
      .then(() => {
        dispatch(addToast({ error: false, text: 'User password updated successfully', id: nanoid() }));
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || 'Error while updating user password';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPasswordError(false);
  }, [password]);

  return (
    <CommonPopup
      popupTitle={'Reset Password'}
      confirmButtonProps={{
        label: 'Reset',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <ResetUserPasswordWrapper className="flex-column w-full radius-4 mt-6">
        {isSameUser && (
          <div className="flex-column pxy-4 w-full radius-4 user-details mb-6">
            <label className="bold-text font-18 mb-2">{name}</label>
            <label className="regular-text main-grey-text font-12">{role?.name || ''}</label>
          </div>
        )}
        {isSameUser && (
          <div className="flex-column mb-6">
            <label className="bold-text font-16 mb-4">Enter the previous Password</label>
            <label className="regular-text main-grey-text font-12 mb-2">Previous password</label>
            <div className="flex-column relative w-full">
              <input
                autoComplete="off"
                className={classNames(
                  'input password-input h-40px radius-3',
                  passwordError.prevPassword && 'error-border',
                )}
                type={showPassword.prevPassword ? 'text' : 'password'}
                placeholder={t('ENTER_PASSWORD')}
                onChange={({ target: { value } }) => setPassword({ ...password, prevPassword: value })}
              />
              <img
                className="show-password"
                src={showPassword.prevPassword ? HidePassword : ShowPassword}
                alt="show password"
                onClick={() => setShowPassword({ ...showPassword, prevPassword: !showPassword.prevPassword })}
              />
            </div>
          </div>
        )}
        <div className="flex-column">
          {isSameUser && <label className="bold-text font-16 mb-4">Create a new Password</label>}
          <div className="flex-column mb-6">
            <label className="regular-text main-grey-text font-12 mb-2">New password</label>
            <div className="flex-column relative w-full">
              <input
                autoComplete="off"
                className={classNames(
                  'input password-input  h-40px radius-3',
                  passwordError.newPassword && 'error-border',
                )}
                type={showPassword.newPassword ? 'text' : 'password'}
                placeholder={t('ENTER_PASSWORD')}
                onChange={({ target: { value } }) => setPassword({ ...password, newPassword: value })}
              />
              <img
                className="show-password"
                src={showPassword.newPassword ? HidePassword : ShowPassword}
                alt="show password"
                onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
              />
            </div>
          </div>
          <div className="flex-column">
            <label className="regular-text main-grey-text font-12 mb-2">Confirm password</label>
            <div className="flex-column relative w-full">
              <input
                autoComplete="off"
                className={classNames(
                  'input password-input  h-40px radius-3',
                  passwordError.confirmPassword && 'error-border',
                )}
                type={showPassword.confirmPassword ? 'text' : 'password'}
                placeholder={t('ENTER_PASSWORD')}
                onChange={({ target: { value } }) => setPassword({ ...password, confirmPassword: value })}
              />
              <img
                className="show-password"
                src={showPassword.confirmPassword ? HidePassword : ShowPassword}
                alt="show password"
                onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
              />
            </div>
          </div>
        </div>
      </ResetUserPasswordWrapper>
    </CommonPopup>
  );
};

const ResetUserPasswordWrapper = styled.form`
  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 8px;
    cursor: pointer;
  }

  .user-details {
    background-color: ${({ theme }) => theme.colors.light_grey};
  }
`;

export default ResetUserPassword;
