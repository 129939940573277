import React, { Fragment } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { ReactComponent as PrepProductIcon } from '../../assets/images/prep-product.svg';
import { ReactComponent as SaleProductIcon } from '../../assets/images/sale-product.svg';
import { ProductStatus } from '../common/status';

const CategoryListItem = ({ category = {} }) => {
  const navigate = useNavigate();

  const { id: categoryId, name, description, lifecycle, connections, usage } = category;

  const navigateToCategoryDetails = () => {
    navigate(`/categories/category-details/${categoryId}`);
  };

  return (
    <CategoryListItemWrapper
      className="data-container py-2 border-bottom cursor"
      onClick={() => navigateToCategoryDetails()}>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      {/* <div className="flex items-center overflow-hidden">
        <label className="regular-text font-16 one-line">{description}</label>
      </div> */}
      <div className="flex items-center overflow-hidden">
        <div className="overflow-hidden flex items-center">
          {usage === 'COMPONENT' ? (
            <Fragment>
              <PrepProductIcon />
              <span className="regular-text ml-2">Prep</span>
            </Fragment>
          ) : (
            <Fragment>
              <SaleProductIcon />
              <span className="regular-text ml-2">Sale</span>
            </Fragment>
          )}
        </div>
      </div>
      <div className="flex items-center overflow-hidden">
        <div className="flex flex-1">
          <span className="regular-text">{connections?.devices ? `Devices: ${connections?.devices}` : 'Global'}</span>
        </div>
        <div className="flex flex-2">
          <span className="regular-text">
            {connections?.products ? `Products: ${connections?.products}` : 'Products: 0'}
          </span>
        </div>
      </div>
      <div className="flex items-center overflow-hidden">
        <ProductStatus status={lifecycle} />
      </div>
    </CategoryListItemWrapper>
  );
};

const CategoryListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }
`;

export default CategoryListItem;
