import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getDevicesDetails, setDevicesDetails } from '../../store/features/deviceSlice';
import { addToast } from '../../store/features/toastSlice';
import DeviceDetailsLeft from './device-details-left';
import DeviceDetailsRight from './device-details-right';

const DeviceDetails = () => {
  const dispatch = useDispatch();
  const { device_id: device_id_param } = useParams();

  const [loading, setLoading] = useState(false);

  const fetchDeviceDetails = async device_id => {
    setLoading(false);
    await dispatch(
      getDevicesDetails({
        device_id: device_id || device_id_param,
      }),
    )
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while fetching device details' }));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (device_id_param) {
      fetchDeviceDetails(device_id_param);
    }

    return () => {
      dispatch(setDevicesDetails({}));
    };
  }, [device_id_param]);

  return (
    <DeviceGroupDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <DeviceDetailsLeft fetchDeviceDetails={fetchDeviceDetails} />
          <DeviceDetailsRight />
        </div>
      )}
    </DeviceGroupDetailsWrapper>
  );
};

export const DeviceGroupDetailsWrapper = styled.div``;

export default DeviceDetails;
