import classNames from 'classnames';
import { useFormik } from 'formik';
import isEquals from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { ReactComponent as PrepProductIcon } from '../../assets/images/prep-product.svg';
import { ReactComponent as SaleProductIcon } from '../../assets/images/sale-product.svg';
import { durationUnitOptions } from '../../constant/optionData';
import { OrganisationContext } from '../../context/organisationContext';
import { formatText, getErrorFieldJoined, lowercase, onlyNumbers } from '../../helpers/utils';
import { getCategoryList } from '../../store/features/categorySlice';
import {
  addProductStorage,
  deleteProductExpiry,
  getProductExpiry,
  setProductExpiry,
  updateProduct,
  updateProductExpiry,
} from '../../store/features/productsSlice';
import { addToast } from '../../store/features/toastSlice';
import Dropdown from '../common/dropdown';
import Menu from '../common/menu';
import SearchableDropdown from '../common/searchable-dropdown';
import ProductStatus from '../common/status/product-status';
import Switch from '../common/switch';
import UpdateActionButtons from '../common/update-action-buttons';

const ProductDetailUI = ({ productDetails, productExpiry }) => {
  const { t } = useTranslation();

  const { name, description, categories = [], lifecycle } = productDetails || {};
  const { name: categoryName, usage } = categories[0] || {};

  const productExpiryData = productExpiry?.find(s => s.expiry_type === 'OVERRIDE') || null;
  const { expiry } = productExpiryData || {};

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      {/* <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('PRODUCT_NOTES')}</label>
        <label className="regular-text font-16 one-line">{description}</label>
      </div> */}
      <div className="flex col-gap-4">
        <div className="flex-column flex-1 row-gap-2">
          <label className="regular-text main-grey-text font-12">Category</label>
          <label className="regular-text font-16 one-line">{categoryName}</label>
        </div>
        <div className="flex-column flex-1 row-gap-2">
          <label className="regular-text main-grey-text font-12">Use</label>
          <label className="regular-text font-16 one-line">
            {usage === 'COMPONENT' ? (
              <Fragment>
                <PrepProductIcon />
                <span className="regular-text font-16 ml-2">Prep</span>
              </Fragment>
            ) : (
              <Fragment>
                <SaleProductIcon />
                <span className="regular-text font-16 ml-2">Sale</span>
              </Fragment>
            )}
          </label>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 flex-column row-gap-2">
          <label className="regular-text main-grey-text font-12">State</label>
          <ProductStatus status={lifecycle} />
        </div>
        {productExpiryData && (
          <div className="flex-1 flex-column row-gap-2">
            <label className="regular-text main-grey-text font-12">Expiry override</label>
            <label className="regular-text font-16 one-line">
              {expiry?.units !== undefined ? expiry?.units : '-'} {formatText(expiry?.period)}
            </label>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const ProductDetailEditUI = ({ productDetails, productExpiry, setEditProduct, onSuccessProductExpiry = () => {} }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { userOrganization } = useSelector(state => state.user);

  const {
    id: productId,
    name: productName,
    description: productDescription,
    lifecycle: productLifecycle,
    categories,
    product_type: productType,
  } = productDetails || {};

  const productCategory = categories?.[0] || null;
  const productExpiryData = productExpiry?.find(s => s.expiry_type === 'OVERRIDE') || null;

  const initialValues = useRef({
    name: productName,
    description: productDescription,
    lifecycle: productLifecycle,
    product_type: productType,
    category: productCategory ? { label: productCategory.name, value: productCategory.id } : null,
  });
  const initialValueExpiry = useRef({
    is_expiry_override: !!productExpiryData,
    expiry: productExpiryData ? productExpiryData.expiry : {},
  });

  const [loading, setLoading] = useState(false);
  const [searchedCategory, setSearchedCategory] = useState(null);

  const getCategoryTypeFromUsage = usage => {
    return usage === 'SINGLE' ? '(For sale)' : usage === 'COMPONENT' ? '(For Prep)' : '';
  };

  const onSave = async data => {
    const { is_expiry_override, expiry, ...restData } = data;
    const { name, description, lifecycle, category, product_type } = restData;

    const expiryData = { is_expiry_override, expiry };

    if (isEquals(initialValues.current, restData) && isEquals(initialValueExpiry.current, expiryData)) {
      setEditProduct(false);
      return;
    }
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      if (!isEquals(initialValues.current, restData)) {
        const updateProductRequest = {
          name: name,
          // description: description,
          product_type,
          categories: [
            {
              id: category.value,
            },
          ],
          lifecycle: lifecycle || 'INACTIVE',
        };
        await dispatch(updateProduct({ request: updateProductRequest, product_id: productId }));
      }

      if (!isEquals(initialValueExpiry.current, expiryData)) {
        const expiryRequest = {
          expiry_type: 'OVERRIDE',
          expiry: expiry ? expiry : null,
          storage: null,
        };
        if (productExpiryData && !is_expiry_override) {
          await dispatch(deleteProductExpiry({ product_expiry_id: productExpiryData.id, product_id: productId }));
        } else if (productExpiryData && is_expiry_override) {
          await dispatch(
            updateProductExpiry({ request: { ...expiryRequest, id: productExpiryData.id }, product_id: productId }),
          );
        } else if (!productExpiryData && is_expiry_override) {
          await dispatch(addProductStorage({ request: expiryRequest, product_id: productId }));
        }
        onSuccessProductExpiry();
      }
      dispatch(addToast({ error: false, text: 'Product updated', id: nanoid() }));
      setLoading(false);
      setEditProduct(false);
    } catch (error) {
      dispatch(addToast({ error: true, text: 'Error while updating product', id: nanoid() }));
    }
  };

  const fetchCategoryList = async (page, search = '') => {
    try {
      const categoryList = await dispatch(
        getCategoryList({
          organizationId: userOrganization.id,
          merge: false,
          forFetchOnly: true,
          params: {
            page: page,
            search: search,
            lifecycle: 'ACTIVE',
          },
        }),
      );
      return categoryList;
    } catch (error) {
      return null;
    }
  };

  const fetchCategoryOptions = async (search, _prevOptions, { page, merge }) => {
    const categoryOptions = await fetchCategoryList(page, search);
    const { content, ...restResponse } = categoryOptions || {};
    const categoryContent = categoryOptions ? content : [];
    const changedOptions = categoryContent.map(option => ({
      label: `${option.name} ${getCategoryTypeFromUsage(option.usage)}`,
      value: option.id,
    }));

    return {
      options: changedOptions,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: { ...initialValues.current, ...initialValueExpiry.current },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('name'),
      category: Yup.object({ value: Yup.string().required() }).required('category'),
      is_expiry_override: Yup.boolean(),
      expiry: Yup.object().when('is_expiry_override', {
        is: is_expiry_override => is_expiry_override,
        then: () =>
          Yup.object()
            .shape(
              {
                units: Yup.number().required('units'),
                period: Yup.string().required('duration'),
              },
              ['units', 'period'],
            )
            .required(),
        otherwise: () => Yup.object(),
      }),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSave,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorObject = values.is_expiry_override ? { ...errors, ...(errors.expiry || {}), expiry: null } : errors;
      const errorFields = getErrorFieldJoined(errorObject, (key, value) =>
        key === 'units' ? lowercase(values.expiry?.period || 'DAYS') : value,
      );
      const errorText = `Please add ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">Name</label>
        <input
          className={classNames('input radius-3 h-40px', errors.name && !values.name && 'error-border')}
          onChange={({ target: { value } }) => {
            setFieldValue('name', value);
          }}
          placeholder={t('enter_type', { type: 'name' })}
          value={values.name || ''}
        />
      </div>
      {/* <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('PRODUCT_NOTES')}</label>
        <textarea
          className={classNames('textarea radius-3')}
          onChange={({ target: { value } }) => setFieldValue('description', value)}
          placeholder={t('enter_type', { type: 'product notes' })}
          rows={4}
          value={values.description || ''}
        />
      </div> */}
      <SearchableDropdown
        customStyle={{ control: { height: '40px', marginTop: '4px', borderRadius: '12px' } }}
        inputValue={searchedCategory}
        onInputChange={setSearchedCategory}
        loadOptions={fetchCategoryOptions}
        className="w-full"
        placeholder={t('select_type', { type: 'category' })}
        name="Category"
        menuPlacement="bottom"
        value={values.category}
        onChange={option => setFieldValue('category', option)}
        isSearchable
        defaultAdditional={{ page: 0 }}
        error={errors.category && !values.category}
      />
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">State</label>
        <div className="flex items-center justify-between">
          <label className="regular-text font-16">Active</label>
          <Switch
            enabled={values.lifecycle === 'ACTIVE'}
            onClick={() => setFieldValue('lifecycle', values.lifecycle === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE')}
          />
        </div>
      </div>
      <div className="flex flex-1 w-full items-center mt-2 justify-between">
        <label className="regular-text font-16">Expiry override</label>
        <Switch
          enabled={values.is_expiry_override}
          onClick={() => setFieldValue('is_expiry_override', !values.is_expiry_override)}
        />
      </div>
      {values.is_expiry_override && (
        <div className="flex col-gap-4">
          <div className="flex-column w-full justify-center">
            <div className="mb-1">
              <label className="regular-text main-grey-text font-12">
                {t('number_of_type', { type: lowercase(values.expiry?.period || 'DAYS') })}
              </label>
            </div>
            <input
              className={classNames(
                'input radius-3 h-40px w-full',
                errors.expiry?.units && !values.expiry?.units && 'error-border',
              )}
              onChange={({ target: { value } }) => {
                ((value && onlyNumbers.test(value)) || !value) &&
                  setFieldValue('expiry', { ...values.expiry, units: value ? value : '' });
              }}
              placeholder="-"
              value={values.expiry?.units !== undefined ? values.expiry?.units : ''}
            />
          </div>
          <Dropdown
            customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
            name={'Duration unit'}
            options={durationUnitOptions}
            value={durationUnitOptions.find(uo => uo.value === values.expiry?.period)}
            onChange={option => setFieldValue('expiry', { ...values.expiry, period: option.value })}
            placeholder={t('select_type', { type: '' })}
            error={errors.expiry?.period && !values.expiry?.period}
          />
        </div>
      )}
      <UpdateActionButtons
        btnSize="small"
        className="mt-8"
        inEditState
        isSaving={loading}
        onCancel={() => setEditProduct(false)}
        onSave={handleSubmit}
      />
    </Fragment>
  );
};

const ProductDetailsLeft = ({ productId }) => {
  const dispatch = useDispatch();

  const { productDetails, productExpiry } = useSelector(state => state.products);
  const { hasPermission } = useContext(OrganisationContext);

  const [editProduct, setEditProduct] = useState(false);

  const onEditProduct = () => {
    setEditProduct(true);
  };

  const productItemMenu = [{ name: 'Edit', onClick: () => onEditProduct(), permission: 'CATEGORY_DEVICE_MANAGE' }];

  const filteredMenuList = productItemMenu.filter(m => !m.permission || hasPermission(m.permission));

  const fetchProductExpiry = productId => {
    dispatch(
      getProductExpiry({
        product_id: productId,
      }),
    ).catch(() => {});
  };

  useEffect(() => {
    if (productId) {
      fetchProductExpiry(productId);
    }

    return () => {
      dispatch(setProductExpiry([]));
    };
  }, [productId]);

  return (
    <ProductDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex items-center py-6 pl-6 pr-4">
          <label className="bold-text font-24 flex-1">Product info</label>
          {filteredMenuList.length > 0 && <Menu menuList={filteredMenuList} />}
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          {editProduct ? (
            <ProductDetailEditUI
              productDetails={productDetails}
              productExpiry={productExpiry || []}
              setEditProduct={setEditProduct}
              onSuccessProductExpiry={() => fetchProductExpiry(productId)}
            />
          ) : (
            <ProductDetailUI productDetails={productDetails} productExpiry={productExpiry || []} />
          )}
        </div>
      </div>
    </ProductDetailsLeftWrapper>
  );
};

export const ProductDetailsLeftWrapper = styled.div`
  width: 350px;

  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default ProductDetailsLeft;
