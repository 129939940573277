import { useEffect, useState } from 'react';

export const usePixelRatio = () => {
  const [size, setSize] = useState(window.devicePixelRatio);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setSize(window.devicePixelRatio);
      }, 500);
    };
    const mqString = `(resolution: ${window.devicePixelRatio}dppx)`;
    const media = matchMedia(mqString);
    media.addEventListener('change', handleResize);
    return () => {
      media.removeEventListener('change', handleResize);
    };
  }, [window.devicePixelRatio]);
  return size;
};
