import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getIntegrationsList, setIntegrationsList } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import Loader from '../common/loader';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import TableHeader from '../common/table-header';
import IntegrationsListItem from './integrations-list-item';

const integrationsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'DESCRIPTION', key: 'DESCRIPTION' },
  { name: 'INTEGRATION', key: 'INTEGRATION' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const IntegrationsList = ({ debouncedSearch = '' }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { userOrganization } = useSelector(state => state.user);
  const { integrationsList } = useSelector(state => state.integrations);

  const [loading, setLoading] = useState(false);

  const fetchIntegrationsList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getIntegrationsList({
        params: { page: page, size: 20, search: debouncedSearch },
      }),
    )
      .catch(error => {
        const errorDescription = error?.response?.data?.error_description || 'Error while fetching integrations list';
        dispatch(addToast({ id: 'INTEGRATIONS_LIST_ERROR', error: true, text: errorDescription }));
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchIntegrationsList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchIntegrationsList(0, false, debouncedSearch, true);
  }, [userOrganization, debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setIntegrationsList([]));
    };
  }, []);

  return (
    <IntegrationsListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <Loader height={40} />
      ) : (integrationsList || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={integrationsTableHeaders} />
          <InfiniteScrollComponent
            dataLength={integrationsList.length}
            hasMore={false}
            height={64}
            fetchMoreData={fetchMoreData}>
            {(integrationsList || []).map(integration => (
              <IntegrationsListItem integration={integration} key={integration?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </IntegrationsListWrapper>
  );
};

const IntegrationsListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(150px, 1fr) minmax(225px, 1.5fr) minmax(200px, 250px) minmax(140px, 170px)
      40px;
    column-gap: 24px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .data-container {
    padding: 4px 16px 4px 24px;
  }
`;

export default IntegrationsList;
