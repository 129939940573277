import styled from 'styled-components';
import BackgroundImage from '../../assets/images/BG.svg';

export const LoginWrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: scroll;

  .login-form {
    width: 456px;

    .form {
      display: flex;
      flex-direction: column;

      .show-password {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 16px;
        top: 12px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        align-self: center;
      }
    }

    .error-message {
      .error-icon {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
`;
