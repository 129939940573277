import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getProductDetails, setProductDetails } from '../../store/features/productsSlice';
import ProductDetailsLeft from './details-left';
import ProductDetailsRight from './details-right';

const ProductDetails = ({ isEditProduct }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();

  const [loading, setLoading] = useState(true);

  const fetchProductDetails = productId => {
    setLoading(true);
    dispatch(
      getProductDetails({
        productId: productId,
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (productId) {
      fetchProductDetails(productId);
    }

    return () => {
      dispatch(setProductDetails({}));
    };
  }, [productId]);

  return (
    <ProductDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <ProductDetailsLeft productId={productId} />
          <ProductDetailsRight isEditProduct={isEditProduct} />
        </div>
      )}
    </ProductDetailsWrapper>
  );
};

export const ProductDetailsWrapper = styled.div``;

export default ProductDetails;
