import Axios from 'axios';
import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextLogo from '../../assets/images/Logo.svg';
import { ReactComponent as BackIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ResetIcon from '../../assets/images/reset.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import Button from '../../components/Elements/button/button';
import { Tooltip } from '../../components/tooltip/tooltip';
import { countMatchesInRegex, emailRegEx, getErrorDescription } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { getPasswordRequirements } from '../../store/features/userSlice';
import { ForgotPasswordWrapper } from '../../styles/pages/forgot-password.styled';
import OtpInput from '../otp-input';

const upperCaseRegex = /[A-Z]/g;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/g;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [active, setActive] = useState();
  const [currentStep, setCurrentStep] = useState('EMAIL');
  const [isOtpInvalid, setIsOtpInvalid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [password, setPassword] = useState();
  const [confimPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [isResendingCode, setIsResendingCode] = useState(false);
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(() => setPasswordRequirements({}));
  };

  useEffect(() => {
    if (authenticationToken) {
      fetchPasswordRequirements(authenticationToken);
    }
  }, [authenticationToken]);

  useEffect(() => {
    setActive(email && email.length > 2);
  }, [email]);

  const onSendOtpToEmail = async () => {
    if (active) {
      if (!emailRegEx.test(email)) {
        setIsEmailValid(false);
        dispatch(addToast({ error: true, text: 'Your email is invalid, please enter valid email', id: nanoid() }));
        return;
      }
      const request = { destination: email, destination_type: 'EMAIL' };
      try {
        setIsResendingCode(true);
        await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, request);
        setCurrentStep('OTP');
        setIsResendingCode(false);
        dispatch(addToast({ text: 'Code sent successfully', id: nanoid() }));
      } catch (error) {
        setIsResendingCode(false);
        dispatch(addToast({ error: true, text: 'Invalid authentication', id: nanoid() }));
      }
    }
  };

  const onValidateOtp = async () => {
    if (otpValue && otpValue.length === 6) {
      const requestDestination = { destination: email, destination_type: 'EMAIL' };
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp/validate`, {
          code: otpValue,
          ...requestDestination,
        });
        setAuthenticationToken(data.access_token);
        setCurrentStep('PASSWORD');
      } catch (error) {
        setIsOtpInvalid(getErrorDescription(error, 'Error while validating OTP'));
      }
    }
  };

  const checkPasswordRequirements = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;
    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  const onSetPassword = async () => {
    if (checkPasswordRequirements()) {
      setPasswordError('Passwords do not match requirement');
      return;
    }
    if (password !== confimPassword) {
      setPasswordError('Passwords do not match');
      return;
    }
    try {
      const tokenDecoded = authenticationToken ? JSON.parse(window.atob(authenticationToken.split('.')[1])) : {};
      const user_id = tokenDecoded?.sub;
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/users/${user_id}/password`,
        {
          password: password,
        },
        { headers: { authorization: `Bearer ${authenticationToken}` } },
      );
      navigate('/login');
      dispatch(addToast({ error: false, text: 'Password updated successfully.' }));
    } catch (error) {
      setPasswordError('Error while updating password');
    }
  };

  useEffect(() => {
    setIsOtpInvalid(false);
  }, [otpValue]);

  const onBack = () => {
    if (currentStep === 'EMAIL') {
      navigate('/login');
    } else if (currentStep === 'OTP') {
      setIsOtpInvalid(false);
      setOtpValue('');
      setCurrentStep('EMAIL');
    } else {
      setCurrentStep('OTP');
      setPassword('');
      setConfirmPassword('');
      setPasswordError('');
      setPasswordRequirementError({
        minumumPasswordLengthError: false,
        upperCaseError: false,
        lowerCaseError: false,
        numberError: false,
        specialCharacterError: false,
      });
    }
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    return (
      <div className="flex">
        <ErrorIcon height={16} width={16} className="error-text" />
        <div className="flex-column ml-2">
          <span className="medium-text font-12">Password must:</span>

          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="regular-text main-grey-text font-12">Have {min_length} characters</span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="regular-text main-grey-text font-12">Have {min_uppercase} upper case</span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="regular-text main-grey-text font-12">Have {min_lowercase} lower case</span>
          )}
          {passwordRequirementError.numberError && (
            <span className="regular-text main-grey-text font-12">Have {min_digits} number</span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="regular-text main-grey-text font-12">Have {min_special} special character</span>
          )}
        </div>
      </div>
    );
  };

  const onEnterKey = () => {
    if (currentStep === 'EMAIL') {
      onSendOtpToEmail();
    } else if (currentStep === 'OTP') {
      onValidateOtp();
    } else if (currentStep === 'PASSWORD') {
      onSetPassword();
    }
  };

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onEnterKey();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [currentStep, email, otpValue, password, confimPassword]);

  return (
    <ForgotPasswordWrapper className="flex items-center w-full relative">
      <div className="flex items-center justify-center cursor back-icon-container absolute" onClick={onBack}>
        <BackIcon className="back-icon" height={16} width={16} />
      </div>
      <div className="flex items-center justify-center overflow-scroll m-auto w-full">
        <div className="flex-column items-center forgot-password-content card px-16 pt-10 pb-14">
          <img className="text-logo" src={TextLogo} alt="text-logo" />
          {currentStep === 'EMAIL' ? (
            <Fragment>
              <label className="ramabhadra-regular-text font-24 mt-10 text-center">Forgotten your password?</label>
              <label className="regular-text main-grey-text font-16 mt-2 text-center">
                Enter your email below and we will send you a password re-set email
              </label>
              <div className="flex-column mt-8 w-full">
                <label className="regular-text font-12 main-grey-text mb-2">{t('EMAIL')}</label>
                <div className="input-container">
                  <input
                    className={classNames('input radius-3 h-40px w-full', !isEmailValid && 'error-border')}
                    placeholder={t('ENTER_EMAIL_ADDRESS')}
                    value={email}
                    type="email"
                    title="Please enter a valid email address"
                    onChange={e => {
                      setEmail(e.target.value);
                      setIsEmailValid(true);
                    }}
                  />
                </div>
              </div>
              <Button
                size="medium"
                className={`primary mt-10 ${!active && 'disabled'}`}
                borderRadius="100px"
                onClick={onSendOtpToEmail}
                label={'Send email'}
              />
            </Fragment>
          ) : currentStep === 'OTP' ? (
            <Fragment>
              <label className="bold-text font-24 mt-10 text-center">{t('WE_SENT_YOU_EMAIL')}</label>
              <label className="regular-text main-grey-text font-16 mt-2 text-center">{t('CHECK_EMAIL_CODE')}</label>
              <OtpInput
                className={'otp-input'}
                containerClass={'otp-content'}
                errorStyle={'error-border'}
                hasErrored={isOtpInvalid}
                inputStyle={'input input-style'}
                isInputNum={true}
                onChange={setOtpValue}
                placeholder="000000"
                value={otpValue}
              />
              {isOtpInvalid && (
                <div className="flex w-full error-background radius-2 pxy-2 mt-4">
                  <ErrorIcon height={16} width={16} className="white-text" />
                  <label className="medium-text white-text ml-2">{isOtpInvalid}</label>
                </div>
              )}
              <Button
                size="medium"
                className={`mt-10 mb-6 primary ${!(otpValue && otpValue.length === 6) && 'disabled'}`}
                borderRadius="100px"
                onClick={onValidateOtp}
                label={t('SUBMIT')}
              />
              <div
                className={classNames('flex items-center cursor re-send', isResendingCode && 'disabled-resend')}
                onClick={onSendOtpToEmail}>
                <img src={ResetIcon} alt="reset" height={16} width={16} />
                <label className="bold-text font-16 ml-2 main-orange-text">{t('RESEND_SECURE_CODE')}</label>
              </div>
            </Fragment>
          ) : currentStep === 'PASSWORD' ? (
            <Fragment>
              <label className="bold-text font-24 mt-8 mb-2"> {t('RESET_YOUR_PASSWORD')}</label>
              {passwordError && (
                <div className="flex items-center error-background radius-2 pxy-1 w-full mt-6">
                  <ErrorIcon height={16} width={16} className="white-text" />
                  <label className="medium-text white-text ml-2">{passwordError}</label>
                </div>
              )}
              <div className="flex-column mt-6 w-full">
                <label className="regular-text font-12 main-grey-text mb-2"> {t('NEW_PASSWORD')}</label>
                <div className="password-requirement-tooltip">
                  <Tooltip
                    className="password-requirement-tooltip-details"
                    shouldOpen={false}
                    isAlwaysOpen={
                      passwordError &&
                      (passwordRequirementError.minumumPasswordLengthError ||
                        passwordRequirementError.upperCaseError ||
                        passwordRequirementError.lowerCaseError ||
                        passwordRequirementError.numberError ||
                        passwordRequirementError.specialCharacterError)
                    }
                    content={passwordRequirementTooltip()}
                    placement="right">
                    <div className="relative">
                      <input
                        placeholder={t('ENTER_NEW_PASSWORD')}
                        value={password}
                        className={`w-full input ${passwordError && 'error-border'}`}
                        onChange={e => {
                          setPasswordError(false);
                          setPassword(e.target.value);
                        }}
                        type={showNewPassword ? 'text' : 'password'}
                      />
                      <img
                        className="show-password"
                        src={showNewPassword ? HidePassword : ShowPassword}
                        alt="show password"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="flex-column mt-6 w-full">
                <label className="regular-text font-12 main-grey-text mb-2"> {t('CONFIRM_NEW_PASSWORD')}</label>
                <div className="relative">
                  <input
                    placeholder={t('CONFIRM_NEW_PASSWORD')}
                    value={confimPassword}
                    className={`w-full input ${passwordError && 'error-border'}`}
                    onChange={e => {
                      setPasswordError(false);
                      setConfirmPassword(e.target.value);
                    }}
                    type={showConfirmPassword ? 'text' : 'password'}
                  />
                  <img
                    className="show-password"
                    src={showConfirmPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
              <Button
                label={t('SET_NEW_PASSWORD')}
                className={`mt-10 mb-6 primary ${!(password && confimPassword) && 'disabled'}`}
                size="medium"
                borderRadius="100px"
                onClick={onSetPassword}
              />
            </Fragment>
          ) : (
            <Fragment />
          )}
        </div>
      </div>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
