import classNames from 'classnames';
import React from 'react';
import { uppercase } from '../../../helpers/utils';
import Checkbox from '../checkbox';

const TableHeader = ({ headers, headerClassName = '', allSelected = false, onSelectorClick = () => {} }) => {
  return (
    <div className={classNames('border-bottom', 'header-container', headerClassName)}>
      {headers.map(header =>
        header.key === 'ITEM_SELECTOR' ? (
          <Checkbox inputClassname="radius-2" checked={allSelected} onChange={() => onSelectorClick()} />
        ) : (
          <label key={header.key} className="flex items-center bold-text font-12">
            {uppercase(header.name)}
          </label>
        ),
      )}
    </div>
  );
};

export default TableHeader;
