import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getStorageDetails, setStorageDetails } from '../../store/features/storageSlice';
import StorageDetailsLeft from './details-left';
import StorageDetailsRight from './details-right';

const StorageDetails = () => {
  const dispatch = useDispatch();
  const { storage_id } = useParams();

  const [loading, setLoading] = useState(false);

  const fetchStorageDetails = storage_id => {
    setLoading(false);
    dispatch(
      getStorageDetails({
        storage_id: storage_id,
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (storage_id) {
      fetchStorageDetails(storage_id);
    }

    return () => {
      dispatch(setStorageDetails({}));
    };
  }, [storage_id]);

  return (
    <StorageDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <StorageDetailsLeft />
          <StorageDetailsRight />
        </div>
      )}
    </StorageDetailsWrapper>
  );
};

export const StorageDetailsWrapper = styled.div``;

export default StorageDetails;
