import moment from 'moment';
import showdown from 'showdown';
import TurndownService from 'turndown';
import FakeBarcode from '../assets/images/fake-barcode.png';

const fontSizes = {
  xx_large: '5mm',
  x_large: '4.5mm',
  large: '3.5mm',
  medium: '3mm',
  small: '2.5mm',
  x_small: '2mm',
};

const marginSizes = {
  medium: '15px',
  small: '7.5px',
};

const docTypeHeader = `
    <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Product Label</title>`;

export const convertMarkupToHtml = data => {
  let value = data;
  value = value.replace(/\)/g, ') ');
  value = value.replace(/\(/g, ' (');
  value = value.replace(/\./g, '. ');
  value = value.replace(/;/g, '; ');
  value = value.replace(/:/g, ': ');
  value = value.replace(/,/g, ', ');
  value = value.replace(/ +(?= )/g, '');

  const converter = new showdown.Converter({
    strikethrough: true,
    underline: true,
  });

  const valueLines = value.split('\n');
  const formattedLines = valueLines.map(line => {
    if (!line) {
      // By default showdown doesn't support empty lines
      // so this is a hacky fix to split the text and add them back in.
      return '<br />';
    }
    return converter.makeHtml(line);
  });
  return formattedLines.join('');
};

export const convertHtmlToMarkup = data => {
  const turndownService = new TurndownService({ emDelimiter: '*', br: ' ' });
  turndownService.addRule('underline', {
    filter: ['u'],
    replacement: content => {
      return '___' + content + '___';
    },
  });
  turndownService.addRule('p', {
    filter: ['p'],
    replacement: content => {
      if (!content) {
        return '\n\n' + content + '\n\n';
      }
      return '\n' + content + '\n';
    },
  });
  const markUp = turndownService.turndown(data);
  return markUp;
};

export const createHtmlPrepLabel = (product, batchCode, includeWeekday, expiry_date) => {
  const extraLabels = [];
  const { initials = '', barcode_number, store_display, created } = product;

  if (product.defrosted) {
    extraLabels.push('DEFROSTED');
  }

  if (product.use_first) {
    extraLabels.push('USE FIRST');
  }

  let allergens = product.allergens_display_override || product.allergens_display || '';
  const productName = product.product_name_override || product.name || '';

  const dateFormatString = includeWeekday ? 'ddd DD/MM/YY' : 'DD/MM/YY';

  const useByFullDate = expiry_date ? moment(expiry_date, 'DD/MM/YY').format(dateFormatString) : '';

  const weekday = includeWeekday ? useByFullDate.slice(0, 3) : '';
  const date = includeWeekday ? useByFullDate.substring(4) : useByFullDate;

  const longProductName = productName.length > 15;

  allergens = allergens.replace('contains_undefined_allergens', 'UNDEFINED - Consult allergen matrix');
  allergens = convertHtmlToMarkup(allergens);

  if (product.custom_allergens) {
    if (allergens.length > 1) {
      allergens += ', ';
    }
    allergens += product.custom_allergens.toUpperCase();
  }

  if (product.may_contain) {
    if (allergens.length > 1) {
      allergens += ' | ';
    }
    allergens += 'M/C: ' + product.may_contain;
  }

  allergens = 'ALLERGENS:' + allergens;

  const tooManyAllergens = allergens.length > 65;

  const allergensHtml = convertMarkupToHtml(allergens);

  let htmlString = '';

  const htmlStyles = `
      <style>
        @page {
          margin: 0px;
        }
        * {
          box-sizing: border-box;
        }
        html {
          height: 100%;
        }
        .body {
          position: relative;
        }
        .body, .left, .right {
          font-family: "Helvetica Neue";
          font-weight: bold;
          font-size: ${fontSizes.medium};
          height: 100%;
        }
        .top-half {
          text-align: center;
          font-weight: bold;
        }
        .item {
          text-transform: uppercase;
          font-size: ${longProductName ? fontSizes.large : fontSizes.xx_large};
        }
        .use-by-container {
          background-color: black;
          color: white;
          display: flex;
          align-items: center;
          justify-content: ${includeWeekday ? 'space-between' : 'center'};
          padding-left: ${includeWeekday ? '4px' : '0px'};
          padding-right: ${includeWeekday ? '4px' : '0px'};
        }
        .use-by {
          font-size: ${
            includeWeekday ? fontSizes.small : product?.use_in_hours_override ? fontSizes.large : fontSizes.x_large
          };
        }
        .use-by-date {
          font-size: ${product?.use_in_hours_override ? fontSizes.large : fontSizes.x_large};
        }
        .middle {
          margin-top: 5px;
          display: flex;
          font-weight: bold;
        }
        .left-middle {
          width: 60%;
          float: left;
          text-transform: uppercase;
        }
        .right-middle {
          width: 40%;
          float: right;
          text-align: right;
        }
        .allergens {
          text-transform: uppercase;
          font-weight: 500;
          ${tooManyAllergens ? `font-size: ${fontSizes.small};` : `font-size: ${fontSizes.large};`}
        }
        .left-bottom {
          position: absolute;
          bottom: ${barcode_number ? '40px' : '20px'};
        }
        .black-highlight {
          color: white;
          background-color: ${extraLabels.length > 0 ? 'black' : 'white'};
          ${
            extraLabels.length > 1
              ? `font-size: ${fontSizes.small};  padding-left: 3px;
                  padding-right: 3px;`
              : ''
          }

        }
        .right-bottom {
          text-align: right;
          position: absolute;
          bottom: 0;
          right: 0;
        }
        .printed {
          font-size: ${fontSizes.x_small};
        }
        .offline {
          position: absolute;
          bottom: 0;
          width: 100%;
          max-width: 80px;
        }
        .barcode-img{
          width: 100%;
          height: 12px;
        }
      </style>
    </head>`;
  htmlString += docTypeHeader;
  htmlString += htmlStyles;

  htmlString += `
    <body class="body">
      <div class="top-half">
        <div class="item">${productName}</div>
        <div class="use-by-container">
          <div class="use-by">${includeWeekday ? 'USE<br>BY:' : 'USE BY:'}</div>
          <div class="use-by-date">&emsp;${weekday}</div>
          <div class="use-by-date">&emsp;${date}</div>
        </div>
      </div>
      <div class="middle">
        <div class="left-middle printed">
          PRINTED: ${created ? moment(created, 'DD/MM/YY HH:mm') : ''}
        </div>
        <div class="right-middle">
          ${product.storage_location_name_override ? product.storage_location_name_override || '' : store_display || ''}
        </div>
      </div>
      <div class="allergens">
        ${allergensHtml}
      </div>
      <div class="left-bottom">
        <span class="black-highlight">
        ${extraLabels.join(' | ')}</span>
      </div>
      <div class="right-bottom">
      ${batchCode ? `${batchCode} | ` : ''}
        ${String(initials)}
      </div>
      <div class="offline">
      ${barcode_number ? `<img src="${FakeBarcode}" alt="Barcode" class="barcode-img" />` : 'PRINTED OFFLINE'}
      </div>
    </body>
  </html>`;
  return htmlString;
};

export const createHtmlSaleLabel = (product, customDate, batchCode) => {
  const productName = product.product_name_override || product.name || '';
  const longProductName = productName.length > 15;

  const ingredients = product.ingredients_override || product?.ingredients || '';
  const ingredientsHtml = convertMarkupToHtml(ingredients);

  let htmlString = '';

  const htmlStyles = `
    <style>
        @page {
          margin: ${marginSizes.small} 0 0 0;
          padding: 0;
        }
        * {
          box-sizing: border-box;
        }
        .body {
          font-size: ${fontSizes.small};
          padding: 0;
          margin: 0;
        }
        .item {
          text-align: center;
          font-weight: bold;
          margin: 0;
          padding: 0;
          text-transform: uppercase;
          font-size: ${longProductName ? fontSizes.large : fontSizes.x_large};
        }
        .ingredients {
          padding: 0;
          margin: 0;
          word-wrap: break-all;
          overflow-wrap: break-all;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;
        }
        .ingredients p {
          margin: 0;
          padding: 0;
          word-wrap: break-all;
          overflow-wrap: break-all;
          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;
        }
        .use-by {
          text-align: center;
          font-size: ${fontSizes.small};
          background-color: black;
          color: white;
          font-weight: bold;
          float: bottom;
        }
      </style>
    </head>`;
  htmlString += docTypeHeader;
  htmlString += htmlStyles;

  if (customDate && batchCode) {
    const dateToUse = customDate === 'USE BY' ? product.use_by_date : product.best_before_date;
    htmlString += `
      <body class="body">
        <div class="item">${productName}</div>
        <div class="use-by">${customDate}: ${dateToUse}&emsp;Batch: ${batchCode}</div>
        <div class="ingredients">${ingredientsHtml}</div>
      </body>
    </html>`;
  } else if (customDate && !batchCode) {
    const dateToUse = customDate === 'USE BY' ? product.use_by_date : product.best_before_date;
    htmlString += `
      <body class="body">
        <div class="item">${productName}</div>
        <div class="use-by">${customDate}: ${dateToUse}</div>
        <div class="ingredients">${ingredientsHtml}</div>
      </body>
    </html>`;
  } else if (batchCode && !customDate) {
    htmlString += `
        <body class="body">
          <div class="item">${productName}</div>
          <div class="use-by">Batch: ${batchCode}</div>
          <div class="ingredients">${ingredientsHtml}</div>
        </body>
      </html>`;
  } else {
    htmlString += `
        <body class="body">
          <div class="item">${productName}</div>
          <div class="ingredients">${ingredientsHtml}</div>
        </body>
      </html>`;
  }
  return htmlString;
};
