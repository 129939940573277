import styled from 'styled-components';

export const MainWrapper = styled.div`
  min-height: 100dvh;
  max-height: 100dvh;
  width: fit-content;
  min-width: 100dvw;
  display: flex;
  flex-direction: row;
  background: ${({ theme }) => theme.colors.backgroundColor2};

  .main {
    display: flex;
    flex: 1;

    .content {
      display: flex;
      flex: 1;
    }
  }
`;
