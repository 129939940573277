import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as PlusRoundIcon } from '../../assets/images/plus-round.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import Button from '../Elements/button/button';
import InputSearch from '../common/input-search';
import Tabs from '../common/tab/tab';
import VenueDevices from './venue-devices';
import VenueStorages from './venue-storages';

const venueContentTabs = [
  { name: 'Devices', value: 'DEVICES', permissions: ['DEVICES_VIEW'], addBtnPermissions: ['DEVICES_MANAGE'] },
];

const VenueContent = (props = {}) => {
  const { isDisplayButton = true } = props;
  const { venueDetails } = useSelector(state => state.accounts);

  const { hasMultiplePermission, setModal } = useContext(OrganisationContext);

  const [selectedTab, setSelectedTab] = useState({});
  const [addedData, setAddedData] = useState({});
  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const tabs = venueContentTabs.filter(({ permissions }) => !permissions || hasMultiplePermission(permissions));

  useEffect(() => {
    const firstTab = tabs.length > 0 ? tabs?.[0] : '';
    setSelectedTab(firstTab);
  }, []);

  const onAddClick = () => {
    switch (selectedTab.value) {
      case 'STORAGE':
        setModal({
          type: 'add-storage',
          content: {
            venueDetails: venueDetails,
            onSuccess: data => setAddedData(data),
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <VenueContentWrapper className="card mx-2 px-6 py-4 flex-column flex-1">
      <div className="flex items-center justify-between">
        <Tabs tabs={tabs} selected={selectedTab.value} onTabChange={tab => setSelectedTab(tab)} />
        <div className="flex items-center">
          <InputSearch
            placeholder={'Search'}
            value={searchText}
            onChange={setSearchText}
            className="ml-6 input-search"
          />
          {isDisplayButton && hasMultiplePermission(selectedTab.addBtnPermissions) && (
            <Button
              fontSize="14px"
              size="small"
              width="130px"
              borderRadius="12px"
              className={`primary specified-width ml-6`}
              icon={<PlusRoundIcon className="white-text" height={16} width={16} />}
              label={'Add new'}
              onClick={onAddClick}
            />
          )}
        </div>
      </div>
      {selectedTab.value === 'DEVICES' && <VenueDevices search={debouncedSearch} />}
      {selectedTab.value === 'STORAGE' && <VenueStorages search={debouncedSearch} addedData={addedData} />}
    </VenueContentWrapper>
  );
};

export const VenueContentWrapper = styled.div`
  .input-search {
    .input {
      background-color: ${({ theme }) => theme.colors.backgroundColor};
      border-color: ${({ theme }) => theme.colors.backgroundColor};

      &:focus {
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export default VenueContent;
