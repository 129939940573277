import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import styled from 'styled-components';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import Checkbox from '../../components/common/checkbox';
import Dropdown from '../../components/common/dropdown';
import PhoneInputUk from '../../components/common/phone-input-uk';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { emailRegEx, generateRandomPassword, getErrorDescription } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { addUsers, getUserRoles, resetUserPassword } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';

const UsersInfo = ({ userSecurity, user, setUser, error, setError, roles = [] }) => {
  const { t } = useTranslation();

  const { name, email, phone, role } = user || {};
  const { name: errorName, email: errorEmail, role: errorRole } = error || {};

  const onUpdateValue = (key, value) => {
    setUser({ ...user, [key]: value });
    setError({ ...error, [key]: false });
  };

  return (
    <div className="flex-column row-gap-6">
      <label className="bold-text font-18">{"User's info"}</label>
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">{t('NAME')}</label>
        <input
          className={classNames('input h-40px radius-3', errorName && 'error-border')}
          onChange={({ target: { value } }) => onUpdateValue('name', value)}
          placeholder={t('TYPE_USER_NAME')}
          value={name}
        />
      </div>
      {!(userSecurity?.inviteType === 'DEVICE_ONLY') && (
        <div className="w-full flex-column">
          <label className="regular-text main-grey-text mb-2 font-12">{t('EMAIL')}</label>
          <input
            className={classNames(
              'input h-40px radius-3',
              errorEmail && !emailRegEx.test(user.email) && 'error-border',
            )}
            onChange={({ target: { value } }) => onUpdateValue('email', value)}
            placeholder={t('TYPE_USER_EMAIL')}
            value={email}
            disabled={userSecurity?.inviteType === 'DEVICE_ONLY'}
          />
        </div>
      )}
      <div className="w-full flex-column">
        <label className="regular-text main-grey-text mb-2 font-12">{t('PHONE_NUMBER')}</label>
        <PhoneInputUk
          phone={phone}
          setPhone={updatedPhone => onUpdateValue('phone', updatedPhone)}
          phoneInputClassname={classNames('h-40px radius-3')}
          className={classNames('h-40px radius-3 items-center user-phone-input')}
        />
      </div>
      <Dropdown
        name="User role"
        options={roles}
        placeholder={t('select_type', { type: 'roles' })}
        variant="primary"
        value={roles.find(r => r.value === role?.value)}
        onChange={option => onUpdateValue('role', option)}
        error={errorRole}
      />
    </div>
  );
};

const SecurityInfo = ({ userSecurity, setUserSecurity, error, setError }) => {
  const { inviteType, password } = userSecurity || {};
  const { password: errorPassword } = error || {};

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex-column row-gap-4">
      <label className="bold-text font-18">{'Backoffice'}</label>
      <label className="regular-text font-12 main-grey-text">Select the security option</label>
      <div className="flex-column row-gap-4">
        <Checkbox
          checked={inviteType === 'INVITE'}
          onChange={() => setUserSecurity({ ...userSecurity, inviteType: 'INVITE', password: '' })}
          className="flex-1"
          is_rounded
          name={'Send invite'}
        />
        <Checkbox
          checked={inviteType === 'PASSWORD'}
          onChange={() => setUserSecurity({ ...userSecurity, inviteType: 'PASSWORD' })}
          className="flex-1"
          is_rounded
          name={'Set password'}
        />
        <Checkbox
          checked={inviteType === 'DEVICE_ONLY'}
          onChange={() => setUserSecurity({ ...userSecurity, inviteType: 'DEVICE_ONLY' })}
          className="flex-1"
          is_rounded
          name={'Device only'}
        />
      </div>
      {inviteType === 'PASSWORD' && (
        <div className="flex-column">
          <label className="regular-text main-grey-text font-12 mb-2">Password</label>
          <div className="flex-column relative w-full">
            <input
              autoComplete="new-password"
              className={classNames('input h-40px radius-3', errorPassword && 'error-border')}
              type={showPassword ? 'text' : 'password'}
              placeholder="Type password"
              onChange={({ target: { value } }) => setUserSecurity({ ...userSecurity, password: value })}
              value={password}
            />
            <img
              className="show-password"
              src={showPassword ? HidePassword : ShowPassword}
              alt="show password"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const AddUser = () => {
  const dispatch = useDispatch();

  const { userOrganization } = useSelector(state => state.user);

  const { modal, setModal } = useContext(OrganisationContext);

  const { onSuccess } = modal.content;

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [userSecurity, setUserSecurity] = useState({
    inviteType: 'INVITE',
    password: '',
    pin: Math.floor(1000 + Math.random() * 9000).toString(),
  });
  const [userRoles, setUserRoles] = useState([]);
  const [error, setError] = useState({});

  const checkErrors = () => {
    const { name, email, role } = user;
    const { inviteType, password } = userSecurity;

    const errors = {
      name: !name || (name && name.trim().split(' ').length < 2),
      email: !emailRegEx.test(email),
      password: inviteType === 'PASSWORD' && !password,
      role: !role,
    };
    setError(errors);
    return Object.values(errors).some(err => err === true);
  };

  const onConfirmAction = async () => {
    if (checkErrors()) {
      dispatch(addToast({ error: true, text: 'Please fill required fields', id: nanoid() }));
      return;
    }

    setError({});
    const { name, email, phone, role } = user;
    const { inviteType, password, pin } = userSecurity;

    const splittedName = name.split(' ');
    const organization_id = userOrganization.id;
    const request = {
      forename: splittedName[0].trim(),
      surname: splittedName[1].trim(),
      role: { id: role.id },
      device_role: null,
      email: email,
      title: '',
      timezone: '',
      language: '',
      employee_number: null,
      badge_number: null,
      country_code: phone ? '+44' : null,
      phone: phone ? `${phone.replaceAll(' ', '')}` : null,
      is_owner: false,
    };

    setLoading(true);
    try {
      const { data: addedUser } = await dispatch(
        addUsers({
          organization_id: organization_id,
          request: { ...request },
          callInviterUserApi: inviteType === 'INVITE',
        }),
      );
      // await dispatch(resetUserPin({ user_id: addedUser.id, request: { pin: pin, old_pin: null } }));
      if (inviteType === 'PASSWORD' || inviteType === 'DEVICE_ONLY') {
        const newPassword = inviteType === 'DEVICE_ONLY' ? generateRandomPassword(12) : password;
        await dispatch(
          resetUserPassword({ user_id: addedUser.id, request: { password: newPassword, old_password: null } }),
        );
      }
      setModal(initModal);
      setLoading(false);
      dispatch(addToast({ text: 'User added successfully', id: nanoid() }));
      onSuccess && onSuccess();
    } catch (error) {
      const errorText = error.response?.data?.error_description || 'Something went wrong while adding user';
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: errorText,
          id: nanoid(),
        }),
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(getUserRoles())
      .then(data => {
        const roleData = data.map(role => ({ ...role, label: role.name, value: role.id }));
        setUserRoles(roleData);
      })
      .catch(error => {
        const errorText = getErrorDescription(error, 'Something went wrong while fetching user roles');
        setUserRoles([]);
        dispatch(
          addToast({
            error: true,
            text: errorText,
            id: nanoid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (userSecurity?.inviteType === 'DEVICE_ONLY') {
      setUser({ ...user, email: `device-${uuid()}@labl.it` });
    } else if (userSecurity?.inviteType === 'INVITE' || userSecurity?.inviteType === 'PASSWORD') {
      const resetEmail = user?.email?.length > 43;
      setUser({ ...user, email: resetEmail ? '' : user?.email });
    }
  }, [userSecurity?.inviteType]);

  return (
    <AddUserCommonWrapper>
      <CommonPopup
        modalClassName="common-popup"
        popupTitle={'Add new user'}
        confirmButtonProps={{
          label: 'Add',
        }}
        disabled={loading}
        onCancel={() => setModal(initModal)}
        onConfirm={onConfirmAction}>
        <AddUserWrapper className="flex flex-1 col-gap-10 w-full radius-4 mt-6">
          <div className="flex-column flex-1 row-gap-8">
            <UsersInfo
              user={user}
              setUser={setUser}
              userSecurity={userSecurity}
              error={error}
              setError={setError}
              roles={userRoles}
            />
          </div>
          <div className="flex-column flex-1 row-gap-8">
            <SecurityInfo
              userSecurity={userSecurity}
              setUserSecurity={setUserSecurity}
              error={error}
              setError={setError}
            />
          </div>
        </AddUserWrapper>
      </CommonPopup>
    </AddUserCommonWrapper>
  );
};

const AddUserCommonWrapper = styled.div`
  .common-popup {
    width: 870px;
    min-height: 720px;
  }
`;

const AddUserWrapper = styled.form`
  .otp-input {
    padding: 4px;
    width: 40px !important;
    height: 40px;
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 7px;
    cursor: pointer;
  }

  .venue-name {
    background: ${({ theme }) => theme.colors.users_teams_background};
  }
`;

export default AddUser;
