import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import IconContainer from '../common/icon-container';

const AllergensSelector = ({ allergens = [], onAdd = () => {}, selectedAllergens = [] }) => {
  const selectedIds = selectedAllergens.map(s => s.id);

  return (
    <AllergensSelectorWrapper className="flex-column">
      <div className="py-6 px-4 flex-column border-bottom">
        <div className="flex items-center justify-between mb-4">
          <label className="bold-text font-16">Selected ({selectedAllergens.length})</label>
        </div>
        {selectedAllergens.length > 0 ? (
          <div className="flex wrap">
            {selectedAllergens.map(sl => (
              <div key={sl.id} className="pxy-1 radius-1 mr-2 mb-2 flex items-center allergens-item">
                <label className="regular-text">{sl.name}</label>
                <IconContainer
                  iconContainerClassname="ml-2 cursor radius-1"
                  Icon={CloseIcon}
                  backgroundColor="white"
                  iconColor="black_text"
                  onClick={() => onAdd([...selectedAllergens].filter(s => s.id !== sl.id))}
                />
              </div>
            ))}
          </div>
        ) : (
          <label className="regular-text main-grey-text">Click on item below to select it</label>
        )}
      </div>
      <div className="py-6 px-4 flex-column row-gap-4">
        <label className="bold-text font-16">Allergens</label>
        {allergens.filter(a => !selectedIds.includes(a.id)).length > 0 ? (
          <div className="flex wrap">
            {allergens
              .filter(a => !selectedIds.includes(a.id))
              .map(al => (
                <div
                  key={al.id}
                  className="pxy-1 radius-1 mr-2 mb-2 cursor allergens-item"
                  onClick={() => onAdd([...selectedAllergens, { ...al }])}>
                  <label className="regular-text">{al.name}</label>
                </div>
              ))}
          </div>
        ) : (
          <label className="regular-text main-grey-text">There are no allergens to select</label>
        )}
      </div>
    </AllergensSelectorWrapper>
  );
};

export const AllergensSelectorWrapper = styled.div`
  width: 315px;
  .allergens-item {
    background-color: ${({ theme }) => theme.colors.grey};
  }

  .icon {
    height: 12px;
    width: 12px;
  }
`;

export default AllergensSelector;
