import classNames from 'classnames';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInputLandline = ({ phone, setPhone, className = '', phoneInputClassname = '' }) => {
  return (
    <PhoneInputLandlineWrapper className={classNames('flex relative', className)}>
      <MaskedInput
        mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
        value={phone || null}
        className={classNames('input w-full phone-input', phoneInputClassname)}
        placeholder={'000 000 000 00'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputLandlineWrapper>
  );
};

const PhoneInputLandlineWrapper = styled('div')`
  border: 1px solid transparent;

  .country-code {
    position: absolute;
    height: 100%;
    left: 1px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.colors.backgroundColor2};
    border-radius: 12px 0 0 12px;
  }
`;

export default PhoneInputLandline;
