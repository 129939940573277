import React, { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as MasqueradeIcon } from '../../assets/icons/masquerade.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getContactValueByType } from '../../helpers/utils';
import { onAccessAccount } from '../../store/features/accountsSlice';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import Button from '../Elements/button/button';
import Menu from '../common/menu';

const AccountsListItem = ({ account = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { user } = useSelector(state => state.user);
  const { hasPermission } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);

  const { name, primary_location, contact_details, owners } = account;
  const contactEmail = getContactValueByType(contact_details, 'EMAIL');
  const contactPhone = getContactValueByType(contact_details, 'MOBILE');

  const onAccountClick = () => {
    navigate(`account-details/${account.id}`);
  };

  const onAccessAccountClick = accountDetails => {
    const account_id = accountDetails.id;
    setLoading(true);
    dispatch(onAccessAccount({ organization_id: account_id }))
      .then(data => {
        const api_tokens = user.api_tokens;
        const organizations = user.organizations;
        const updatedUser = {
          ...user,
          original_api_tokens: { ...api_tokens },
          original_organizations: organizations,
          api_tokens: { ...data.api_tokens },
          accountDetails: accountDetails,
          isAccessAccount: true,
          organizations: data.organizations,
          selectedAccount: { ...accountDetails },
        };
        setItemInLocalStorage('user', updatedUser);
        dispatch(setUser(updatedUser));
        setLoading(false);
        navigate('/home');
        dispatch(addToast({ error: false, text: 'Account has been accessed' }));
      })
      .catch(e => {
        setLoading(false);
        dispatch(addToast({ error: true, text: 'Account cannot be accessed' }));
      });
  };

  return (
    <AccountsListItemWrapper className="data-container py-2 border-bottom cursor" onClick={() => onAccountClick()}>
      <div className="flex items-center px-6 overflow-hidden">
        <label className="medium-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center px-6 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden two-lines">{primary_location?.formatted_address}</label>
      </div>
      <div className="flex items-center px-6 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactPhone?.contact_value}</label>
      </div>
      <div className="flex items-center px-6 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactEmail?.contact_value}</label>
      </div>
      <div className="flex items-center px-6 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{owners?.[0]?.name || ''}</label>
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuList={[{ name: t('DETAILS'), onClick: () => onAccountClick() }]}
          ExtraOptions={
            <Fragment>
              {hasPermission('MASQUERADE') && (
                <div className="pxy-4 border-top">
                  <Button
                    size="tiny"
                    width="150px"
                    icon={<MasqueradeIcon className="primary-text" />}
                    className="additional specified-width"
                    label="Access account"
                    disabled={loading}
                    onClick={() => onAccessAccountClick(account)}
                  />
                </div>
              )}
            </Fragment>
          }
        />
      </div>
    </AccountsListItemWrapper>
  );
};

const AccountsListItemWrapper = styled.div`
  min-height: 68px;
  max-height: 68px;

  &:hover {
    background: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default AccountsListItem;
