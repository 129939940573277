import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../assets/images/edit.svg';
import { OrganisationContext } from '../../context/organisationContext';
import {
  setIntegrationDetails,
  updateIntegration,
  updateIntegrationState,
} from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import Switch from '../common/switch';
import UpdateAction from '../common/update-action';

const InputUpdateUI = ({
  inputType = 'INPUT',
  field,
  value,
  label,
  onUpdate = () => {},
  labelClassname = '',
  hasPermission = true,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isEdit, setIsEdit] = useState(false);
  const [editState, setEditState] = useState('');
  const [error, setError] = useState(false);

  const onCancelAction = () => {
    setIsEdit(false);
    setEditState('');
  };

  const onUpdateAction = () => {
    if (!editState) {
      dispatch(addToast({ error: true, text: `Please enter ${field}`, id: nanoid() }));
      setError(true);
      return;
    }
    if (isEqual(editState, value)) {
      onCancelAction();
      return;
    }
    onUpdate(field, editState, value);
    onCancelAction();
  };

  const setInputComponent = inputProps => {
    switch (inputType) {
      case 'INPUT':
        return <input {...inputProps} className={classNames('input w-full', error && !editState && 'error-border')} />;
      case 'TEXTAREA':
        return (
          <textarea {...inputProps} className={classNames('textarea w-full', error && !editState && 'error-border')} />
        );
      default:
        return <input {...inputProps} className={classNames('input w-full', error && !editState && 'error-border')} />;
    }
  };

  return (
    <div className="flex-column flex-1 row-gap-2">
      <label className="regular-text main-grey-text font-12">{label}</label>
      {isEdit ? (
        <div className="relative">
          {setInputComponent({
            value: editState,
            onChange: ({ target: { value } }) => setEditState(value),
            placeholder: t('enter_type', { type: field }),
          })}
          <UpdateAction onCancel={onCancelAction} onUpdate={onUpdateAction} />
        </div>
      ) : (
        <div
          className={classNames('flex items-center py-1 px-2', hasPermission && 'cursor hover-edit-hide hover-edit')}
          onClick={() => {
            if (hasPermission) {
              setIsEdit(true);
              setEditState(value);
            }
          }}>
          <label className={classNames('regular-text font-16 flex-1', labelClassname)}>{value}</label>
          {hasPermission && <EditIcon width={20} height={20} className="primary-text edit-icon" />}
        </div>
      )}
    </div>
  );
};

const IntegrationDetailsLeft = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { integrationDetails } = useSelector(state => state.integrations);
  const { id: integrationId, name, description, is_enabled, connector } = integrationDetails || {};
  const { icon, name: connectorName } = connector || {};

  const [loading, setLoading] = useState(false);

  const changeIntegrationDetailsStatus = is_enabled => {
    dispatch(setIntegrationDetails({ ...integrationDetails, is_enabled: is_enabled }));
  };

  const onChangeStatus = async () => {
    const { is_enabled } = integrationDetails;
    if (is_enabled) {
      setModal({
        type: 'integration-action',
        content: {
          integration: integrationDetails,
          top: true,
          isDisable: true,
          onSuccess: () => changeIntegrationDetailsStatus(!is_enabled),
        },
      });
    } else {
      const request = {
        enabled: !is_enabled,
      };
      changeIntegrationDetailsStatus(!is_enabled);
      dispatch(updateIntegrationState({ id: integrationDetails?.id, request, updateList: true }))
        .then(() => dispatch(addToast({ text: 'Status updated successfully', id: nanoid() })))
        .catch(error => {
          const errorData = error?.response?.data;
          changeIntegrationDetailsStatus(is_enabled);
          dispatch(
            addToast({
              error: true,
              text:
                errorData?.error_code === 'BAD_REQUEST'
                  ? 'Please add all required parameters in integration'
                  : 'Error while updating status',
              id: nanoid(),
            }),
          );
        });
    }
  };

  const onDelete = () => {
    setModal({
      type: 'integration-action',
      content: {
        integration: integrationDetails,
        isDelete: true,
        onSuccess: () => navigate('/platforms/integrations'),
      },
    });
  };

  const onUpdate = (field, value, prevValue) => {
    if (loading) {
      return;
    }
    const request = {
      ...integrationDetails,
      [field]: value,
    };
    setLoading(true);
    dispatch(setIntegrationDetails({ ...request }));
    dispatch(updateIntegration({ id: integrationId, request }))
      .then(() => {
        dispatch(
          addToast({
            error: false,
            text: 'Integration updated successfully',
            id: nanoid(),
          }),
        );
      })
      .catch(() => {
        dispatch(setIntegrationDetails({ ...integrationDetails, [field]: prevValue }));
        dispatch(
          addToast({
            error: true,
            text: 'Error while updating integration',
            id: nanoid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const integrationItemMenu = [{ name: 'Delete', onClick: onDelete, permission: 'INTEGRATIONS_MANAGE' }];

  const filteredMenuList = integrationItemMenu.filter(m => !m.permission || hasPermission(m.permission));

  return (
    <IntegrationDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex items-center py-6 pl-6 pr-4">
          <label className="bold-text font-24 flex-1">{connectorName}</label>
          {filteredMenuList.length > 0 && <Menu menuList={filteredMenuList} />}
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          <div className="pxy-2 border radius-4 connector-container">
            <img alt="log" src={icon?.active} className="w-full h-full object-fit-contain" />
          </div>
          <InputUpdateUI
            inputType="INPUT"
            label={t('NAME')}
            field={'name'}
            value={name}
            onUpdate={onUpdate}
            labelClassname="one-line"
            hasPermission={hasPermission('INTEGRATIONS_MANAGE')}
          />
          <InputUpdateUI
            inputType="TEXTAREA"
            label={t('DESCRIPTION')}
            field={'description'}
            value={description}
            onUpdate={onUpdate}
            hasPermission={hasPermission('INTEGRATIONS_MANAGE')}
          />
          <div className="flex items-center justify-between">
            <span className="regular-text font-16">{is_enabled ? t('ENABLED') : t('DISABLED')}</span>
            <Switch
              variant="large"
              onClick={onChangeStatus}
              enabled={is_enabled}
              disabled={!hasPermission('INTEGRATIONS_MANAGE')}
            />
          </div>
        </div>
      </div>
    </IntegrationDetailsLeftWrapper>
  );
};

export const IntegrationDetailsLeftWrapper = styled.div`
  width: 350px;

  .connector-container {
    height: 222px;
    width: 100%;
  }

  .details-header {
    height: 72px;
  }
`;

export default IntegrationDetailsLeft;
