import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DeviceIcon } from '../../assets/images/device.svg';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { removeCategoryDevice } from '../../store/features/categorySlice';
import { removeStorageDevice } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import { removeUserDevice } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';

const removeDeviceApi = {
  CATEGORY: { api: removeCategoryDevice, idKey: 'categoryId' },
  USER: { api: removeUserDevice, idKey: 'user_id' },
  STORAGE: { api: removeStorageDevice, idKey: 'storage_id' },
};

const RemoveDevice = () => {
  const dispatch = useDispatch();
  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const { key, idValue, device, onSuccess = () => {} } = modal.content;

  const { id: deviceId, name, mac_address, serial_number, organisation } = device;

  const onConfirmAction = async () => {
    const { api, idKey } = removeDeviceApi[key] || {};
    if (!idKey && !api) {
      return;
    }
    setLoading(true);
    dispatch(api({ deviceId, [idKey]: idValue }))
      .then(() => {
        dispatch(addToast({ error: false, text: `Device removed`, id: nanoid() }));
        onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        console.log('error', error);
        const errorText = error?.response?.data?.error_description || 'Error while removing device';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CommonPopup
      popupTitle={'Are you sure you want to remove this device?'}
      confirmButtonProps={{
        label: 'Remove',
        className: 'negative',
      }}
      cancelButtonProps={{ label: 'Cancel' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <RemoveDeviceWrapper className="border col-gap-4 pxy-4 w-full radius-4 mt-6">
        <DeviceIcon className="flex" />
        <div className="flex-column row-gap-1">
          <label className="bold-text font-16 one-line">{name}</label>
          <label className="regular-text font-12 one-line">{organisation?.name}</label>
          {/* <label className="regular-text font-12 one-line">
            <span className="main-grey-text mr-1">Mac address:</span>
            {mac_address}
          </label>
          <label className="regular-text font-12 one-line">
            <span className="main-grey-text mr-1">Serial number:</span>
            {serial_number}
          </label> */}
        </div>
      </RemoveDeviceWrapper>
    </CommonPopup>
  );
};

const RemoveDeviceWrapper = styled.div`
  display: grid;
  grid-template-columns: 44px minmax(100px, 1fr) 32px;
`;

export default RemoveDevice;
