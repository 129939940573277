import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getStorageItem, setStorageItem } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../Elements/button/button';
import InputSearch from '../common/input-search';
import TableHeader from '../common/table-header';
import StorageItem from './storage-item';

const storageTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'USE BY', key: 'USE_BY' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const StorageGroupDetailsRight = () => {
  const pageRef = useRef(null);
  const { storage_group_id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { storageItemList = [] } = useSelector(state => state.storageGroup);

  const { setModal, hasPermission, organizationParentChildIds } = useContext(OrganisationContext);

  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [storageRulesList, setStorageRulesList] = useState([]);

  const debouncedSearch = useDebounce(searchText, 500);

  const onStorageRulesListUpdate = updatedStorage => {
    const updatedDeviceList = storageRulesList.map(storagerule =>
      storagerule.id === updatedStorage.id ? updatedStorage : storagerule,
    );
    setStorageRulesList(updatedDeviceList);
  };

  const fetchStorageRules = (page = 0, merge = false, search = debouncedSearch) => {
    pageRef.current = page;
    setLoading(true);
    dispatch(
      getStorageItem({
        merge,
        params: {
          page: page,
          size: 15,
          search: search,
          group_id: storage_group_id,
        },
      }),
    )
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while fetching storage list' }));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchStorageRules(0, false, debouncedSearch);

    return () => {
      dispatch(setStorageItem([]));
    };
  }, [storage_group_id, debouncedSearch, organizationParentChildIds]);

  const onAddSotrageRules = storage => {
    setModal({
      type: 'add-storage',
      content: {
        isCreateStorageRuels: true,
        id: storage_group_id,
        onSuccess: fetchStorageRules,
      },
    });
  };

  useEffect(() => {
    if (storageItemList) {
      setStorageRulesList(storageItemList);
    }
  }, [storageItemList]);

  return (
    <StorageDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom justify-between details-header flex items-center pxy-6">
        <label className="bold-text font-24 flex-1">{t('STORAGE')}</label>
        <div className="flex gap-4 items-center">
          {hasPermission('STORAGE_RULES_MANAGE') && (
            <Button
              label="Add new"
              icon={<PlusIcon className="primary-text" />}
              className="additional specified-width"
              width="110px"
              size="tiny"
              onClick={onAddSotrageRules}
              disabled={loading}
            />
          )}
          <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} className="input-search" />
        </div>
      </div>
      <div className="details-container flex-column flex-1 overflow-scroll radius-4">
        {loading ? (
          <div className="item flex items-center justify-center flex-1">
            <img alt="loading" height="40px" src={Loading} />
          </div>
        ) : (
          <Fragment>
            <TableHeader headers={storageTableHeaders} />
            {storageRulesList.length > 0 ? (
              <div className="storage-wrapper">
                {storageRulesList.map(storage => (
                  <StorageItem
                    storage={storage}
                    key={storage.id}
                    fetchStorageRules={fetchStorageRules}
                    onStorageRulesListUpdate={onStorageRulesListUpdate}
                  />
                ))}
              </div>
            ) : (
              <div className="flex-column items-center justify-center flex-1">
                <label className="medium-text font-16 main-grey-text mt-6">{t('NO_STORAGE_LIST')}</label>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </StorageDetailsRightWrapper>
  );
};

export const StorageDetailsRightWrapper = styled.div`
  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
  .input {
    height: 32px;
    font-size: 14px;
    padding-left: 36px;
    line-height: 32px;
  }
  .search-icon {
    width: 16px;
    height: 16px;
    top: 8px;
  }
  .menu-btn {
    width: 24px;
    height: 24px;
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    min-height: 32px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default StorageGroupDetailsRight;
