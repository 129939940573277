import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Loader from '../../../../components/common/loader';
import SearchableDropdown from '../../../../components/common/searchable-dropdown';
import PageHeader from '../../../../components/page-header';
import UserDetails from '../../../../components/user-details';
import UsersList from '../../../../components/users-list';
import { OrganisationContext } from '../../../../context/organisationContext';
import useDebounce from '../../../../helpers/useDebounceHook';
import { getAccountCategoryTagInfo } from '../../../../store/features/accountsSlice';
import { addToast } from '../../../../store/features/toastSlice';
import { getUsers } from '../../../../store/features/userManagementSlice';
import { UsersWrapper } from '../../../../styles/pages/settings.styled';

const CustomOption = props => {
  const { innerProps, data, isSelected } = props;
  const isNetwork = data.label === 'Network';
  return (
    <div
      className={classNames(
        'flex items-center w-full py-2 radius-1 cursor option-wrapper',
        isNetwork ? 'px-2 no-select pointer-events-none' : 'px-4',
        isSelected && 'selected',
      )}
      {...innerProps}>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>{data.label}</span>
    </div>
  );
};

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { setModal, organizationParentChildData } = useContext(OrganisationContext);
  const { organization, networks } = organizationParentChildData;

  const { users } = useSelector(state => state.usersManagement);
  const { userOrganization } = useSelector(state => state.user);

  const [selectedVenue, setSelectedVenue] = useState(null);
  const [searchedVenue, setSearchedVenue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState({});
  const [selectedPage, setSelectedPage] = useState(0);
  const [search, setSearchText] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getFilterValue = filter => {
    if (!filter.value?.id) {
      return {};
    }
    return { [filter.object.param]: filter.value?.id || '' };
  };

  const fetchVenuList = async (page, search = '') => {
    try {
      const venuList = await dispatch(
        getAccountCategoryTagInfo({
          organizationId: userOrganization.id,
          params: { page: page, search: search },
        }),
      );
      return venuList;
    } catch (error) {
      return null;
    }
  };

  const fetchVenueOptions = async (search, _prevOptions, { page, merge }) => {
    const venueOptions = await fetchVenuList(page, search);
    const { content, ...restResponse } = venueOptions || {};
    const venueContent = venueOptions ? content : [];
    const changedOptions = venueContent.map(option => ({ ...option, label: option.name, value: option.id }));
    const options = page === 0 ? [{ label: 'Network', value: '', id: '' }, ...changedOptions] : changedOptions;

    const groupByAccountTag = options.reduce((acc, item) => {
      const { parent_organization } = item;
      const brandOrganisation = parent_organization?.find(o => o.category?.tag?.tag === 'ACCOUNT');

      if (brandOrganisation) {
        const brandName = brandOrganisation.name;
        if (acc[brandName]) {
          const brand = acc[brandName];
          acc[brandName] = { label: brandName, options: [...brand.options, item] };
        } else {
          acc[brandName] = { label: brandName, options: [item] };
        }
      } else {
        if (acc['OTHER']) {
          const brand = acc['OTHER'];
          acc['OTHER'] = { label: 'OTHER', options: [...brand.options, item] };
        } else {
          acc['OTHER'] = { label: 'OTHER', options: [item] };
        }
      }
      return acc;
    }, {});

    return {
      options: Object.values(groupByAccountTag),
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  const customSearchableDropdownFiled = () => {
    return (
      <SearchableDropdownWrapper>
        <SearchableDropdown
          isClearable
          inputValue={searchedVenue}
          onInputChange={setSearchedVenue}
          loadOptions={fetchVenueOptions}
          customComponents={{ Option: CustomOption }}
          className="w-full"
          placeholder="Filter"
          value={selectedVenue}
          onChange={setSelectedVenue}
          isSearchable
          defaultAdditional={{ page: 0 }}
        />
      </SearchableDropdownWrapper>
    );
  };

  const fetchUsersList = (page = 0) => {
    setLoading(true);
    dispatch(
      getUsers({
        params: {
          search: debouncedSearch,
          page: page,
          size: 13,
          organization_id: selectedVenue?.id || null,
          ...getFilterValue(filter),
        },
      }),
    )
      .catch(error => {
        dispatch(
          addToast({
            error: true,
            text: error?.response?.data?.error_description || 'Something went wrong while fetching users list',
            id: nanoid(),
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedPage(0);
    fetchUsersList(0);
  }, [debouncedSearch, selectedVenue, filter.value?.id]);

  useEffect(() => {
    fetchUsersList(selectedPage);
  }, [selectedPage]);

  const handleAddUser = () => {
    setModal({
      type: 'invite-user',
      content: {
        onSuccess: () => fetchUsersList(selectedPage),
      },
    });
  };

  return (
    <UsersWrapper className="flex-column flex-1">
      <Routes>
        <Route
          path="user_details/:user_id"
          element={
            <Fragment>
              <PageHeader
                title="User account"
                showBackButton
                onBackClick={() => navigate('/settings/security/users')}
              />
              <UserDetails />
            </Fragment>
          }
        />
        <Route
          path="/"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: t('SETTINGS'), path: '/settings' },
                  { text: t('SECURITY'), path: '/settings/security' },
                  { text: t('USERS'), onClick: () => {} },
                ]}
                showAddNewBtn
                // showFilter
                filterObject={[]}
                showSearch
                filter={filter}
                setFilter={setFilter}
                searchText={search}
                onSearchChange={setSearchText}
                customSearchableDropdownFiled={customSearchableDropdownFiled}
                showCustomSearchableDropdownFiled={organization?.allow_networking && networks?.length > 0}
                showPagination
                pagination={{
                  selectedPage: selectedPage,
                  totalPages: users?.total_pages || 0,
                  setSelectedPage: setSelectedPage,
                }}
                addBtnPermission="USERS_MANAGE"
                onAddClick={() => handleAddUser()}
              />
              {loading ? (
                <Loader height={64} width={64} />
              ) : (
                <UsersList
                  filter={filter}
                  debouncedSearch={debouncedSearch}
                  updateUsersList={() => fetchUsersList(selectedPage)}
                />
              )}
            </Fragment>
          }
        />
      </Routes>
    </UsersWrapper>
  );
};

export default Users;

const SearchableDropdownWrapper = styled.div`
  display: flex;
  width: 224px;
  height: 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media screen and (max-width: 1400px) {
    width: 200px;
    margin-left: 15px;
  }

  .css-198usqn-MenuList {
    max-height: 350px;
  }

  .searchable-select__menu {
    padding: 0;
  }

  .all-venue-teg {
    border-bottom: 1px solid rgb(222, 229, 243);
    margin-bottom: 15px;
    padding: 12px 12px;
    .option-text {
      border-radius: 0;
      margin-left: 0px;
    }
  }

  .all-venue-list-wrapper-brand {
    padding: 0px 20px;
    &:first-child {
      padding: 0px 0px;
    }

    .brand-name-text {
      padding: 8px 8px 8px 0;
      font-size: inherit;
    }
    .option-wrapper-list {
      margin: 0px 0 0px 10px;
      &:hover {
        background-color: ${({ theme }) => theme.colors.menu_item_hover};
      }
    }
  }

  .all-venue-list-wrapper {
    padding: 0px 12px;
    &:first-child {
      padding: 0px 0px;
    }
  }
`;
