import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { convertHtmlToMarkup, convertMarkupToHtml } from '../../helpers/preview-converter';
import { capitalize, formatText } from '../../helpers/utils';
import { addProductAllergens, deleteProductAllergens, updateProductLabel } from '../../store/features/productsSlice';
import { addToast } from '../../store/features/toastSlice';
import UpdateActionButtons from '../common/update-action-buttons';
import ProductAllergens from './product-allergens';
import ProductIngredients from './product-ingredients';

const ProductDetailsRight = ({ isEditProduct = false }) => {
  const dispatch = useDispatch();
  const { productId } = useParams();

  const { productDetails } = useSelector(state => state.products);
  const { usage } = productDetails?.categories?.[0] || {};

  const [value, setValue] = useState(null);
  const [mayContain, setMayContain] = useState(null);
  const [customAllergens, setCustomAllergens] = useState(null);
  const [labelData, setLabelData] = useState({});
  const [savingData, setSavingData] = useState(false);
  const [inEditState, setInEditState] = useState(isEditProduct);
  const [allergensList, setAllergensList] = useState([]);
  const [selectedProductAllergens, setSelectedProductAllergens] = useState([]);
  const [initialSelectedProductAllergens, setInitialSelectedProductAllergens] = useState([]);

  const [refreshProductData, setRefreshProductData] = useState(null);

  const productUsage = usage === 'COMPONENT' ? 'ALLERGENS' : 'INGREDIENTS';

  const updateProductIngredientsData = () => {
    const { id, label_text, ...restLabelData } = labelData;
    const request = {
      ...restLabelData,
      text: '',
      label_text: { ...label_text, ingredients: value ? convertHtmlToMarkup(value) : '' },
    };
    setSavingData(true);
    dispatch(updateProductLabel({ productLabelId: id, request }))
      .then(() => setRefreshProductData(nanoid()))
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while saving ingredients' }));
      })
      .finally(() => {
        setSavingData(false);
        setInEditState(false);
      });
  };

  const updateProductAllergensPromise = (callerFunction, payload) => {
    return dispatch(callerFunction(payload))
      .then(response => {
        Promise.resolve(response?.data);
      })
      .catch(err => {
        Promise.reject(err);
      });
  };

  const updateProductAllergensData = async () => {
    const { id, label_text, ...restLabelData } = labelData;
    const request = {
      ...restLabelData,
      text: '',
      label_text: {
        ...label_text,
        allergens: value ? convertHtmlToMarkup(value) : '',
        may_contain: mayContain || '',
        custom_allergens: customAllergens || '',
      },
    };

    const productAllergens = initialSelectedProductAllergens;
    const selectedProductAllergensIds = selectedProductAllergens.map(spa => spa.id);
    const productAllergensIds = productAllergens.map(spa => spa.id);

    const deletedAllergensPromise = productAllergensIds
      .filter(pa => !selectedProductAllergensIds.includes(pa))
      .map(dpa => ({ callerFunction: deleteProductAllergens, payload: { productId: productId, allergenId: dpa } }));

    const addedAllergensIds = selectedProductAllergensIds
      .filter(spa => !productAllergensIds.includes(spa))
      .map((apa, index) => ({
        allergen: {
          id: apa,
        },
        priority: index,
      }));
    const addProductAllergensPromise =
      addedAllergensIds.length > 0
        ? [{ callerFunction: addProductAllergens, payload: { productId: productId, request: addedAllergensIds } }]
        : [];
    setSavingData(true);
    try {
      await dispatch(updateProductLabel({ productLabelId: id, request }));
      await Promise.all(
        [...deletedAllergensPromise, ...addProductAllergensPromise]
          .filter(Boolean)
          .map(async pr => await updateProductAllergensPromise(pr.callerFunction, pr.payload)),
      ).catch(() => {
        dispatch(addToast({ error: true, text: 'Error while saving allergens' }));
        setSavingData(false);
        setInEditState(false);
      });
      setSavingData(false);
      setInEditState(false);
      setRefreshProductData(nanoid());
    } catch (error) {
      setSavingData(false);
      setInEditState(false);
    }
  };

  const onCancel = () => {
    switch (productUsage) {
      case 'INGREDIENTS':
        setValue(convertMarkupToHtml(labelData.label_text?.ingredients || ''));
        setInEditState(false);
        break;
      case 'ALLERGENS':
        setValue(convertMarkupToHtml(labelData.label_text?.allergens || ''));
        setSelectedProductAllergens(initialSelectedProductAllergens);
        setMayContain(labelData.label_text?.may_contain || '');
        setInEditState(false);
        break;
      default:
        break;
    }
  };

  const onSave = () => {
    switch (productUsage) {
      case 'INGREDIENTS':
        updateProductIngredientsData();
        break;
      case 'ALLERGENS':
        updateProductAllergensData();
        break;
      default:
        break;
    }
  };

  return (
    <ProductDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom details-header flex items-center pxy-6">
        <div className="flex flex-1">
          <label className="bold-text font-24">{capitalize(formatText(productUsage))}</label>
        </div>
        <UpdateActionButtons
          btnWidth="104px"
          onCancel={onCancel}
          onSave={onSave}
          onEdit={() => setInEditState(true)}
          isSaving={savingData}
          inEditState={inEditState}
        />
      </div>
      <div className="details-container pxy-6 flex-column flex-1 overflow-scroll">
        {productUsage === 'ALLERGENS' && (
          <ProductAllergens
            value={value}
            setValue={setValue}
            labelData={labelData}
            setLabelData={setLabelData}
            inEditState={inEditState}
            mayContain={mayContain}
            setMayContain={setMayContain}
            customAllergens={customAllergens}
            setCustomAllergens={setCustomAllergens}
            allergensList={allergensList}
            setAllergensList={setAllergensList}
            selectedProductAllergens={selectedProductAllergens}
            setSelectedProductAllergens={setSelectedProductAllergens}
            setInitialSelectedProductAllergens={setInitialSelectedProductAllergens}
            refreshProductData={refreshProductData}
          />
        )}
        {productUsage === 'INGREDIENTS' && (
          <ProductIngredients
            value={value}
            setValue={setValue}
            labelData={labelData}
            setLabelData={setLabelData}
            inEditState={inEditState}
            refreshProductData={refreshProductData}
          />
        )}
      </div>
    </ProductDetailsRightWrapper>
  );
};

export const ProductDetailsRightWrapper = styled.div`
  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default ProductDetailsRight;
