import parse from 'html-react-parser';
import React from 'react';
import styled from 'styled-components';
import { uppercase } from '../../helpers/utils';
import { usePixelRatio } from '../../hooks/usePixelRatio';

const LabelPreview = ({ previewText = '', previewSettings = {}, isDataAvailable = false, noDataLabel = '' }) => {
  const size = usePixelRatio();

  return (
    <LabelPreviewWrapper size={size} className="relative" previewHeight={previewSettings.height}>
      <label className="bold-text mb-4 flex">{uppercase('Label preview')}</label>
      <div className="radius-4 border pxy-2 preview-renderer overflow-scroll">
        {isDataAvailable ? (
          parse(previewText)
        ) : (
          <div className="flex items-center justify-center h-full">
            <label className="bold-text main-grey-text">{uppercase(noDataLabel)}</label>
          </div>
        )}
      </div>
    </LabelPreviewWrapper>
  );
};

export const LabelPreviewWrapper = styled.div`
  .preview-renderer {
    width: 53mm;
    zoom: calc(${({ size }) => (size >= 2 ? 1 + 1 / size : 1)});
    box-shadow: 0px 4px 10px 0px rgba(9, 19, 53, 0.06);
    height: ${({ previewHeight }) => previewHeight};
  }
`;

export default LabelPreview;
