import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getStorageGroupList, setStorageGroupList } from '../../store/features/storageGroupsSlice';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import TableHeader from '../common/table-header';
import StorageGroupListItem from './storage-list-item';

const storageTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'SCOPE', key: 'SCOPE' },
  { name: 'USAGE', key: 'USAGE' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const StorageGroupsList = ({ debouncedSearch = '' }) => {
  const dispatch = useDispatch();

  const { organizationParentChildIds } = useContext(OrganisationContext);

  const { storageGroupList = [] } = useSelector(state => state.storageGroup);

  const [loading, setLoading] = useState(true);
  const [storageGroupListItem, setStorageGroupListItem] = useState([]);

  const onStorageGroupListUpdate = updatedGroup => {
    const updatedDeviceList = storageGroupListItem.map(storageGroup =>
      storageGroup.id === updatedGroup.id ? updatedGroup : storageGroup,
    );
    setStorageGroupListItem(updatedDeviceList);
  };

  const fetchStorageGroupList = debouncedSearch => {
    setLoading(true);
    dispatch(
      getStorageGroupList({
        params: {
          search: debouncedSearch,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchStorageGroupList(debouncedSearch);
  }, [debouncedSearch, organizationParentChildIds]);

  useEffect(() => {
    return () => {
      dispatch(setStorageGroupList([]));
    };
  }, []);

  useEffect(() => {
    if (storageGroupList) {
      setStorageGroupListItem(storageGroupList);
    }
  }, [storageGroupList]);

  return (
    <StorageListGroupWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (storageGroupListItem || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={storageTableHeaders} />
          <div className="flex-column overflow-scroll">
            {(storageGroupListItem || []).map(storage => (
              <StorageGroupListItem
                storage={storage}
                key={storage?.id}
                onStorageGroupListUpdate={onStorageGroupListUpdate}
                fetchStorageGroupList={fetchStorageGroupList}
              />
            ))}
          </div>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </StorageListGroupWrapper>
  );
};

export const StorageListGroupWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 120px 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default StorageGroupsList;
