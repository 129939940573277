import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getContactValueByType } from '../../helpers/utils';
import Menu from '../common/menu';

const BrandListItem = ({ brand = {}, isLast = false }) => {
  const { t } = useTranslation();

  const { name, primary_location, contact_details } = brand;
  const contactEmail = getContactValueByType(contact_details, 'EMAIL');
  const contactPhone = getContactValueByType(contact_details, 'MOBILE');

  return (
    <VenueListItemWrapper className={`data-container py-2 cursor ${!isLast && 'border-bottom'}`}>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="medium-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{primary_location?.formatted_address}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactPhone?.contact_value}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{contactEmail?.contact_value}</label>
      </div>
      <div className="flex items-center justify-center">
        <Menu menuList={[{ name: t('DETAILS'), onClick: () => {} }]} />
      </div>
    </VenueListItemWrapper>
  );
};

const VenueListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default BrandListItem;
