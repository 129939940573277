import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import Dropdown from '../../components/common/dropdown';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorFieldJoined } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

import { changeGroupStorageRulesGroup, getStorageGroupList } from '../../store/features/storageGroupsSlice';

const ChangeGroup = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal, organizationParentChildIds } = useContext(OrganisationContext);
  const { onSuccess, id: device_id } = modal.content;

  const [loading, setLoading] = useState(false);
  const [storageGroupList, setStorageGroupList] = useState([]);

  const fetchStorageGroupList = () => {
    setLoading(true);
    dispatch(
      getStorageGroupList({
        fetchOnly: true,
        params: {
          lifecycle: 'ACTIVE',
        },
      }),
    )
      .then(data => {
        const groupList =
          data?.length > 0
            ? data?.map(item => ({
                ...item,
                value: item?.id,
                label: item?.name,
              }))
            : [];
        setStorageGroupList(groupList);
      })
      .finally(() => setLoading(false));
  };

  const onConfirm = async data => {
    setLoading(true);
    const { id: group_id } = data?.group_name || {};
    const request = {
      id: device_id,
    };
    dispatch(changeGroupStorageRulesGroup({ group_id: group_id, request: [request] }))
      .then(addedStorage => {
        dispatch(addToast({ success: true, text: t('SUCCESSFULLY_CHANGE_GROUP'), id: nanoid() }));
        onSuccess(addedStorage);
        setModal(initModal);
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_RULES_GROUP');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: { group_name: null },
    validationSchema: Yup.object().shape({
      group_name: Yup.object().nullable().required('Please select a group name'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onConfirm,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorFields = getErrorFieldJoined(errors, value => {
        return value;
      });
      const errorText = `Please select ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  useEffect(() => {
    fetchStorageGroupList();
  }, []);

  return (
    <CommonPopup
      popupTitle={t('CHANGE_GROUP')}
      confirmButtonProps={{ label: t('SAVE') }}
      cancelButtonProps={{ label: t('CANCEL') }}
      onCancel={() => setModal(initModal)}
      onConfirm={handleSubmit}
      disabled={loading}>
      <ChangeGroupWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <Dropdown
          customStyle={{ control: { height: '40px', borderRadius: '12px' } }}
          name={t('NAME')}
          options={storageGroupList}
          value={storageGroupList.find(item => item.value === values.group_name?.value) || null}
          onChange={option => setFieldValue('group_name', option)}
          placeholder={t('select_type', { type: 'group name' })}
          error={errors.group_name && !values.group_name}
        />
      </ChangeGroupWrapper>
    </CommonPopup>
  );
};

const ChangeGroupWrapper = styled.div``;

export default ChangeGroup;
