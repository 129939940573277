import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Checkbox = ({
  name,
  is_required = false,
  onChange = () => {},
  checked = false,
  error,
  is_rounded = false,
  className = '',
  inputClassname = '',
}) => {
  return (
    <CheckboxWrapper className={`flex items-center ${className}`}>
      <input
        className={classNames(
          'cursor checkbox radius-1',
          inputClassname,
          is_rounded && 'rounded',
          checked && 'checked',
          error && 'required-error',
        )}
        type="checkbox"
        checked={checked}
        onClick={e => {
          e.stopPropagation();
          onChange(!checked);
        }}
      />
      {name && (
        <div className="ml-2 flex-1">
          <span className="regular-text font-16 name-text">{name}</span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: 24px;
    min-height: 24px;
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.main_border};
    background-color: ${({ theme }) => theme.colors.white};
  }

  .rounded {
    border-radius: 50%;
  }

  .checked {
    outline: ${({ theme }) => theme.colors.main_orange} solid 6px;
    outline-offset: -12px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.additional_red};
  }
`;

export default Checkbox;
