import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const deviceSlice = createSlice({
  name: 'device',
  initialState: {
    devicesList: [],
    deviceDetails: {},
  },
  reducers: {
    setDevicesList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.devicesList = { ...data, content: [...state.devicesList.content, ...data.content] };
      } else {
        state.devicesList = data;
      }
    },
    setDevicesDetails: (state, { payload }) => {
      state.deviceDetails = payload;
    },
  },
});

export const getDevices =
  ({ merge, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/devices`, { params, paramsSerializer: { indexes: null } });
      dispatch(setDevicesList({ data, merge }));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getDevicesDetails =
  ({ device_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/devices/${device_id}`, { params });
      dispatch(setDevicesDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateDevice =
  ({ device_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/devices/${device_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateDevicePin =
  ({ device_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/devices/${device_id}/pin`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateDeviceLocation =
  ({ device_id, request }) =>
  async dispatch => {
    try {
      const { data } = await api.put(`/devices/${device_id}`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setDevicesList, setDevicesDetails } = deviceSlice.actions;
export default deviceSlice.reducer;
