import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accountsList: [],
    accountDetails: {},
    accountSearchFields: {},
  },
  reducers: {
    setAccountsList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.accountsList = { ...data, content: [...state.accountsList.content, ...data.content] };
      } else {
        state.accountsList = data;
      }
    },
    setAccountDetails: (state, { payload }) => {
      state.accountDetails = payload;
    },
    setVenueDetails: (state, { payload }) => {
      state.venueDetails = payload;
    },
    setAccountSearchFields: (state, { payload }) => {
      state.accountSearchFields = payload;
    },
  },
});

export const getAccountsList = payload => async dispatch => {
  try {
    const { organizationId, params, merge } = payload;
    const { data } = await api.get(`/organizations/${organizationId}/network`, { params: params });
    dispatch(setAccountsList({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountDetails = payload => async dispatch => {
  try {
    const { organizationId, isFetchOnly = false } = payload;
    const { data } = await api.get(`/organizations/${organizationId}`);
    !isFetchOnly && dispatch(setAccountDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountCategoryTagInfo = payload => async () => {
  try {
    const { organizationId, params } = payload;
    const { data } = await api.get(`/organizations/${organizationId}/network`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getVenueDetails = payload => async dispatch => {
  try {
    const { organizationId } = payload;
    const { data } = await api.get(`/organizations/${organizationId}`);
    dispatch(setVenueDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAccountDevices = payload => async () => {
  try {
    const { organizationId, params } = payload;
    const { data } = await api.get(`/organizations/${organizationId}/devices`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAccount =
  ({ request }) =>
  async () => {
    try {
      const { data } = await api.post(`/organizations`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getOrganizationCategories =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organization_categories`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const onAccessAccount =
  ({ organization_id }) =>
  async dispatch => {
    try {
      const { data } = await api.post(`/organizations/${organization_id}/masquerade`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setAccountsList, setAccountDetails, setVenueDetails, setAccountSearchFields } = accountsSlice.actions;
export default accountsSlice.reducer;
