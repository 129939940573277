import parse from 'html-react-parser';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as LinkIcon } from '../../assets/icons/arrows/link.svg';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { convertMarkupToHtml, createHtmlSaleLabel } from '../../helpers/preview-converter';
import { isEditorValueEmpty } from '../../helpers/utils';
import { getProductLabels } from '../../store/features/productsSlice';
import Dropdown from '../common/dropdown';
import Editor from '../common/editor';
import LabelPreview from './label-preview';

const heightOptions = [
  { label: '80 mm', value: '80mm' },
  { label: '40 mm', value: '40mm' },
];

const ProductIngredients = ({ value, setValue, labelData, setLabelData, inEditState, refreshProductData }) => {
  const dispatch = useDispatch();

  const { hasPermission } = useContext(OrganisationContext);

  const { productId } = useParams();
  const { productDetails } = useSelector(state => state.products);

  const [loading, setLoading] = useState(true);
  const [previewSettings, setPreviewSettings] = useState({
    height: '40mm',
    // date: moment().add(3, 'day').format('DD/MM/YY'),
    date: '11/11/11',
    batch_code: 111,
    include_date: true,
  });

  useEffect(() => {
    if (productId) {
      dispatch(getProductLabels({ productId }))
        .then(data => {
          const firstLabelData = data?.[0] || {};
          setLabelData(firstLabelData);
          setValue(convertMarkupToHtml(firstLabelData.label_text?.ingredients || ''));
        })
        .catch(() => {
          setLabelData({});
          setValue('');
        })
        .finally(() => setLoading(false));
    }
  }, [productId, refreshProductData]);

  return (
    <ProductIngredientsWrapper className="flex-column flex-1">
      {loading ? (
        <div className="item flex items-center justify-center flex-1 my-4">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <Fragment>
          <label
            className="semibold-text primary-text flex items-center mb-6 cursor"
            onClick={() => window.open('https://www.gov.uk/food-labelling-and-packaging/ingredients-list', '_blank')}>
            Government guidance on listing ingredients <LinkIcon className="ml-2" />
          </label>
          {hasPermission('LABELS_MANAGE') && (
            <Fragment>
              {inEditState ? (
                <Editor value={value} setValue={setValue} placeholder="Select or add ingredient" />
              ) : (
                <div className="px-4 py-3 radius-2 mb-12 label-container">
                  {!isEditorValueEmpty(value) || labelData?.label_text?.ingredients ? (
                    <label className="regular-text font-16">
                      {parse(convertMarkupToHtml(value || labelData?.label_text))}
                    </label>
                  ) : (
                    <label className="medium-text main-grey-text">No ingredients added</label>
                  )}
                </div>
              )}
            </Fragment>
          )}
          <div className="flex col-gap-12">
            <LabelPreview
              previewSettings={previewSettings}
              setPreviewSettings={setPreviewSettings}
              isDataAvailable={!isEditorValueEmpty(value) || labelData?.label_text?.ingredients}
              noDataLabel="No ingredients added"
              previewText={createHtmlSaleLabel(
                {
                  ...productDetails,
                  ingredients: value || labelData?.label_text?.ingredients,
                  use_by_date: previewSettings.date,
                },
                previewSettings.include_date ? 'USE BY' : null,
                previewSettings.batch_code,
              )}
            />
            {/* TODO: Refactor if possible */}
            <div className="flex-column row-gap-4 mt-8 settings-container">
              <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1 w-full">Height</label>
                <Dropdown
                  className="custom-dropdown"
                  options={heightOptions}
                  value={heightOptions.find(height => height.value === previewSettings.height)}
                  onChange={option => setPreviewSettings({ ...previewSettings, height: option.value })}
                />
              </div>
              {/* <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text">Include date</label>
                <Switch
                  onClick={() =>
                    setPreviewSettings({
                      ...previewSettings,
                      include_date: !previewSettings.include_date,
                    })
                  }
                  enabled={previewSettings.include_date}
                />
              </div>
              {previewSettings.include_date && (
                <div className="flex items-center col-gap-8 justify-between">
                  <label className="regular-text">Date</label>
                  <input
                    className="input radius-2 custom-input"
                    placeholder={'Date'}
                    value={previewSettings.date}
                    onChange={({ target: { value } }) => setPreviewSettings({ ...previewSettings, date: value })}
                  />
                </div>
              )}
              <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1">Batch code</label>
                <input
                  className="input radius-2 custom-input"
                  placeholder={'Batch'}
                  value={previewSettings.batch_code}
                  onChange={({ target: { value } }) => setPreviewSettings({ ...previewSettings, batch_code: value })}
                />
              </div> */}
            </div>
          </div>
        </Fragment>
      )}
    </ProductIngredientsWrapper>
  );
};

export const ProductIngredientsWrapper = styled.div`
  .settings-container {
    min-width: 200px;
  }

  .label-container {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .custom-dropdown,
  .custom-input {
    height: 32px;
    font-size: 14px;
    width: 140px;
  }
`;

export default ProductIngredients;
