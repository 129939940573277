import classNames from 'classnames';
import { useFormik } from 'formik';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import Dropdown from '../../components/common/dropdown';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { getErrorFieldJoined } from '../../helpers/utils';
import { createIntegration, getConnectors } from '../../store/features/integrationsSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('name'),
  description: Yup.string().required('description'),
  provider: Yup.object(),
  connector: Yup.object().required('provider'),
});

const AddIntegration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const [connectors, setConnectors] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchConnectors = () => {
    dispatch(getConnectors())
      .then(data => {
        const connectorData = data.map(connector => ({ ...connector, value: connector.id, label: connector.name }));
        setConnectors(connectorData);
      })
      .catch(() => {
        setConnectors([]);
        dispatch(addToast({ error: true, text: 'Error while fetching data' }));
      });
  };

  useEffect(() => {
    fetchConnectors();
  }, []);

  const onAdd = async () => {
    setLoading(true);
    const { name, description, connector } = values;
    const request = {
      name: name,
      description: description,
      connector: { id: connector.id },
      is_enabled: false,
      is_default: false,
    };
    dispatch(createIntegration({ request }))
      .then(data => {
        setModal(initModal);
        navigate(`/platforms/integrations/integration-details/${data.id}`);
      })
      .catch(() => {
        dispatch(addToast({ error: true, text: 'Error while creating integration', id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const { setFieldValue, errors, values, handleSubmit, isValidating } = useFormik({
    initialValues: { name: '', description: '', provider: null, connector: null },
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onAdd,
  });

  useEffect(() => {
    if (!isValidating && Object.keys(errors).length) {
      const errorFields = getErrorFieldJoined(errors, (_key, value) => value);
      const errorText = `Please add ${errorFields}`;
      dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
    }
  }, [errors, isValidating]);

  return (
    <CommonPopup
      popupTitle={t('new_type', { type: 'integration' })}
      confirmButtonProps={{ label: 'Add' }}
      onCancel={() => setModal(initModal)}
      onConfirm={handleSubmit}
      disabled={loading}>
      <AddIntegrationWrapper className="flex-column items-center w-full">
        <div className="w-full flex-column mt-6">
          <label className="regular-text main-grey-text mb-1">{t('NAME')}</label>
          <input
            autoComplete="turnoff"
            className={classNames('input', errors.name && !values.name && 'error-border')}
            onChange={({ target: { value } }) => setFieldValue('name', value)}
            placeholder="Enter name"
            value={values.name}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text main-grey-text mb-1">{t('DESCRIPTION')}</label>
          <textarea
            className={classNames('textarea', errors.description && !values.description && 'error-border')}
            onChange={({ target: { value } }) => setFieldValue('description', value)}
            placeholder="Enter description"
            rows={4}
            value={values.description}
          />
        </div>
        <div className="w-full flex-column mt-6">
          <label className="regular-text main-grey-text mb-1">Providers</label>
          <Dropdown
            customStyle={{ control: { height: '40px', marginTop: '4px', borderRadius: '12px' } }}
            options={connectors}
            value={connectors?.find(c => c.value === values.provider?.value)}
            onChange={option => {
              setFieldValue('provider', option);
              setFieldValue('connector', null);
            }}
            placeholder={t('select_type', { type: 'provider' })}
            error={errors.provider && !provider}
          />
        </div>
        {values.provider && (
          <div className="sub-provider mt-6">
            {values.provider?.connectors?.map(connector => (
              <div
                key={connector?.id}
                className={classNames(
                  'flex items-center pxy-2 cursor border radius-3',
                  values.connector?.id === connector.id && 'selected-sub-provider',
                )}
                onClick={() => setFieldValue('connector', connector)}>
                <img className="mr-2" alt="icon" src={`${connector.icon?.active}`} width={24} height={24} />
                <span className="medium-text">{connector.name}</span>
              </div>
            ))}
          </div>
        )}
      </AddIntegrationWrapper>
    </CommonPopup>
  );
};

const AddIntegrationWrapper = styled.div`
  .sub-provider {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    column-gap: 16px;
    row-gap: 16px;
  }

  .sub-provider-item {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;
  }

  .selected-sub-provider {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

export default AddIntegration;
