import { nanoid } from 'nanoid';
import React, { Fragment, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getFormattedDate, getRecurringPeriodName, isUserSassOperator } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateDevice } from '../../store/features/deviceSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';
import Switch from '../common/switch';

const DeviceListItem = ({
  device = {},
  onDeviceRequirePinUpdate = () => {},
  isFirst = false,
  venueListLength,
  refreshDeviceList = () => {},
}) => {
  const { hasPermission, setModal } = useContext(OrganisationContext);

  const navigate = useNavigate();

  const {
    id: deviceId,
    name,
    device_code,
    created_on,
    subscription,
    organisation,
    requires_profile_pin,
    serial_number,
    mac_address,
  } = device;
  const { recurring, status, integration } = subscription || {};
  const dispatch = useDispatch();

  const isSassOperator = isUserSassOperator();

  const onSetLocation = () => {
    setModal({
      type: 'update-device-location',
      content: {
        device: device,
        popupTitle: 'Set location',
        onSuccess: refreshDeviceList,
      },
    });
  };

  const onUpdatePin = () => {
    setModal({
      type: 'update-device-pin',
      content: { device, onSuccess: refreshDeviceList },
    });
  };

  const onToggleRequiredPin = updatedRequiredPin => {
    const deviceUpdateRequest = {
      name,
      organisation: { id: organisation.id },
      serial_number,
      mac_address,
      requires_profile_pin: updatedRequiredPin,
    };
    onDeviceRequirePinUpdate({ ...device, requires_profile_pin: updatedRequiredPin });
    dispatch(updateDevice({ device_id: deviceId, request: deviceUpdateRequest })).catch(() => {
      onDeviceRequirePinUpdate(device);
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating device',
          id: nanoid(),
        }),
      );
    });
  };

  const filteredMenuList = useFilteredMenuList({
    menuList: [
      { name: 'Update pin', onClick: onUpdatePin, permission: 'DEVICES_MANAGE', key: 'UPDATE_PIN' },
      { name: 'Set location', onClick: onSetLocation, permission: 'DEVICES_MANAGE', key: 'SET_LOCATION' },
    ].filter(menuItem => (venueListLength === 0 ? menuItem.key !== 'SET_LOCATION' : true)),
  });

  const navigateToDeviceDetails = () => {
    if (!isSassOperator) {
      navigate(`/devices/device-details-info/${deviceId}`);
    }
  };

  return (
    <DeviceListItemWrapper
      className={`data-container py-2 cursor ${!isFirst && 'border-top'}`}
      onClick={() => navigateToDeviceDetails()}>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="medium-text font-16 one-line">{name}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden">{device_code}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{organisation?.name}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">
          {created_on ? getFormattedDate(created_on, 'DD/MM/YYYY') : ''}
        </label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">
          {getRecurringPeriodName(recurring?.interval)}
        </label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{integration?.external_reference}</label>
      </div>
      <div className="flex items-center px-5 overflow-hidden">
        <label className="regular-text font-16 overflow-hidden one-line">{<ProductStatus status={status} />}</label>
      </div>
      <div className="flex items-center justify-center">
        <Menu
          menuClassName="device-list-menu"
          menuList={filteredMenuList}
          ExtraOptions={
            <Fragment>
              {hasPermission('DEVICES_MANAGE') && (
                <div className="flex pl-4 pb-4 pr-3 items-center justify-between">
                  <label className="regular-text one-line font-16 mr-2">Require profile pins</label>
                  <Switch
                    variant={'medium'}
                    enabled={requires_profile_pin}
                    onClick={() => onToggleRequiredPin(!requires_profile_pin)}
                  />
                </div>
              )}
            </Fragment>
          }
        />
      </div>
    </DeviceListItemWrapper>
  );
};

const DeviceListItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default DeviceListItem;
