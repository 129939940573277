export const multipleFilterTypeEnum = {
  products: 'PRODUCTS',
  category: 'CATEGORY',
  printLog: 'PRINT_LOG',
  expiryReport: 'EXPIRY_REPORT',
  account: 'ACCOUNT',
  brand: 'BRAND',
  venue: 'VENUE',
};

export const organizationFilterOptions = [
  {
    label: 'Network',
    param: 'organization_id',
    value: 'ORGANIZATION',
    childComponent: 'MULTI_DROPDOWN',
  },
];

export const productsFilterOptions = [
  {
    label: 'Category',
    param: 'category_id',
    value: 'CATEGORY',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Usage',
    param: 'category.usage',
    value: 'USAGE',
    childComponent: 'DROPDOWN',
  },
  {
    label: 'State',
    param: 'lifecycle',
    value: 'STATE',
    childComponent: 'DROPDOWN',
  },
];

export const categoryFilterOptions = [
  {
    label: 'Usage',
    param: 'usage',
    value: 'USAGE',
    childComponent: 'DROPDOWN',
  },
  {
    label: 'State',
    param: 'lifecycle',
    value: 'STATE',
    childComponent: 'DROPDOWN',
  },
];

export const usageFilter = [
  { value: 'SINGLE', label: 'Sale' },
  { value: 'COMPONENT', label: 'Prep' },
];

export const lifecycleFilter = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const stateFilter = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const printLogFilterOptions = [
  {
    label: 'Product',
    param: 'product_id',
    value: 'PRODUCT',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Storage',
    param: 'storage_id',
    value: 'STORAGE',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Initials',
    param: 'initials',
    value: 'INITIALS',
    childComponent: 'INPUT',
  },
  {
    label: 'Date',
    param: 'printed',
    value: 'DATE',
    childComponent: 'DATE_RANGE',
  },
];

export const expiryReportFilterOptions = [
  {
    label: 'Product',
    param: 'product_id',
    value: 'PRODUCT',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Storage',
    param: 'storage_id',
    value: 'STORAGE',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Initials',
    param: 'initials',
    value: 'INITIALS',
    childComponent: 'INPUT',
  },
  {
    label: 'Date',
    param: 'use_by',
    value: 'DATE',
    childComponent: 'DATE_RANGE',
  },
];

export const AccountFilterOptions = [
  {
    label: 'Brand',
    param: 'brand_id',
    value: 'BRAND',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
  {
    label: 'Venue',
    param: 'venue_id',
    value: 'VENUE',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
];

export const brandFilterOptions = [
  {
    label: 'Venue',
    param: 'venue_id',
    value: 'VENUE',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
];

export const venueFilterOptions = [
  {
    label: 'Brand',
    param: 'brand_id',
    value: 'BRAND',
    childComponent: 'SEARCHABLE_DROPDOWN',
  },
];
