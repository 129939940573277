import React, { useContext } from 'react';
import { OrganisationContext } from '../../context/organisationContext';

import { ReactComponent as ArrowRight } from '../../assets/icons/arrows/right.svg';
import { SidebarMenuItemWrapper } from '../../styles/components/sidebar/sidebar.styled';

const MenuItem = ({ item, onClick, expanded }) => {
  const { checkMultiplePermission, hasMultiplePermission } = useContext(OrganisationContext);
  return (
    <SidebarMenuItemWrapper>
      <div className="menu-item">
        <div
          className="flex items-center menu-item-container"
          style={{
            opacity: hasMultiplePermission(item.permission) ? 1 : 0.5,
          }}>
          <div
            className={`flex items-center justify-between cursor w-full px-3 radius-3 mi-item ${
              item.selected && (item.subitems.length > 0 ? 'mi-subitem-selected' : 'mi-item-selected')
            }`}
            onClick={() => checkMultiplePermission(() => onClick(item.id), item.permission)}>
            <div className="flex items-center">
              <div className="flex pxy-1 radius-2 white-text icon-container">
                <item.icon className={`white-text ${item.setStrokeOfIcon ? 'mi-icon-stroke' : 'mi-icon'}`} />
              </div>
              {expanded && <label className="regular-text white-text ml-4 menu-item-name">{item.text}</label>}
            </div>
            {expanded && item.subitems.length > 0 && (
              <ArrowRight className={`white-text menu-arrow ${item.open && 'menu-arrow-down'}`} />
            )}
          </div>
        </div>
        {item.subitems.length > 0 && (
          <div
            className={`flex-column cursor menu-subitems ${item.open ? `menu-subitems-show` : 'menu-subitems-hide'}`}
            style={{ height: item.open ? `${48 * item.subitems.length}px` : 0 }}>
            {item.subitems.map((subitem, i) => (
              <div
                key={i}
                className={`flex subitem-container radius-3 cursor ${subitem.selected && 'subitem-selected'}`}
                onClick={() => checkMultiplePermission(() => onClick(item.id, subitem.id), subitem.permission)}
                style={{
                  opacity: hasMultiplePermission(subitem.permission) ? 1 : 0.5,
                }}>
                <div
                  key={subitem.id}
                  className={`flex items-center w-full subitem white-text relative ${expanded ? 'pl-10' : 'px-3'}`}>
                  <div className="flex pxy-1 radius-2 white-text icon-container">
                    <subitem.icon className={`${subitem.setStrokeOfIcon ? 'mi-icon-stroke' : 'mi-icon'}`} />
                  </div>
                  {expanded && <label className="regular-text white-text ml-4 sub-item-name">{subitem.text}</label>}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </SidebarMenuItemWrapper>
  );
};

export default MenuItem;
