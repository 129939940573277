import isEqual from 'lodash.isequal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { createFilterUrl } from '../../helpers/utils';
import { getStorageList, setStorageList } from '../../store/features/storageSlice';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import TableHeader from '../common/table-header';
import StorageListItem from './storage-list-item';

const storageTableHeaders = [
  // { name: '', key: 'ITEM_SELECTOR' },
  { name: 'NAME', key: 'NAME' },
  { name: 'USE BY', key: 'USE_BY' },
  { name: 'USAGE', key: 'USAGE' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const StorageList = ({ debouncedSearch = '' }) => {
  const dispatch = useDispatch();

  const { storageList } = useSelector(state => state.storage);

  const [loading, setLoading] = useState(true);
  const [selectedStorageIds, setSelectedStorageIds] = useState([]);

  const fetchStorageList = debouncedSearch => {
    setLoading(true);
    dispatch(
      getStorageList({
        forFetchOnly: false,
        params: {
          search: debouncedSearch,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setSelectedStorageIds([]);
    fetchStorageList(debouncedSearch);
  }, [debouncedSearch]);

  useEffect(() => {
    return () => {
      dispatch(setStorageList([]));
    };
  }, []);

  const onSelectAllStorages = () => {
    if (selectedStorageIds.length === (storageList || []).length) {
      setSelectedStorageIds([]);
    } else {
      setSelectedStorageIds((storageList || []).map(storage => storage.id));
    }
  };

  const onStorageSelect = storageId => {
    if (selectedStorageIds.includes(storageId)) {
      setSelectedStorageIds(selectedStorageIds.filter(id => id !== storageId));
    } else {
      setSelectedStorageIds([...selectedStorageIds, storageId]);
    }
  };

  return (
    <StorageListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (storageList || []).length > 0 ? (
        <Fragment>
          <TableHeader
            headers={storageTableHeaders}
            allSelected={selectedStorageIds.length === (storageList || []).length}
            onSelectorClick={onSelectAllStorages}
          />
          <div className="flex-column overflow-scroll">
            {(storageList || []).map(storage => (
              <StorageListItem
                storage={storage}
                key={storage?.id}
                storageSelected={selectedStorageIds.includes(storage.id)}
                onStorageSelect={onStorageSelect}
              />
            ))}
          </div>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </StorageListWrapper>
  );
};

export const StorageListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 120px 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default StorageList;
