import classNames from 'classnames';
import React from 'react';
import Select, { components } from 'react-select';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/icons/arrows/down.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/toast-close.svg';

const defaultCustomStyle = {
  control: {},
  valueContainer: {},
  menu: {},
  menuList: {},
};

const variants = {
  primary: {
    control: { height: '40px', borderRadius: '12px' },
  },
};

const Option = props => {
  const { innerProps, data, isSelected } = props;
  return (
    <div
      className={`flex items-center w-full pxy-2 radius-1 cursor option-wrapper ${isSelected ? 'selected' : ''}`}
      {...innerProps}>
      <span className={`flex-1 regular-text font-16 option-text ${isSelected && 'semibold-text'}`}>{data.label}</span>
    </div>
  );
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} className="mr-2">
      <DownIcon />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return <CloseIcon height={16} width={16} onClick={props.clearValue} />;
};

const Dropdown = ({
  options = [],
  placeholder = '',
  isSearchable = false,
  onChange = () => {},
  value = null,
  isMulti = false,
  name = '',
  is_required = false,
  className = '',
  error = false,
  customStyle = defaultCustomStyle,
  menuPlacement = 'auto',
  variant = '',
  customComponents = {},
  isClearable = false,
  ...rest
}) => {
  const theme = useTheme();

  const dropdownStyle = {
    control: (baseStyles, { selectProps: { menuIsOpen } }) => ({
      ...baseStyles,
      borderColor: error ? theme.colors.additional_red : menuIsOpen ? theme.colors.primary : theme.colors.main_border,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      minHeight: '32px',
      cursor: 'pointer',
      boxShadow: 'none',

      '&:hover': {
        borderColor: error ? theme.colors.additional_red : menuIsOpen ? theme.colors.primary : theme.colors.main_border,
      },
      ...(variants[variant]?.control || {}),
      ...customStyle.control,
    }),
    placeholder: baseStyles => ({
      ...baseStyles,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontFamily: 'Lato Regular',
      color: theme.colors.main_grey,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: baseStyles => ({
      ...baseStyles,
      color: theme.colors.black_text,
    }),
    valueContainer: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato Regular',
      padding: '4px 8px',
      ...customStyle.valueContainer,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      borderRadius: '8px',
      padding: '8px',
      zIndex: 11,
      ...customStyle.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      padding: '0px',
      maxHeight: '180px',
      ...customStyle.menuList,
    }),
    noOptionsMessage: baseStyles => ({
      ...baseStyles,
      fontFamily: 'Lato Regular',
      color: theme.colors.main_grey,
    }),
  };

  return (
    <DropdownWrapper className={classNames('w-full', className)}>
      {name && (
        <div className="mb-1">
          <span className="regular-text main-grey-text font-12">{name}</span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <Select
        isClearable={isClearable}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
        onChange={option => {
          onChange(option);
        }}
        classNamePrefix={'select'}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        value={value}
        styles={dropdownStyle}
        menuPlacement={menuPlacement}
        components={{
          Option,
          DropdownIndicator,
          ClearIndicator,
          ...customComponents,
        }}
        {...rest}
      />
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  .option-wrapper {
    &:hover {
      background-color: ${({ theme }) => theme.colors.menu_item_hover};
    }
  }

  .selected {
    background-color: ${({ theme }) => theme.colors.menu_item_hover};

    &:hover {
      background-color: ${({ theme }) => theme.colors.menu_item_hover};
    }
  }
`;

export default Dropdown;
