import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { getStorageGroupDetails, setStorageGroupDetails } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import StorageGroupDetailsLeft from './StorageGroupDetailsLeft';
import StorageGroupDetailsRight from './StorageGroupDetailsRight';

const StorageGroupDetails = () => {
  const dispatch = useDispatch();
  const { storage_group_id } = useParams();

  const [loading, setLoading] = useState(false);

  const fetchStorageGroupDetails = async storage_rule_group_id => {
    setLoading(false);
    await dispatch(
      getStorageGroupDetails({
        storage_rule_group_id: storage_rule_group_id,
      }),
    )
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while fetching storage group details' }));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (storage_group_id) {
      fetchStorageGroupDetails(storage_group_id);
    }

    return () => {
      dispatch(setStorageGroupDetails({}));
    };
  }, [storage_group_id]);

  return (
    <StorageGroupDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <StorageGroupDetailsLeft />
          <StorageGroupDetailsRight />
        </div>
      )}
    </StorageGroupDetailsWrapper>
  );
};

export const StorageGroupDetailsWrapper = styled.div``;

export default StorageGroupDetails;
