import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const StorageGroupDetailsUI = ({ storageGroupDetails }) => {
  const { t } = useTranslation();
  const { name, is_saas_default } = storageGroupDetails || {};

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('NAME')}</label>
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text font-16 one-line">{is_saas_default ? 'Local' : 'Preset Group'}</label>
      </div>
    </Fragment>
  );
};

const StorageGroupDetailsLeft = () => {
  const { t } = useTranslation();
  const { storageGroupDetails } = useSelector(state => state.storageGroup);

  return (
    <StorageDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex  items-center pxy-6">
          <label className="bold-text font-24 flex-1">{t('DETAILS')}</label>
        </div>
        <div className="details-container pxy-6 flex-column row-gap-6 overflow-scroll">
          <StorageGroupDetailsUI storageGroupDetails={storageGroupDetails} />
        </div>
      </div>
    </StorageDetailsLeftWrapper>
  );
};

export const StorageDetailsLeftWrapper = styled.div`
  width: 350px;

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default StorageGroupDetailsLeft;
