import axios from 'axios';
import { setUser } from '../features/userSlice';
import instance from './index';

const setUpInterceptor = store => {
  instance.interceptors.request.use(
    async function (config) {
      try {
        config.headers.Authorization = 'Bearer ' + (await getAccessToken(store));
      } catch (e) {
        localStorage.removeItem('user');
        window.location.reload();
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
};

const getAccessToken = async store => {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user.exp - 10000 < new Date().getTime()) {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/user/refresh?refresh_token=${user.refresh_token}`,
      {},
    );
    if (
      data.organisations &&
      data.organisations.length > 0 &&
      data.organisations[0].roles.find(role => role.name === 'Admin')
    )
      data.admin = true;
    localStorage.setItem('user', JSON.stringify(data));
    store.dispatch(setUser(data));
    return data.access_token;
  } else return user.access_token;
};

export default setUpInterceptor;
