import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Tabs = ({ tabs = [], selected = '', onTabChange = () => {}, tabsClassName = '' }) => {
  return (
    <TabWrapper className={classNames('radius-2 flex', tabsClassName)}>
      {tabs.map(tab => (
        <div
          onClick={() => onTabChange(tab)}
          className={classNames('medium-text font-16 tab-item cursor flex items-center justify-center cursor', {
            'selected-item': selected === tab.value,
          })}>
          {tab.name}
        </div>
      ))}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  background: ${({ theme }) => theme.colors.light_grey};
  height: 32px;

  .tab-item {
    min-width: 132px;
  }

  .selected-item {
    background: ${({ theme }) => theme.colors.black_text};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export default Tabs;
