import isEquals from 'lodash.isequal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { createFilterUrl, uppercase } from '../../helpers/utils';
import { getProductsList, setProductsList } from '../../store/features/productsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import ProductsListItem from './products-list-item';

const productsTableHeaders = [
  { name: 'PRODUCT', key: 'PRODUCT' },
  { name: 'FOR', key: 'FOR' },
  { name: 'CATEGORY', key: 'CATEGORY' },
  { name: 'INGREDIENTS / ALLERGENS', key: 'INGREDIENTS_ALLERGENS' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const ProductsList = ({ debouncedSearch = '', getAutomationData = () => {}, filters = [] }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const filterRef = useRef(createFilterUrl(filters));

  const { userOrganization } = useSelector(state => state.user);
  const { productsList } = useSelector(state => state.products);
  const { content: products, total_elements } = productsList || {};

  const [loading, setLoading] = useState(true);

  const fetchProductsList = (page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }

    dispatch(
      getProductsList({
        organizationId: userOrganization.id,
        merge,
        params: {
          page: page,
          size: 20,
          lifecycle: 'ACTIVE',
          include_ingredients: true,
          include_allergens: true,
          include_labels: true,
          include_devices: true,
          search: debouncedSearch,
          ...filterRef.current,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchProductsList(pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    fetchProductsList(0, false, debouncedSearch, true);
  }, [userOrganization, debouncedSearch]);

  useEffect(() => {
    const updatedFilter = createFilterUrl(filters);

    if (!isEquals(updatedFilter, filterRef.current)) {
      filterRef.current = updatedFilter;
      fetchProductsList(0, false, debouncedSearch, true);
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(setProductsList([]));
    };
  }, []);

  const renderTableHeader = headers => {
    return (
      <div className="border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <ProductsListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (products || []).length > 0 ? (
        <Fragment>
          {renderTableHeader(productsTableHeaders)}
          <InfiniteScrollComponent
            dataLength={products.length}
            hasMore={products?.length < total_elements}
            height={48}
            fetchMoreData={fetchMoreData}>
            {(products || []).map(product => (
              <ProductsListItem product={product} key={product?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : debouncedSearch ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </ProductsListWrapper>
  );
};

export const ProductsListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(150px, 180px) 60px 100px minmax(360px, 1fr) 80px
      40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default ProductsList;
