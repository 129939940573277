import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';

export const storageSlice = createSlice({
  name: 'storage',
  initialState: {
    storageList: [],
    storageDetails: {},
    storageDevices: [],
  },
  reducers: {
    setStorageList: (state, { payload }) => {
      state.storageList = payload;
    },
    setStorageDetails: (state, { payload }) => {
      state.storageDetails = payload;
    },
    setStorageDevices: (state, { payload }) => {
      state.storageDevices = payload;
    },
  },
});

export const getStorageList =
  ({ params, forFetchOnly = true }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage`, {
        params: params,
        paramsSerializer: {
          indexes: null,
        },
      });
      if (!forFetchOnly) {
        dispatch(setStorageList(data));
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStorageDetails =
  ({ storage_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage/${storage_id}`);
      dispatch(setStorageDetails(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getStorageDevices =
  ({ storage_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.get(`/api/storage/${storage_id}/devices`);
      dispatch(setStorageDevices(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const createStorage =
  ({ request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const updateStorage =
  ({ storage_id, request, updateDetails = false }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.put(`/api/storage/${storage_id}`, request);
      if (updateDetails) {
        dispatch(setStorageDetails(data));
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteStorage =
  ({ storage_id }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/storage/${storage_id}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const addDeviceToStorage =
  ({ storage_id, request }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.post(`/api/storage/${storage_id}/devices`, request);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const removeStorageDevice =
  ({ storage_id, deviceId }) =>
  async dispatch => {
    try {
      const { data } = await apiV3.delete(`/api/storage/${storage_id}/devices/${deviceId}`);
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setStorageList, setStorageDetails, setStorageDevices } = storageSlice.actions;
export default storageSlice.reducer;
