import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/images/cross.svg';
import { useWindowSize } from '../../../hooks/useWindowSize';
import Button from '../../Elements/button/button';
import FilterItem from './filter-item';
import { MultipleFilterStyledWrapper } from './multiple-filter.styled';

const MultipleFilter = ({ filters = [{}], setFilters, filterFor, className = '', multiFilterPlacement }) => {
  const filterItemRef = useRef();
  const filterEndItemRef = useRef();
  const { height } = useWindowSize();
  const [isScroll, setScroll] = useState(true);

  const handleScroll = () => {
    setScroll(filterItemRef?.current?.clientHeight < height - 240 ? false : true);
  };

  useEffect(() => {
    handleScroll();
  }, [height, filterItemRef?.current?.clientHeight, filters]);

  const scrollToBottom = () => {
    filterEndItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (isScroll) {
      scrollToBottom();
    }
  }, [filters, isScroll]);

  return (
    <MultipleFilterStyledWrapper className={classNames('flex-column card absolute', multiFilterPlacement, className)}>
      <div className="flex items-center justify-between w-full pxy-4">
        <label className="semibold-text font-16">Filter</label>
        <div className="flex items-center justify-center cursor clear-all no-select" onClick={() => setFilters([{}])}>
          <label className="medium-text error-text">Clear</label>
        </div>
      </div>
      <div
        className="filter-item-container"
        style={{ maxHeight: `${height - 240}px`, overflowY: `${isScroll ? 'auto' : 'unset'}` }}
        ref={filterItemRef}>
        {filters.length > 0 &&
          filters.map((data, index) => (
            <div ref={filterEndItemRef}>
              <FilterItem
                filterFor={filterFor}
                index={index}
                key={data.id}
                filter={data}
                setFilter={uf => {
                  const updatedFilterList = filters.map(item =>
                    item.id === data.id ? { ...item, ...uf } : { ...item },
                  );
                  setFilters([...updatedFilterList]);
                }}
                removeFilter={rmf => {
                  const updatedFilters = filters.filter(item => item.id !== rmf.id);
                  setFilters(updatedFilters.length === 0 ? [{ id: nanoid() }] : [...updatedFilters]);
                }}
              />
            </div>
          ))}
      </div>
      <div className="flex pxy-4 items-center justify-center w-full border-top">
        <Button
          className={'additional'}
          label={'Add filter'}
          borderRadius={'10px'}
          size={'tiny'}
          icon={<PlusIcon className="primary-text" />}
          onClick={() => {
            setFilters([...filters, { id: nanoid() }]);
          }}
        />
      </div>
    </MultipleFilterStyledWrapper>
  );
};

export default MultipleFilter;
