import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as FridgeIcon } from '../../assets/icons/fridge.svg';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { lowercase } from '../../helpers/utils';
import { getStorageList } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';

export const VenueStorage = ({
  storage = {},
  venueDetails = {},
  onDeleteSuccess = () => {},
  onEditSuccess = () => {},
}) => {
  const { hasPermission, setModal } = useContext(OrganisationContext);

  const { id: storageId, name, expiry } = storage;

  const onDeleteStorage = () => {
    setModal({
      type: 'delete-storage',
      content: {
        storage: storage,
        onSuccess: onDeleteSuccess,
      },
    });
  };

  const onEditStorage = () => {
    setModal({
      type: 'edit-storage',
      content: {
        storage: storage,
        venueDetails: venueDetails,
        isEdit: true,
        onSuccess: onEditSuccess,
      },
    });
  };

  const deviceItemMenu = [
    { name: 'Edit', onClick: onEditStorage, permission: 'STORAGE_MANAGE' },
    { name: 'Delete', onClick: onDeleteStorage, permission: 'STORAGE_MANAGE' },
  ];
  const filteredMenuList = deviceItemMenu.filter(m => !m.permission || hasPermission(m.permission));

  const getUseBy = expiry => {
    const { units, period } = expiry;
    if (units && period) {
      return `${units} ${lowercase(period)?.[0]}`;
    }
    return '-';
  };

  return (
    <VenueStorageItemWrapper className="flex items-center border radius-3 pxy-2" key={storageId}>
      <FridgeIcon />
      <label className="semibold-text flex-1 one-line ml-4">{name}</label>
      <label className="regular-text main-grey-text font-12 mx-1">Use by: {getUseBy(expiry)}</label>
      {filteredMenuList.length > 0 && <Menu menuClassName="ml-2 storage-menu" menuList={filteredMenuList} />}
    </VenueStorageItemWrapper>
  );
};

const VenueStorages = ({ search = '', addedData = {} }) => {
  const dispatch = useDispatch();

  const { venueDetails } = useSelector(state => state.accounts);

  const [loading, setLoading] = useState(true);
  const [storageList, setStorageList] = useState([]);

  const fetchStorageList = (location_id, search) => {
    setLoading(true);
    dispatch(
      getStorageList({
        params: { search, location_id },
      }),
    )
      .then(data => setStorageList(data))
      .catch(() => dispatch(addToast({ error: true, text: 'Error while fetching storages', id: nanoid() })))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchStorageList(venueDetails.id, search);
  }, [venueDetails?.id, search, addedData.id]);

  return (
    <VenueStoragesWrapper className="flex-1 flex mt-6">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <Fragment>
          {storageList.length > 0 ? (
            <div className="flex-1 device-container">
              {storageList.map(storage => (
                <VenueStorage
                  storage={storage}
                  key={storage?.id}
                  venueDetails={venueDetails}
                  onDeleteSuccess={() => fetchStorageList(venueDetails.id, search)}
                  onEditSuccess={() => fetchStorageList(venueDetails.id, search)}
                />
              ))}
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center">
              <label className="regular-text font-16 main-grey-text">No storages available</label>
            </div>
          )}
        </Fragment>
      )}
    </VenueStoragesWrapper>
  );
};

const VenueStoragesWrapper = styled.div`
  .device-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    height: fit-content;
  }
`;

const VenueStorageItemWrapper = styled.div`
  height: 40px;

  .storage-menu {
    .menu-btn {
      height: 24px;
      width: 24px;
      border-radius: 8px;
    }
    .menu-icon {
      height: 16px;
      width: 16px;
    }
  }
`;

export default VenueStorages;
