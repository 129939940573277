import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const automationsSlice = createSlice({
  name: 'automations',
  initialState: {
    automationsList: [],
    userList: [],
    automationConfigLoading: false,
  },
  reducers: {
    setAutomationsList: (state, { payload }) => {
      state.automationsList = payload;
    },
    setAutomationDetails: (state, { payload }) => {
      state.automationDetails = payload;
    },
    setUserList: (state, { payload }) => {
      state.userList = payload;
    },
    setAutomationConfigLoading: (state, { payload }) => {
      state.automationConfigLoading = payload;
    },
  },
});

export const getAutomationsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/automations`, { params: payload?.params });
    dispatch(setAutomationsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAutomationDetails = payload => async dispatch => {
  try {
    dispatch(setAutomationConfigLoading(true));
    const { data } = await api.get(`/automations/${payload.automationID}`);
    dispatch(setAutomationDetails(data));
    dispatch(setAutomationConfigLoading(false));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getUsersList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/users`, { params: payload.params });
    dispatch(setUserList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationDetails = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}`, request);
    dispatch(getAutomationDetails({ automationID: automationID }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAutomationStatus = payload => async dispatch => {
  try {
    const { automationID, request } = payload;
    const { data } = await api.put(`/automations/${automationID}/state`, request);
    dispatch(getAutomationDetails({ automationID: automationID }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAutomation = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/automations`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteAutomation = payload => async dispatch => {
  try {
    const { automationID } = payload;
    const { data } = await api.delete(`/automations/${automationID}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setAutomationsList, setAutomationDetails, setUserList, setAutomationConfigLoading } =
  automationsSlice.actions;
export default automationsSlice.reducer;
