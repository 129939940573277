import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as FridgeIcon } from '../../assets/icons/fridge.svg';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { lowercase } from '../../helpers/utils';
import { deleteStorageRules } from '../../store/features/storageGroupsSlice';
import { deleteStorage } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const DeleteStorage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);

  const [loading, setLoading] = useState(false);
  const { storage, onSuccess = () => {}, isStorageRules } = modal.content;

  const { id: storageId, name, expiry } = storage;

  const onConfirmAction = async () => {
    setLoading(true);
    if (isStorageRules) {
      dispatch(deleteStorageRules({ storage_id: storageId }))
        .then(() => {
          dispatch(addToast({ error: false, text: t('STORAGE_RULES_DELETE'), id: nanoid() }));
          onSuccess();
          setModal(initModal);
        })
        .catch(error => {
          dispatch(addToast({ error: true, text: t('ERROR_STORAGE_RULES_DELETE'), id: nanoid() }));
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      dispatch(deleteStorage({ storage_id: storageId }))
        .then(() => {
          dispatch(addToast({ error: false, text: t('STORAGE_DELETE'), id: nanoid() }));
          onSuccess();
          setModal(initModal);
        })
        .catch(error => {
          dispatch(addToast({ error: true, text: t('ERROR_STORAGE_DELETE'), id: nanoid() }));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getUseBy = expiry => {
    const { units, period } = expiry;
    if (units && period) {
      return `${units} ${lowercase(period)?.[0]}`;
    }
    return '-';
  };

  return (
    <CommonPopup
      popupTitle={'Are you sure you want to delete this storage?'}
      confirmButtonProps={{
        label: 'Delete',
        className: 'negative',
      }}
      cancelButtonProps={{ label: 'Cancel' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <DeleteStorageWrapper className="flex items-center pxy-2 w-full radius-2 mt-6">
        <FridgeIcon />
        <label className="semibold-text flex-1 one-line ml-4">{name}</label>
        <label className="regular-text main-grey-text font-12 mx-2">Use by: {getUseBy(expiry)}</label>
      </DeleteStorageWrapper>
    </CommonPopup>
  );
};

const DeleteStorageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.light_grey};
`;

export default DeleteStorage;
