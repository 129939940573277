import parse from 'html-react-parser';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { convertMarkupToHtml, createHtmlPrepLabel } from '../../helpers/preview-converter';
import { isEditorValueEmpty, uppercase } from '../../helpers/utils';
import { getAllergens, getProductAllergens, getProductLabels } from '../../store/features/productsSlice';
import Dropdown from '../common/dropdown';
import Editor from '../common/editor';
import CustomTooltip from '../common/tooltip-new';
import AllergensSelector from './allergens-selector';
import LabelPreview from './label-preview';

const heightOptions = [
  { label: '80 mm', value: '80mm' },
  { label: '40 mm', value: '40mm' },
];

const ProductAllergens = ({
  value,
  setValue,
  labelData,
  setLabelData,
  inEditState,
  mayContain,
  setMayContain,
  customAllergens,
  setCustomAllergens,
  allergensList,
  setAllergensList,
  selectedProductAllergens,
  setSelectedProductAllergens,
  setInitialSelectedProductAllergens,
  refreshProductData,
}) => {
  const dispatch = useDispatch();
  const { hasPermission } = useContext(OrganisationContext);

  const { productId } = useParams();
  const { productDetails } = useSelector(state => state.products);

  const [loading, setLoading] = useState(true);
  const [previewSettings, setPreviewSettings] = useState({
    height: '40mm',
    // expiry_date: moment().add(3, 'day').format('DD/MM/YY'),
    expiry_date: '11/11/11',
    batch_code: 111,
    include_weekday: true,
  });
  const [openAllergensAdd, setOpenAllergensAdd] = useState(false);

  const editorRef = useRef(null);

  useEffect(() => {
    if (productId) {
      dispatch(getProductLabels({ productId }))
        .then(data => {
          const firstLabelData = data?.[0] || {};
          setLabelData(firstLabelData);
          setValue(convertMarkupToHtml(firstLabelData.label_text?.allergens || ''));
          setMayContain(firstLabelData.label_text?.may_contain);
          setCustomAllergens(firstLabelData.label_text?.custom_allergens);
        })
        .catch(() => {
          setLabelData({});
          setValue('');
          setMayContain('');
          setCustomAllergens('');
        })
        .finally(() => setLoading(false));
      dispatch(getProductAllergens({ productId }))
        .then(data => {
          const allergensData = data?.map(a => ({ ...a, ...a.allergen }));
          setSelectedProductAllergens(allergensData);
          setInitialSelectedProductAllergens(allergensData);
        })
        .catch(() => {
          setSelectedProductAllergens([]);
        });
    }
  }, [productId, refreshProductData]);

  useEffect(() => {
    dispatch(getAllergens())
      .then(data => {
        const allergensData = data?.map(a => ({ ...a, ...a.allergen }));
        setAllergensList(allergensData);
      })
      .catch(() => {
        setAllergensList([]);
      });
  }, []);

  return (
    <ProductAllergensWrapper className="flex-column flex-1">
      {loading ? (
        <div className="item flex items-center justify-center flex-1 my-4">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <Fragment>
          {hasPermission('LABELS_MANAGE') && (
            // TODO: Refactor
            <Fragment>
              {inEditState ? (
                <Fragment>
                  <Editor
                    value={value}
                    setValue={setValue}
                    placeholder="Use +/- Button to Add/Remove Allergens"
                    disableInput={true}
                    showPlus
                    onPlus={quillRef => {
                      editorRef.current = quillRef;
                      setOpenAllergensAdd(!openAllergensAdd);
                    }}
                  />
                  <CustomTooltip
                    tooltipId="plus-selector"
                    clickable
                    place="bottom"
                    content={
                      <AllergensSelector
                        allergens={allergensList}
                        selectedAllergens={selectedProductAllergens}
                        onAdd={selectedAllergens => {
                          const allergensText = selectedAllergens?.map(al => al.name).join(', ');
                          editorRef.current.getEditor().setText(allergensText);
                          setSelectedProductAllergens(selectedAllergens);
                        }}
                      />
                    }
                    isOpen={openAllergensAdd}
                    onBlurTooltip={() => setOpenAllergensAdd(false)}
                  />
                  <div className="flex-column">
                    <label className="regular-text main-grey-text font-12 flex-1 mb-2">Custom Allergens</label>
                    <input
                      className="input radius-3 mb-8 may-contain-input"
                      placeholder={'Custom contain'}
                      value={customAllergens}
                      onChange={({ target: { value } }) => setCustomAllergens(uppercase(value))}
                    />
                  </div>
                  <div className="flex-column">
                    <label className="regular-text main-grey-text font-12 flex-1 mb-2">May contain</label>
                    <input
                      className="input radius-3 mb-12 may-contain-input"
                      placeholder={'May contain'}
                      value={mayContain}
                      onChange={({ target: { value } }) => setMayContain(uppercase(value))}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="px-4 py-3 radius-2 mb-12 label-container">
                    {!isEditorValueEmpty(value) || labelData?.label_text?.allergens ? (
                      <label className="regular-text font-16">
                        {parse(convertMarkupToHtml(value || labelData?.label_text?.allergens))}
                      </label>
                    ) : (
                      <label className="medium-text main-grey-text">No allergence added</label>
                    )}
                  </div>
                  <div className="flex-column">
                    <label className="bold-text mb-4">CUSTOM ALLERGENS</label>
                    <div className="px-4 py-3 radius-2 mb-12  label-container">
                      {customAllergens || labelData?.label_text?.custom_allergens ? (
                        <label className="regular-text font-16">
                          {customAllergens || labelData?.label_text?.custom_allergens}
                        </label>
                      ) : (
                        <label className="medium-text main-grey-text">No data added</label>
                      )}
                    </div>
                  </div>
                  <div className="flex-column">
                    <label className="bold-text mb-4">MAY CONTAIN</label>
                    <div className="px-4 py-3 radius-2 mb-12  label-container">
                      {mayContain || labelData?.label_text?.may_contain ? (
                        <label className="regular-text font-16">
                          {mayContain || labelData?.label_text?.may_contain}
                        </label>
                      ) : (
                        <label className="medium-text main-grey-text">No data added</label>
                      )}
                    </div>
                  </div>
                </Fragment>
              )}
            </Fragment>
          )}
          <div className="flex col-gap-12">
            <LabelPreview
              previewSettings={previewSettings}
              setPreviewSettings={setPreviewSettings}
              isDataAvailable={!isEditorValueEmpty(value) || labelData?.label_text?.allergens}
              noDataLabel="No allergens added"
              previewText={createHtmlPrepLabel(
                {
                  ...productDetails,
                  allergens_display: value || labelData?.label_text?.allergens,
                  use_by_date: previewSettings.date,
                  may_contain: mayContain,
                  barcode_number: labelData?.barcode || '111111',
                  custom_allergens: customAllergens,
                },
                previewSettings.batch_code,
                previewSettings.include_weekday,
                previewSettings.expiry_date,
              )}
            />
            {/* TODO: Refactor if possible */}
            <div className="flex-column row-gap-4 mt-8 settings-container">
              <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1 w-full">Height</label>
                <Dropdown
                  className="custom-dropdown"
                  options={heightOptions}
                  value={heightOptions.find(height => height.value === previewSettings.height)}
                  onChange={option => setPreviewSettings({ ...previewSettings, height: option.value })}
                />
              </div>
              {/* <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1">Expiry date</label>
                <input
                  className="input radius-2 custom-input"
                  placeholder={'Expiry date'}
                  value={previewSettings.expiry_date}
                  onChange={({ target: { value } }) => setPreviewSettings({ ...previewSettings, expiry_date: value })}
                />
              </div>
              <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1">Batch code</label>
                <input
                  className="input radius-2 custom-input"
                  placeholder={'Batch'}
                  value={previewSettings.batch_code}
                  onChange={({ target: { value } }) => setPreviewSettings({ ...previewSettings, batch_code: value })}
                />
              </div>
              <div className="flex items-center col-gap-8 justify-between">
                <label className="regular-text flex-1">Include weekday</label>
                <Switch
                  onClick={() =>
                    setPreviewSettings({
                      ...previewSettings,
                      include_weekday: !previewSettings.include_weekday,
                    })
                  }
                  enabled={previewSettings.include_weekday}
                />
              </div> */}
            </div>
          </div>
        </Fragment>
      )}
    </ProductAllergensWrapper>
  );
};

export const ProductAllergensWrapper = styled.div`
  .settings-container {
    min-width: 200px;
  }

  .label-container {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .custom-dropdown,
  .custom-input {
    height: 32px;
    font-size: 14px;
    width: 130px;
  }

  .may-contain-input {
    height: 40px;
  }
`;

export default ProductAllergens;
