import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { formatText, getFormattedDate } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { updateDevice } from '../../store/features/deviceSlice';
import Menu from '../common/menu';
import Switch from '../common/switch';

const StorageGroupDetailsUI = ({ deviceDetails, onToggleRequiredPin = () => {} }) => {
  const { t } = useTranslation();
  const { name, created_on, subscription, requires_profile_pin } = deviceDetails || {};
  const { recurring, integration } = subscription || {};

  return (
    <Fragment>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('NAME')}</label>
        <label className="regular-text font-16 one-line">{name}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('CREATE_ON')}</label>
        <label className="regular-text font-16 one-line"> {getFormattedDate(created_on, 'DD/MM/YYYY')}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('BILLING_PREIOD')}</label>
        <label className="regular-text font-16 one-line text-capitalize">{formatText(recurring?.interval)}</label>
      </div>
      <div className="flex-column row-gap-2">
        <label className="regular-text main-grey-text font-12">{t('SUBSCRIPTION_ID')}</label>
        <label className="regular-text font-16 one-line">{integration?.external_reference}</label>
      </div>
      <div className="flex-column row-gap-2">
        <div className="flex items-center justify-between">
          <span className={`regular-text font-16`}>{t('REQUIRE_PROFILE_PINS')}</span>
          <Switch
            onClick={() => {
              onToggleRequiredPin(!requires_profile_pin);
            }}
            className="pr-3"
            enabled={requires_profile_pin}
            disabled={false}
          />
        </div>
      </div>
    </Fragment>
  );
};

const DeviceDetailsLeft = ({ fetchDeviceDetails = () => {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setModal } = useContext(OrganisationContext);
  const { deviceDetails } = useSelector(state => state.device);
  const { id: devicesId, name, serial_number, mac_address, organisation } = deviceDetails || {};

  const [deviceInfo, setDeviceInfo] = useState({});

  const onUpdatePin = () => {
    setModal({
      type: 'update-device-pin',
      content: { device: deviceDetails, onSuccess: fetchDeviceDetails },
    });
  };

  const onSetLocation = () => {
    setModal({
      type: 'update-device-location',
      content: {
        device: deviceDetails,
        popupTitle: 'Set location',
        onSuccess: fetchDeviceDetails,
      },
    });
  };

  const onDeviceRequirePinUpdate = updatedDevice => {
    setDeviceInfo(updatedDevice);
  };

  const onToggleRequiredPin = updatedRequiredPin => {
    const deviceUpdateRequest = {
      name,
      organisation: { id: organisation.id },
      serial_number,
      mac_address,
      requires_profile_pin: updatedRequiredPin,
    };
    onDeviceRequirePinUpdate({ ...deviceInfo, requires_profile_pin: updatedRequiredPin });
    dispatch(updateDevice({ device_id: devicesId, request: deviceUpdateRequest })).catch(() => {
      onDeviceRequirePinUpdate(deviceInfo);
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating device',
          id: nanoid(),
        }),
      );
    });
  };

  useEffect(() => {
    if (deviceDetails) {
      setDeviceInfo(deviceDetails);
    }
  }, [deviceDetails]);

  return (
    <DeviceDetailsLeftWrapper className="overflow-scroll">
      <div className="radius-4 card flex-column">
        <div className="border-bottom details-header flex  items-center pxy-6">
          <label className="bold-text font-24 flex-1">{t('DEVICE_INFO')}</label>
          <Menu
            menuList={useFilteredMenuList({
              menuList: [
                { name: 'Update pin', onClick: onUpdatePin, permission: 'DEVICES_MANAGE', key: 'UPDATE_PIN' },
                { name: 'Set location', onClick: onSetLocation, permission: 'DEVICES_MANAGE', key: 'SET_LOCATION' },
              ],
            })}
          />
        </div>
        <div className="details-container pxy-6 flex-column row-gap-4 overflow-scroll">
          <StorageGroupDetailsUI deviceDetails={deviceInfo} onToggleRequiredPin={onToggleRequiredPin} />
        </div>
      </div>
    </DeviceDetailsLeftWrapper>
  );
};

export const DeviceDetailsLeftWrapper = styled.div`
  width: 350px;
  .menu-btn {
    width: 16px;
    height: 16px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
`;

export default DeviceDetailsLeft;
