import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as StorgeIcon } from '../../assets/images/storage.svg';
import { ReactComponent as UserIcon } from '../../assets/images/users.svg';
import Box from '../../components/common/Box';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import { SettingsWrapper } from '../../styles/pages/settings.styled';

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { hasPermission } = useContext(OrganisationContext);

  return (
    <SettingsWrapper className="flex-column items-start justify-start flex-1">
      <PageHeader title={t('SETTINGS')} className="w-full" />
      <div className="flex w-full mx-2 gap-6">
        <div className="flex items-center justify-start w-full mt-6 card">
          <Box
            title={t('SECURITY')}
            icon={<UserIcon height={24} width={24} className="icon-fill" />}
            subTitle={t('CONFIGURE_YOUR_SECURITY')}
            onClick={() => navigate(`${location.pathname}/security`)}
          />
        </div>
        {hasPermission('STORAGE_RULE_GROUPS_VIEW') && (
          <div className="flex items-center justify-start w-full mt-6 card">
            <Box
              title={t('STORAGE_RULES')}
              icon={<StorgeIcon height={24} width={24} className="icon-fill" />}
              subTitle={t('CREATE_STORAGE_SETTINGS')}
              onClick={() => navigate(`${location.pathname}/storages-rules`)}
            />
          </div>
        )}
      </div>
    </SettingsWrapper>
  );
};

export default Settings;
