import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as DeviceIcon } from '../../assets/images/device.svg';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getDateMoment, getFormattedDate } from '../../helpers/utils';
import { useFilteredMenuList } from '../../hooks/useFilteredMenuList';
import { getDeviceList } from '../../store/features/categorySlice';
import { updateDevice } from '../../store/features/deviceSlice';
import { addToast } from '../../store/features/toastSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import Menu from '../common/menu';
import Switch from '../common/switch';

const VenueDevice = ({ device = {}, onDeviceRequirePinUpdate = () => {}, refreshDeviceList = () => {} }) => {
  const dispatch = useDispatch();

  const { setModal } = useContext(OrganisationContext);

  const {
    id: deviceId,
    organisation,
    name,
    serial_number,
    mac_address,
    created_on,
    subscription,
    device_code,
    requires_profile_pin,
  } = device;
  const { current_period, integration } = subscription || {};

  const onUpdatePin = () => {
    setModal({
      type: 'update-device-pin',
      content: { device },
    });
  };

  const onSetLocation = () => {
    setModal({
      type: 'update-device-location',
      content: {
        device: device,
        popupTitle: 'Change location',
        onSuccess: refreshDeviceList,
      },
    });
  };

  const filteredMenuList = useFilteredMenuList({
    menuList: [
      { name: 'Update pin', onClick: onUpdatePin, permission: 'DEVICES_MANAGE' },
      { name: 'Change location', onClick: onSetLocation, permission: 'DEVICES_MANAGE' },
    ],
  });

  const getBillingPeriod = (start_date, end_date) => {
    if (start_date && end_date) {
      const startDateMoment = getDateMoment(start_date);
      const endDateMoment = getDateMoment(end_date);
      const isSameYear = startDateMoment.get('year') === endDateMoment.get('year');

      const startDateString = startDateMoment.format(isSameYear ? 'DD MMM' : 'DD MMM, YYYY');
      const endDateString = endDateMoment.format('DD MMM, YYYY');

      return `${startDateString} to ${endDateString}`;
    }
    return '-';
  };

  const onToggleRequiredPin = updatedRequiredPin => {
    const deviceUpdateRequest = {
      name,
      organisation: { id: organisation.id },
      serial_number,
      mac_address,
      requires_profile_pin: updatedRequiredPin,
    };
    onDeviceRequirePinUpdate({ ...device, requires_profile_pin: updatedRequiredPin });
    dispatch(updateDevice({ device_id: deviceId, request: deviceUpdateRequest })).catch(() => {
      onDeviceRequirePinUpdate(device);
      dispatch(
        addToast({
          error: true,
          text: 'Error while updating device',
          id: nanoid(),
        }),
      );
    });
  };

  return (
    <VenuDeviceItemWrapper className="border radius-3 pxy-4 col-gap-4" key={deviceId}>
      <DeviceIcon className="flex" />
      <div className="flex-column row-gap-2">
        <label className="bold-text font-16 one-line">{name}</label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Created on:</span>
          {created_on ? getFormattedDate(created_on, 'DD/MM/YYYY') : '-'}
        </label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Billing period:</span>
          {getBillingPeriod(current_period?.start_date, current_period?.end_date)}
        </label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Subscription ID:</span>
          {integration?.external_reference || '-'}
        </label>
        {/* <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Mac address:</span>
          {mac_address}
        </label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Serial number:</span>
          {serial_number}
        </label> */}
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">7-digit code:</span>
          {device_code}
        </label>
        <div className="flex items-center justify-between">
          <label className="regular-text font-12 one-line">Require profile pins</label>
          <Switch
            variant={'small'}
            enabled={requires_profile_pin}
            onClick={() => onToggleRequiredPin(!requires_profile_pin)}
          />
        </div>
      </div>
      {filteredMenuList.length > 0 && <Menu menuList={filteredMenuList} />}
    </VenuDeviceItemWrapper>
  );
};

const VenueDevices = ({ search = '' }) => {
  const dispatch = useDispatch();

  const { venueDetails } = useSelector(state => state.accounts);

  const pageRef = useRef(0);

  const [loading, setLoading] = useState(true);
  const [deviceList, setDeviceList] = useState([]);
  const [pageInfo, setPageInfo] = useState({});

  const fetchDeviceList = (page, merge, venue_id) => {
    setLoading(true);
    dispatch(
      getDeviceList({
        params: { page, search: search, size: 20, organization_id: venue_id },
      }),
    )
      .then(data => {
        const { content, ...restPage } = data;
        const devices = merge ? [...deviceList, ...content] : content;
        setDeviceList(devices);
        setPageInfo(restPage);
        pageRef.current = page;
      })
      .catch(() => dispatch(addToast({ error: true, text: 'Error while fetching devices', id: nanoid() })))
      .finally(() => setLoading(false));
  };

  const fetchMoreDevices = () => {
    if (venueDetails?.id) {
      fetchDeviceList(pageRef.current + 1, true, venueDetails?.id);
    }
  };

  const onDeviceRequirePinUpdate = updatedDevice => {
    const updatedDeviceList = deviceList.map(device => (device.id === updatedDevice.id ? updatedDevice : device));
    setDeviceList(updatedDeviceList);
  };

  useEffect(() => {
    if (venueDetails?.id) {
      fetchDeviceList(0, false, venueDetails?.id);
    }
  }, [venueDetails?.id, search]);

  const refreshDeviceList = () => {
    if (venueDetails?.id) {
      fetchDeviceList(0, false, venueDetails?.id);
    }
  };

  return (
    <VenueDevicesWrapper className="flex-1 flex mt-6">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (
        <Fragment>
          {deviceList.length > 0 ? (
            <div className="flex-1">
              <InfiniteScrollComponent
                infiniteScrollClassName="device-container"
                dataLength={deviceList.length}
                hasMore={!pageInfo.last}
                fetchMoreData={fetchMoreDevices}>
                {deviceList.map(device => (
                  <VenueDevice
                    device={device}
                    key={device?.id}
                    onDeviceRequirePinUpdate={onDeviceRequirePinUpdate}
                    refreshDeviceList={refreshDeviceList}
                  />
                ))}
              </InfiniteScrollComponent>
            </div>
          ) : (
            <div className="flex flex-1 items-center justify-center">
              <label className="regular-text font-16 main-grey-text">No devices available</label>
            </div>
          )}
        </Fragment>
      )}
    </VenueDevicesWrapper>
  );
};

const VenueDevicesWrapper = styled.div`
  .device-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
`;

const VenuDeviceItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 44px minmax(100px, 1fr) 32px;
`;

export default VenueDevices;
