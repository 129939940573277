import React from 'react';
import styled from 'styled-components';
import { ReactComponent as RightIcon } from '../../assets/icons/arrows/right.svg';
import { ReactComponent as PlusRoundIcon } from '../../assets/images/plus-round.svg';
import Button from '../Elements/button/button';
import InputSearch from '../common/input-search';

const CategoryTagDetails = ({
  isDisplayButton = true,
  title,
  searchText,
  setSearchText,
  setIsOpen,
  isOpen,
  onAddClick = () => {},
}) => {
  return (
    <CategoryTagDetailsWrapper className="flex items-center pxy-4">
      <label className="semibold-text font-20 flex flex-1">{title}</label>
      <InputSearch placeholder={'Search'} value={searchText} onChange={setSearchText} className="ml-6 input-search" />
      {isDisplayButton && (
        <Button
          fontSize="14px"
          size="small"
          width="110px"
          borderRadius="12px"
          className={`primary specified-width ml-6`}
          icon={<PlusRoundIcon className="white-text" height={16} width={16} />}
          label={'Add new'}
          onClick={onAddClick}
        />
      )}
      <div
        className={`pxy-1 flex items-center cursor ml-4 ${isOpen && 'rotate-90'}`}
        onClick={() => setIsOpen(!isOpen)}>
        <RightIcon />
      </div>
    </CategoryTagDetailsWrapper>
  );
};

export const CategoryTagDetailsWrapper = styled.div`
  .input {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-color: ${({ theme }) => theme.colors.backgroundColor};

    &:focus {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

export default CategoryTagDetails;
