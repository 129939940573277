export const timeMeridiemOptions = [
  { name: 'AM', value: 'AM' },
  { name: 'PM', value: 'PM' },
];

export const usageOptions = [
  { label: 'For Sale', value: 'SINGLE' },
  { label: 'For Prep', value: 'COMPONENT' },
];

export const durationUnitOptions = [
  { label: 'Hours', value: 'HOURS' },
  { label: 'Days', value: 'DAYS' },
];
