import classNames from 'classnames';
import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';

const PhoneInputUk = ({ phone, setPhone, className = '', phoneInputClassname = '', countryCodeClassname = '' }) => {
  return (
    <PhoneInputUkWrapper className={classNames('flex relative', className)}>
      <div className={classNames('country-code', countryCodeClassname)}>
        <span className="regular-text font-16">+44</span>
      </div>
      <MaskedInput
        mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
        value={phone || null}
        className={classNames('input w-full phone-input', phoneInputClassname)}
        placeholder={'0000 000 000'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputUkWrapper>
  );
};

const PhoneInputUkWrapper = styled('div')`
  border: 1px solid transparent;

  .country-code {
    position: absolute;
    height: 100%;
    left: 1px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    background-color: ${({ theme }) => theme.colors.backgroundColor2};
    border-radius: 12px 0 0 12px;
  }

  .phone-input {
    padding-left: 70px;
  }
`;

export default PhoneInputUk;
