import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/toast-close.svg';
import InfiniteScrollComponent from '../../components/common/infinite-scroll';
import InputSearch from '../../components/common/input-search';
import Loader from '../../components/common/loader';
import SearchableDropdown from '../../components/common/searchable-dropdown';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { getAccountCategoryTagInfo } from '../../store/features/accountsSlice';
import { addDeviceToCategory, getDeviceList } from '../../store/features/categorySlice';
import { addDeviceToStorage } from '../../store/features/storageSlice';
import { addToast } from '../../store/features/toastSlice';
import { addDeviceToUser } from '../../store/features/userManagementSlice';
import CommonPopup from '../common-popup';

const addDeviceApi = {
  CATEGORY: { api: addDeviceToCategory, idKey: 'category_id' },
  USER: { api: addDeviceToUser, idKey: 'user_id' },
  STORAGE: { api: addDeviceToStorage, idKey: 'storage_id' },
};

const DeviceItem = ({ device, onClick = () => {} }) => {
  const { id: deviceId, name, organisation, serial_number, mac_address } = device;

  return (
    <div className="border radius-3 pxy-4 col-gap-4 flex-1 device-item" onClick={() => onClick(device)}>
      <div className="flex-column row-gap-1 cursor">
        <label className="medium-text font-16 one-line mb-1">{name}</label>
        <label className="regular-text one-line">{organisation?.name}</label>
        {/* <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Mac address:</span>
          {mac_address}
        </label>
        <label className="regular-text font-12 one-line">
          <span className="main-grey-text mr-1">Serial number:</span>
          {serial_number}
        </label> */}
      </div>
    </div>
  );
};

const AddDevice = () => {
  const dispatch = useDispatch();
  const devicePageRef = useRef(null);

  const { userOrganization } = useSelector(state => state.user);
  const { modal, setModal } = useContext(OrganisationContext);

  const { key, idValue, addedDevices, onSuccess = () => {} } = modal.content;
  const addedDevicesIds = addedDevices?.map(d => d.id) || [];

  const [loading, setLoading] = useState(false);
  const [loadingDevices, setLoadingDevices] = useState(true);

  const [selectedVenue, setSelectedVenue] = useState(null);
  const [searchedVenue, setSearchedVenue] = useState(null);

  const [deviceList, setDeviceList] = useState([]);
  const [devicePageInfo, setDevicePageInfo] = useState({});
  const [searchedDevice, setSearchedDevice] = useState('');
  const [selectedDevices, setSelectedDevices] = useState([]);

  const debounchedSearchedDevice = useDebounce(searchedDevice, 500);
  const selectedDevicesIds = selectedDevices.map(s => s.id);

  const onSelectDevice = device => {
    if (!selectedDevicesIds.includes(device.id)) {
      setSelectedDevices([...selectedDevices, { ...device }]);
    }
  };

  const onConfirmAction = async () => {
    if (!selectedDevicesIds.length) {
      dispatch(addToast({ error: true, text: 'Please select one device', id: nanoid() }));
      return;
    }

    const request = selectedDevicesIds.map(id => ({ id }));
    const { api, idKey } = addDeviceApi[key];
    if (!idKey && !api) {
      return;
    }
    setLoading(true);
    dispatch(api({ [idKey]: idValue, request }))
      .then(() => {
        dispatch(addToast({ error: false, text: 'Device Added', id: nanoid() }));
        onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || 'Error while adding device';
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchVenuList = async (page, search = '') => {
    try {
      const venuList = await dispatch(
        getAccountCategoryTagInfo({
          organizationId: userOrganization.id,
          params: { page: page, search: search, category_tag: 'VENUE' },
        }),
      );
      return venuList;
    } catch (error) {
      return null;
    }
  };

  const fetchVenueOptions = async (search, _prevOptions, { page, merge }) => {
    const venueOptions = await fetchVenuList(page, search);
    const { content, ...restResponse } = venueOptions || {};
    const venueContent = venueOptions ? content : [];
    const changedOptions = venueContent.map(option => ({ ...option, label: option.name, value: option.id }));
    const options = page === 0 ? [{ label: 'All venues', value: '', id: '' }, ...changedOptions] : changedOptions;

    return {
      options: options,
      hasMore: !restResponse.last,
      additional: {
        page: page + 1,
        merge,
        hasMore: !restResponse.last,
      },
    };
  };

  const fetchDeviceList = (page, merge, search, venue = {}) => {
    dispatch(getDeviceList({ params: { page, search: search, size: 50, organization_id: venue?.id } }))
      .then(data => {
        const { content, ...restResponse } = data;
        const deviceContent = merge ? [...deviceList, ...content] : content;
        const filteredDevices = deviceContent.filter(d => !addedDevicesIds.includes(d.id));
        setDeviceList(filteredDevices);
        setDevicePageInfo(restResponse);
        devicePageRef.current = page;
      })
      .catch(() => dispatch(addToast({ error: true, text: 'Error while fetching devices', id: nanoid() })))
      .finally(() => setLoadingDevices(false));
  };

  const fetchMoreDevices = () => {
    fetchDeviceList(devicePageRef.current + 1, true, debounchedSearchedDevice, selectedVenue);
  };

  const getUnselectedDevices = () => {
    return deviceList.filter(d => !selectedDevicesIds.includes(d.id));
  };

  useEffect(() => {
    setLoadingDevices(true);
    fetchDeviceList(0, false, debounchedSearchedDevice, selectedVenue);
  }, [debounchedSearchedDevice, selectedVenue]);

  useEffect(() => {
    const unselectedDevicesLength = getUnselectedDevices().length;
    if (selectedDevices.length && unselectedDevicesLength < 1 && devicePageInfo && !devicePageInfo.last) {
      setLoadingDevices(true);
      fetchMoreDevices(devicePageRef.current + 1, true);
    }
  }, [selectedDevices]);

  return (
    <AddDeviceCommonWrapper>
      <CommonPopup
        modalClassName="common-popup"
        popupTitle={'Add device'}
        confirmButtonProps={{
          label: 'Add',
          className: 'primary specified-width',
          width: '170px',
        }}
        cancelButtonProps={{ label: 'Cancel', className: 'secondary specified-width', width: '170px' }}
        disabled={loading}
        onCancel={() => setModal(initModal)}
        onConfirm={onConfirmAction}>
        <AddDeviceWrapper className="flex mt-6 w-full flex-1 col-gap-10 overflow-hidden">
          <div className="flex-column flex-1 mx-1">
            <SearchableDropdown
              isClearable
              inputValue={searchedVenue}
              onInputChange={setSearchedVenue}
              loadOptions={fetchVenueOptions}
              className="w-full"
              placeholder="Select venue"
              name="Venue"
              value={selectedVenue}
              onChange={setSelectedVenue}
              isSearchable
              defaultAdditional={{ page: 0 }}
            />
            <InputSearch
              className="mt-4 mb-6 search-wrapper"
              value={searchedDevice}
              onChange={setSearchedDevice}
              placeholder={'Search'}
            />
            {getUnselectedDevices().length > 0 ? (
              <div className="flex-column flex-1 overflow-hidden">
                <InfiniteScrollComponent
                  dataLength={getUnselectedDevices().length}
                  hasMore={!devicePageInfo.last}
                  height={100}
                  fetchMoreData={fetchMoreDevices}>
                  <div className="flex-column row-gap-2 my-1">
                    {getUnselectedDevices().map(device => (
                      <DeviceItem device={device} onClick={() => onSelectDevice(device)} />
                    ))}
                  </div>
                </InfiniteScrollComponent>
              </div>
            ) : loadingDevices ? (
              <Loader height={24} width={24} />
            ) : (
              <label className="regular-text main-grey-text">No devices to select</label>
            )}
          </div>
          <div className="flex-column flex-1 pxy-4 row-gap-4 selected-devices">
            <label className="semibold-text font-16">Selected devices</label>
            {selectedDevices.length > 0 ? (
              <div className="flex-column row-gap-2 overflow-scroll">
                {selectedDevices.map(device => (
                  <div className="flex items-center col-gap-4">
                    <DeviceItem device={device} />
                    <CloseIcon
                      className="cursor"
                      onClick={() => setSelectedDevices(selectedDevices.filter(s => s.id !== device.id))}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <label className="regular-text main-grey-text">No selected devices</label>
            )}
          </div>
        </AddDeviceWrapper>
      </CommonPopup>
    </AddDeviceCommonWrapper>
  );
};

const AddDeviceCommonWrapper = styled.div`
  .common-popup {
    width: 776px;
    max-height: 780px;
    min-height: 500px;
  }
`;

const AddDeviceWrapper = styled.div`
  .search-wrapper {
    .input {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.backgroundColor};
      border-color: ${({ theme }) => theme.colors.backgroundColor};

      &:focus {
        background-color: ${({ theme }) => theme.colors.white};
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .selected-devices {
    background-color: ${({ theme }) => theme.colors.backgroundColor};

    .device-item {
      background-color: white;
    }
  }
`;

export default AddDevice;
