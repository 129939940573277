import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import Item from './item';
import { FilterStyledWrapper } from './filter.styled.js';

const Filter = ({ objects, values, setFilter }) => {
  const [filters, setFilters] = useState(values || { id: 0 });

  useEffect(() => {
    setFilter({
      ...filters,
    });
  }, [filters, setFilter]);

  return (
    <FilterStyledWrapper className="flex-column card filter-menu absolute">
      <div className="flex items-center justify-between pxy-4">
        <label className="normal-text">Filter</label>
        <div
          className="flex items-center justify-center cursor clear-all no-select"
          onClick={() => setFilters({ id: uuid() })}>
          <label className="normal-text px-2" style={{ color: '#FF406E' }}>
            Clear
          </label>
        </div>
      </div>
      <div>
        <Item
          filter={{ ...filters, objects: objects }}
          setFilter={f => setFilters(f)}
          removeFilter={f => setFilters({ id: uuid() })}
        />
      </div>
    </FilterStyledWrapper>
  );
};

export default Filter;
