import classNames from 'classnames';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LinkIcon } from '../../assets/icons/arrows/link.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/arrows/menu.svg';
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/menu/logout.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/menu/user.svg';
import { ReactComponent as LogoWhite } from '../../assets/images/logo-white.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { setUser } from '../../store/features/userSlice';
import { SidebarWrapper } from '../../styles/components/sidebar/sidebar.styled';
import MenuItem from './menu-item';

const Sidebar = ({ menuItems, onItemClick, selectedPath }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hasPermission } = useContext(OrganisationContext);
  const { userDetails, userOrganization, user } = useSelector(state => state.user);
  const { category } = userOrganization;
  const { tag } = category?.tag || {};
  const accountTitle = tag === 'BRAND' ? 'My Brand' : tag === 'VENUE' ? 'My Venue' : 'My Account';

  const [expanded, setExpanded] = useState(true);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const logout = () => {
    localStorage.removeItem('user');
    navigate('/login', { replace: true });
  };

  const goToProfile = () => {
    navigate('/my-account');
    setShowUserMenu(false);
  };

  const items = useMemo(
    () => [
      {
        id: 0,
        text: accountTitle,
        Icon: UserIcon,
        IconGreen: UserIcon,
        action: goToProfile,
        path: '/my-account',
        hasPermission:
          userOrganization.organization_type === 'ACCOUNT' ||
          (userOrganization.organization_type === 'VENUE' && hasPermission('ORGANIZATIONS_VIEW')),
      },
      {
        id: 1,
        text: 'Log Out',
        Icon: LogoutIcon,
        IconGreen: LogoutIcon,
        action: logout,
        hasPermission: true,
        borderBottom: true,
      },
    ],
    [userOrganization],
  );

  const [userMenuItems, setUserMenuItems] = useState(items.filter(item => item.hasPermission));

  useEffect(() => {
    setUserMenuItems(
      items.filter(item => item.hasPermission).map(item => ({ ...item, selected: selectedPath.includes(item.path) })),
    );
  }, [selectedPath, user.isAccessAccount]);

  const onExitMasquerade = () => {
    const api_tokens = user.original_api_tokens;
    const organizations = user.original_organizations;
    const updatedUser = {
      ...user,
      api_tokens: { ...api_tokens },
      organizations: organizations,
      accountDetails: null,
      isAccessAccount: false,
      original_api_tokens: null,
      masquarage_organizations: null,
      selectedAccount: null,
    };
    setShowUserMenu(false);
    setItemInLocalStorage('user', updatedUser);
    dispatch(setUser(updatedUser));
    navigate('/home');
  };

  return (
    <SidebarWrapper
      className={classNames('radius-4', expanded ? 'expanded' : 'collapsed')}
      style={{
        transition: 'all 400ms linear',
      }}>
      <div className="flex items-center pxy-6 border-bottom">{expanded && <LogoWhite alt="lablit-logo" />}</div>
      <div
        className={`absolute flex items-center justify-center cursor expand-icon`}
        style={{
          transition: 'all 400ms linear',
        }}
        onClick={() => setExpanded(!expanded)}>
        {expanded ? (
          <LinkIcon className="expand-arrow-icon white-text rotate-180" />
        ) : (
          <LinkIcon className="expand-arrow-icon white-text" />
        )}
      </div>
      <div className="sidebar-top pxy-4" tabIndex="0">
        <div className="flex items-center justify-between cursor user-info no-select relative" tabIndex={0}>
          <div className="w-full menu-items">
            {menuItems.map((item, i) => (
              <MenuItem key={i} item={item} onClick={onItemClick} expanded={expanded} />
            ))}
          </div>
        </div>
      </div>
      <div className="pxy-4">
        <div
          className={classNames('sidebar-bottom radius-3', showUserMenu && 'sidebar-bottom-opened')}
          // onBlur={() => setShowUserMenu(false)}
          onClick={() => setShowUserMenu(!showUserMenu)}
          tabIndex="0">
          <div className="flex items-center py-3 px-4 user-name cursor">
            {expanded && (
              <div className="flex flex-1 items-center">
                <div className="flex-column flex-1">
                  <label className="regular-text main-grey-text font-12">{userOrganization?.role?.name}</label>
                  <label className="semibold-text white-text">{`${userDetails?.forename} ${userDetails?.surname}`}</label>
                </div>
                <MenuIcon className="white-text" />
              </div>
            )}
          </div>
          <div
            className={classNames('w-full user-menu')}
            style={{
              height: showUserMenu
                ? userMenuItems.filter(menu => menu.hasPermission).length * 34 + (user.isAccessAccount ? 52 : 0) + 16
                : '0px',
            }}>
            <div className={classNames('flex-column', showUserMenu && 'pxy-2 user-menu-border')}>
              {userMenuItems
                .filter(menu => menu.hasPermission)
                .map((item, i) => (
                  <div
                    key={i}
                    className={classNames('user-action radius-2', item.selected && 'user-action-selected')}
                    onClick={() => {
                      item.action();
                    }}>
                    <div
                      className={classNames(
                        'flex items-center pxy-2 cursor',
                        expanded ? 'px-3' : 'px-2 justify-center',
                      )}>
                      <div className="flex radius-2 user-action-icon">
                        <item.Icon className="white-text" />
                      </div>
                      {expanded && <label className="regular-text white-text ml-3 user-action-text">{item.text}</label>}
                    </div>
                  </div>
                ))}
            </div>
            {user.isAccessAccount && (
              <div
                key={'exit-access-account'}
                className="user-action pxy-2 user-menu-border"
                onClick={() => onExitMasquerade()}>
                <div
                  className={classNames('flex items-center pxy-2 cursor', expanded ? 'px-3' : 'px-2 justify-center')}>
                  <div className="flex radius-2 user-action-icon">
                    <ExitIcon className="white-text" />
                  </div>
                  {expanded && <label className="regular-text white-text ml-3 user-action-text">Exit masquerade</label>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
