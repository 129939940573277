import classNames from 'classnames';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../../../assets/images/edit.svg';
import { ReactComponent as Loading } from '../../../assets/images/loading.svg';
import Button from '../../Elements/button/button';

const UpdateActionButtons = ({
  onSave = () => {},
  onCancel = () => {},
  onEdit = () => {},
  inEditState = false,
  className = '',
  disabled = false,
  btnWidth = '100%',
  btnSize = 'tiny',
  isSaving = false,
}) => {
  return (
    <UpdateActionButtonWrapper className={classNames('flex col-gap-4', className)}>
      {!inEditState ? (
        <Button
          onClick={onEdit}
          className="additional specified-width"
          label="Edit"
          icon={<EditIcon className="primary-text" />}
          disabled={disabled}
          size={btnSize}
          width={btnWidth}
        />
      ) : (
        <Fragment>
          <Button
            onClick={onCancel}
            className="secondary specified-width"
            label="Cancel"
            disabled={isSaving || disabled}
            size={btnSize}
            width={btnWidth}
          />
          <Button
            onClick={onSave}
            className="primary specified-width"
            label="Save"
            icon={isSaving ? <Loading height={14} /> : <Fragment />}
            disabled={isSaving || disabled}
            size={btnSize}
            width={btnWidth}
          />
        </Fragment>
      )}
    </UpdateActionButtonWrapper>
  );
};

const UpdateActionButtonWrapper = styled('div')``;

export default UpdateActionButtons;
