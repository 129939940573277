import classNames from 'classnames';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { updateDevicePin } from '../../store/features/deviceSlice';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

const UpdateDevicePin = () => {
  const dispatch = useDispatch();

  const { modal, setModal } = useContext(OrganisationContext);
  const { device, onSuccess } = modal.content;
  const { id: deviceId, device_code } = device;

  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [pinError, setPinError] = useState(false);

  const checkOtpError = () => {
    if (pin.length !== 4) {
      setPinError(true);
      dispatch(addToast({ error: true, text: 'Invalid PIN code', id: nanoid() }));
      return true;
    }
    setPinError(false);
    return false;
  };

  const onConfirmAction = async () => {
    if (checkOtpError()) return;
    setLoading(true);
    dispatch(updateDevicePin({ device_id: deviceId, request: { pin: pin, device_code: device_code } }))
      .then(() => {
        dispatch(addToast({ error: false, text: 'Device pin added', id: nanoid() }));
        onSuccess && onSuccess();
        setModal(initModal);
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while adding device pin', id: nanoid() }));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setPinError(false);
  }, [pin]);

  return (
    <CommonPopup
      popupTitle={'Set PIN code'}
      confirmButtonProps={{
        label: 'Submit',
      }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <UpdateDevicePinWrapper className="flex-column w-full radius-4 mt-6">
        <label className="regular-text font-16 main-grey-text text-center">Device 7-digit code:</label>
        <label className="bold-text font-24 text-center mt-4">{device_code}</label>
        <div className="flex-column mt-10 w-full">
          <label className="regular-text font-16 main-grey-text text-center">Enter 4-digit code here</label>
          <OtpInput
            containerStyle={'flex items-center justify-center col-gap-4 mt-6'}
            value={pin}
            onChange={setPin}
            numInputs={4}
            placeholder="0000"
            inputType="tel"
            renderSeparator={<span />}
            renderInput={props => (
              <input {...props} className={classNames('input otp-input', pinError && 'error-border')} />
            )}
          />
        </div>
      </UpdateDevicePinWrapper>
    </CommonPopup>
  );
};

const UpdateDevicePinWrapper = styled.div`
  .otp-input {
    padding: 4px;
    width: 48px !important;
    height: 48px;
  }
`;

export default UpdateDevicePin;
