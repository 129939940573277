import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  backdrop-filter: blur(8px);
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    position: relative;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in, opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition: transform 100ms ease-in 50ms, opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const AutomationActionWrapper = styled.div`
  width: 450px;

  .automation-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;

    .user-icon {
      height: 32px;
      width: 32px;
      background: ${({ theme }) => theme.colors.primary};
      padding: 8px;
      border-radius: 50%;
      span {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const AddAutomationWrapper = styled.div`
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 48px;
    }
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.primary};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  .user-icon {
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.Gray3};
    padding: 8px;
    border-radius: 50%;
    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .owner-option {
    height: 40px;

    &:hover {
      background: ${({ theme }) => theme.colors.Gray3};
    }
  }
`;
