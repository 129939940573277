import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as MailIcon } from '../assets/images/mail.svg';
import { ReactComponent as MobileIcon } from '../assets/images/mobile.svg';
import { initModal } from '../constant/InitialData';
import { OrganisationContext } from '../context/organisationContext';
import { addToast } from '../store/features/toastSlice';
import { deleteUser, inviteUser, updateUsersList, updateUsersStatus } from '../store/features/userManagementSlice';
import CommonPopup from './common-popup';

const UserActions = () => {
  const dispatch = useDispatch();
  const { userOrganization } = useSelector(state => state.user);

  const { modal, setModal } = useContext(OrganisationContext);
  const [loading, setLoading] = useState(false);
  const {
    user,
    isDelete,
    isReinvite,
    title,
    isChangeStatus,
    isActive,
    isCancelInvitation,
    onSuccess = () => {},
  } = modal.content;

  const onConfirmAction = async () => {
    if (loading) return;
    setLoading(true);
    try {
      if (isReinvite) {
        dispatch(inviteUser({ organization_id: userOrganization.id, user: user }))
          .then(() => {
            setLoading(false);
            dispatch(addToast({ text: 'User re-invited successfully', id: nanoid() }));
            setModal(initModal);
            onSuccess();
          })
          .catch(() => {
            setLoading(false);
            dispatch(
              addToast({
                error: true,
                text: 'Error while re invite user',
                id: nanoid(),
              }),
            );
          });
      } else if (isDelete || isCancelInvitation) {
        dispatch(deleteUser({ ...user }))
          .then(() => {
            dispatch(
              addToast({
                text: isCancelInvitation ? 'Invitation cancelled successfully' : 'User deleted successfully',
                id: nanoid(),
              }),
            );
            setLoading(false);
            setModal(initModal);
          })
          .catch(() => {
            setLoading(false);
            dispatch(
              addToast({
                error: true,
                text: isCancelInvitation ? 'Error while cancelling invitation' : 'Error while delete user',
                id: nanoid(),
              }),
            );
          });
      } else if (isChangeStatus) {
        const { is_active, id } = user;
        const params = {
          is_active: !is_active,
        };
        dispatch(updateUsersList({ id: id }));
        dispatch(updateUsersStatus({ id: id, params }))
          .then(() => {
            dispatch(addToast({ text: `User ${is_active ? 'deactivated' : 'activated'} successfully`, id: nanoid() }));
            setLoading(false);
            setModal(initModal);
          })
          .catch(error => {
            dispatch(updateUsersList({ id: id }));
            dispatch(
              addToast({
                error: true,
                text: 'Error while updating user status',
                id: nanoid(),
              }),
            );
          });
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        addToast({
          error: true,
          text: `Failed to ${isDelete ? 'delete' : 'disable'} user`,
          id: nanoid(),
        }),
      );
    }
  };

  return (
    <CommonPopup
      popupTitle={title}
      confirmButtonProps={{
        label: isCancelInvitation
          ? 'Yes'
          : isReinvite
          ? 'Re-invite'
          : isDelete
          ? 'Delete'
          : isChangeStatus
          ? isActive
            ? 'Deactivate'
            : 'Activate'
          : 'Confirm',
        className: isDelete ? 'negative' : 'primary',
      }}
      cancelButtonProps={{ label: isCancelInvitation ? 'No' : 'Cancel' }}
      disabled={loading}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}>
      <div className="flex-column items-center w-full">
        <div className="mt-6 pxy-4 flex-column w-full user-details-container radius-4 light-grey-background">
          <label className="bold-text font-18">{user.name}</label>
          <label className="regular-text font-14 main-grey-text mt-2">{user?.role?.name}</label>
          <div className="flex-column items-start justify-start w-full mt-5">
            <div className="flex items-center justify-start w-full">
              <div className="flex items-center justify-center icon-wrapper mr-2 white-background pxy-1 radius-1">
                <MailIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label className={`regular-text font-14 ${!user?.email ? 'main-grey-text' : ''}`}>
                {user?.email ? user?.email : 'Not provided'}
              </label>
            </div>
            <div className="flex items-center justify-start w-full mt-2">
              <div className="flex items-center justify-center icon-wrapper mr-2 white-background pxy-1 radius-1">
                <MobileIcon className="flex items-center justify-center " height={16} width={16} />
              </div>
              <label className={`regular-text font-14 ${!user?.phone ? 'main-grey-text' : ''}`}>
                {user?.phone ? user?.phone : 'Not provided'}
              </label>
            </div>
          </div>
        </div>
      </div>
    </CommonPopup>
  );
};

export default UserActions;
