import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    printLogList: [],
    expiryReportList: [],
  },
  reducers: {
    setPrintLogList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.printLogList = { ...data, content: [...state.printLogList.content, ...data.content] };
      } else {
        state.printLogList = data;
      }
    },
    setExpiryReportList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.expiryReportList = { ...data, content: [...state.expiryReportList.content, ...data.content] };
      } else {
        state.expiryReportList = data;
      }
    },
  },
});

export const getPrintLogList = payload => async dispatch => {
  try {
    const { params, merge } = payload;
    const { data } = await apiV3.get(`/api/label_logs`, {
      params: params,
      paramsSerializer: { indexes: null },
    });
    dispatch(setPrintLogList({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getExpiryReportList = payload => async dispatch => {
  try {
    const { params, merge } = payload;
    const { data } = await apiV3.get(`/api/label_logs`, { params: params, paramsSerializer: { indexes: null } });
    dispatch(setExpiryReportList({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setPrintLogList, setExpiryReportList } = reportsSlice.actions;
export default reportsSlice.reducer;
